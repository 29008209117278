var path = 'api/media/'
var folderpath = 'api/mediafolder/'

function getMedia(http, id) {
    console.log('getMedia')
    //loggingService.log(isDebug, serviceName, 'getDirectChildren')
    return http.get(path + id)
}

function saveMedia(http, parentid, file) {
    return http.post(path + parentid, file)
}

function getMediaFolder(http, id) {
    console.log('getMediaFolder')
    //loggingService.log(isDebug, serviceName, 'getMediaFolder')
    return http.get(folderpath + id)
}

function getChildren(http, rootid) {
    return http.get(`${folderpath}getChildren/${rootid}`)
}

function saveFilesToNewParent(http, childrenIds, newParentId) {
    return http.patch(`${folderpath}save-files-to-new-parent`, { fileIds: childrenIds, parentId: newParentId });
}

function saveFolderAsPublished(http, dto) {
    return http.post(folderpath + 'save-as-published', dto)
}

function rename(http, dto) {
    return http.post(`${folderpath}rename`, dto)
}

function publish(http, dto) {
    return http.post(`${folderpath}publish`, dto)
}

function publishDirectChildren(http, list) {
    return http.post(`${folderpath}publish-direct-children`, list)
}

function unpublish(http, dto) {
    return http.post(`${folderpath}unpublish`, dto)
}

function unpublishDirectChildren(http, list) {
    return http.post(`${folderpath}unpublish-direct-children`, list)
}

function toggleLock(http, dto) {
    dto.isLocked = !dto.isLocked
    return http.post(folderpath, dto)
}

function deleteMediaFolder(http, id) {
    return http.post(`${folderpath}delete/${id}`)
}

function prMediaFolderSelectStructure(http, rootid) {
    console.log('prMediaFolderSelectStructure')
    //loggingService.log(isDebug, serviceName, 'prMediaFolderSelectStructure')
    return http.get(folderpath + 'prMediaFolderSelectStructure/' + rootid)
}

function downloadFile(http, id) {
    return http.get(`${path}downloadfile/${id}`)
}

function getContractDeliverableOutline(http) {
    return http.get(folderpath + 'get-contract-deliverable-outline')
}

export default {
    //media
    getMedia: getMedia,
    saveMedia: saveMedia,
    downloadFile: downloadFile,
    getContractDeliverableOutline: getContractDeliverableOutline,

    //media folder
    getMediaFolder: getMediaFolder,
    getChildren: getChildren,
    saveFilesToNewParent: saveFilesToNewParent,
    saveFolderAsPublished: saveFolderAsPublished,
    rename: rename,
    publish: publish,
    publishDirectChildren: publishDirectChildren,
    unpublish: unpublish,
    unpublishDirectChildren: unpublishDirectChildren,
    toggleLock: toggleLock,
    deleteMediaFolder: deleteMediaFolder,

    //stored procs
    prMediaFolderSelectStructure: prMediaFolderSelectStructure
}
var path = 'api/improv/providerrenewal/'

function getProviderRenewalListByUser(http) {
    return http.get(path + 'get-provider-renewal-list-by-user')
}

function getProviderRenewalHistoryByProviderId(http, providerId) {
    return http.get(path + 'get-provider-renewal-history-by-provider/' + providerId)
}

function getProviderRenewalProcessInstanceByProcessInstanceId(http, processInstanceId) {
    return http.get(path + 'get-provider-renewal-process-instance-by-process-instance-id/' + processInstanceId)
}

function getProviderRenewalProcessInstanceListByUser(http) {
    return http.get(path + 'get-provider-renewal-process-instance-list-by-user')
}

function getProviderRenewalProcessInstanceListInProgressByUser(http) {
    return http.get(path + 'get-provider-renewal-process-instance-list-in-progress-by-user')
}

function save(http, dto) {
    return http.post(path + 'save', dto)
}

function initialize(http, processInstanceId) {
    return http.post(path + 'initialize/' + processInstanceId)
}

function submit(http, dto) {
    return http.post(path + 'submit', dto)
}

function approve(http, dto, expiryMonthsToAdd) {
    const url = `${path}approve?expiryMonthsToAdd=${expiryMonthsToAdd}`
    return http.post(url, dto)
}

function reject(http, dto) {
    return http.post(path + 'reject', dto)
}

function cancel(http, dto) {
    return http.post(path + 'cancel', dto)
}

function revertProcess(http, processInstanceId) {
    return http.post(path + 'revert-process/' + processInstanceId)
}

export default {
    getProviderRenewalListByUser: getProviderRenewalListByUser,
    getProviderRenewalHistoryByProviderId: getProviderRenewalHistoryByProviderId,
    getProviderRenewalProcessInstanceByProcessInstanceId: getProviderRenewalProcessInstanceByProcessInstanceId,
    getProviderRenewalProcessInstanceListByUser: getProviderRenewalProcessInstanceListByUser,
    getProviderRenewalProcessInstanceListInProgressByUser: getProviderRenewalProcessInstanceListInProgressByUser,
    save: save,
    initialize: initialize,
    submit: submit,
    approve: approve,
    reject: reject,
    cancel: cancel,
    revertProcess: revertProcess
}
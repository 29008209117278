var path = 'api/upload/'
var header_path = 'api/upload_header/'
var record_path = 'api/upload_record/'

function upload(http, file, isValidate) {
    return http.post(`${header_path}upload/${isValidate}`, file)
}

function downloadFile(http, id) {
    return http.get(`${header_path}downloadfile/${id}`)
}

function saveHeader(http, dto) {
    return http.post(`${header_path}save`, dto)
}

function getUploadHeaderViewModels(http, year, month) {
    return http.get(`${header_path}getviewmodellist/${year}/${month}`)
}

function getLatestUploadHeaderViewModel(http) {
    return http.get(`${header_path}getlatestviewmodel`)
}

function getUploadRecordViewModels(http, upload_HeaderId, formTypeId, excludeDuplicateRecordErrors) {
    return http.get(`${record_path}getviewmodellist/${upload_HeaderId}/${formTypeId}/${excludeDuplicateRecordErrors}`)
}

function deleteUpload(http, upload_HeaderID) {
    return http.post(`${header_path}delete/${upload_HeaderID}`)
}

export default {
    upload: upload,
    downloadFile: downloadFile,
    saveHeader: saveHeader,
    getUploadHeaderViewModels: getUploadHeaderViewModels,
    getLatestUploadHeaderViewModel: getLatestUploadHeaderViewModel,
    getUploadRecordViewModels: getUploadRecordViewModels,
    deleteUpload: deleteUpload
}
<script>
import enumService from '@/services/util/enumService';
import { clientSummaryService } from '@/services/clientSummaryService';
import agencyGroupService from '@/services/agencyGroupService';

export default{
    data: () => ({
        isFormValid: false,
        agencyGroups: [],
        childProviderClientObject: {},
        requiredFieldRules: [
            value => !!value || 'This field is REQUIRED.',
        ],
        
    }),
    methods: {
        close() {
            this.$emit("close-provider-dialog");
        },
        save() {
            this.$emit("save-provider-dialog", {providerObject: this.childProviderClientObject});
        },
        onHandlerEvent() {
            this.childProviderClientObject = this.parentProviderClientObject ? JSON.parse(JSON.stringify(this.parentProviderClientObject)) : {};
        },
    },
    props: {
            parentProviderClientObject: Object,
            dialog: {
                type: Boolean,
                required: true,
            },
            isEdit: {
                type: Boolean,
                required: true,
            }
    },
    watch: {
        $props: {
            handler() {
                this.onHandlerEvent();
            },
            deep: true,
            immediate: true
        },
    },
    created() {
    }
}
</script>



<template>
    <v-dialog :value="dialog" @click:outside="close" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="600">
        <v-card>
            <v-toolbar color="orange lighten-4" dark class="black--text">{{isEdit ? "Edit Provider" : "Create Provider"}}</v-toolbar>
            <v-spacer></v-spacer>
            <v-card-text class="mt-6">
                <v-form v-model="isFormValid">
                    <v-text-field v-model="childProviderClientObject.providerClientId" :label="'Provider Client ID'" :rules="requiredFieldRules"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="save" :disabled="!isFormValid">Save</v-btn>
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="close">Cancel</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
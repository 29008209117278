var path = 'api/fmt_mis/'

function save(http, dto) {
    return http.post(path, dto)
}

function testSave(http) {
    return http.post(path + 'testSave')
}

function get(http, id) {
    return http.get(path + id)
}

function getViewModel(http, id) {
    return http.get(path + 'getviewmodel/' + id)
}

function getRelated(http, searchDto) {
    console.log(searchDto)
    return http.post(path + 'getrelated', searchDto)
}

function deleteByObject(http, dto) {
    return http.post(path + `delete-by-dto`, {trackingId: dto.formID, additionalIdentifyingInformation: null});
}

function getDeletedFormsByClientId(http, id) {
    return http.get(path + `get-deleted-by-client-id/${id}`);
}

function restoreByObject(http, dto) {
    let dtoArray = new Array();
    dto.forEach(x => {
        dtoArray.push({trackingId: x, additionalIdentifyingInformation: null});

    });
    return http.post(path + `restore-from-delete-by-dto`, dtoArray);
}

function getLatest(http) {
    return http.get(path + 'get-latest')
}

export default {
    save: save,
    get: get,
    getViewModel: getViewModel,
    getRelated: getRelated,
    deleteByObject: deleteByObject,
    getDeletedFormsByClientId: getDeletedFormsByClientId,
    restoreByObject: restoreByObject,
    getLatest: getLatest,

    testSave: testSave
}
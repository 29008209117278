const announcementPath = 'api/providerannouncement/';

class ProviderAnnouncementService {
    constructor() {}

    getAllAnnouncements(http) {
        return http.get(`${announcementPath}get-announcements`);
    }

    createAnnouncement(http, announcementBody) {
        return http.post(`${announcementPath}create-announcement`, announcementBody);
    }

    updateAnnouncement(http, announcementBody) {
        return http.put(`${announcementPath}update-announcement`, announcementBody);
    }

    deleteAnnouncement(http, announcementId) {
        return http.delete(`${announcementPath}delete-announcement/${announcementId}`);
    }

    getLatestAnnouncementDate(http) {
        return http.get(`${announcementPath}get-latest-date`);
    }
}

export const providerAnnouncementService = new ProviderAnnouncementService();
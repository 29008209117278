import { render, staticRenderFns } from "./ClientSummaryReport.vue?vue&type=template&id=2cd49ade&scoped=true&"
import script from "./ClientSummaryReport.vue?vue&type=script&lang=js&"
export * from "./ClientSummaryReport.vue?vue&type=script&lang=js&"
import style0 from "./ClientSummaryReport.vue?vue&type=style&index=0&id=2cd49ade&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd49ade",
  null
  
)

export default component.exports
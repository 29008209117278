var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"transition":"dialog-top-transition","max-width":"2200","max-height":"2200"},on:{"click:outside":_vm.finish,"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-container',{attrs:{"fluid":true}},[_c('v-card',[_c('v-card-title',{staticClass:"black--text"},[_vm._v(_vm._s("Client ID " + this.clientId))]),_c('v-toolbar',{staticClass:"black--text mt-8",attrs:{"dark":"","flat":"","prominent":"","color":"orange lighten-4","height":105}},[_c('v-tabs',{staticClass:"pa-6",attrs:{"centered":"","dark":"","slider-color":"deep-purple accent-4","height":85,"active-class":"activeTabClass"},on:{"change":function($event){return _vm.mutateData($event)}},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.childEpisodeInfoToDisplay),function(episode){return _c('v-tab',{key:episode.episodeID,staticStyle:{"color":"black"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chart-box-outline")]),_vm._v(" "+_vm._s(episode.agencyCode + " - " + episode.agencyName + ": " + "Episode " + episode.episodeNumber)+" ")],1)}),1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.collapseHeaderColumns()}}},[_c('v-icon',[_vm._v("mdi-arrow-expand-left")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.dynamicCollapseAndExpandHeaderSet.length === _vm.sharedDlaHeaders.length},on:{"click":function($event){return _vm.expandHeaderColumns()}}},[_c('v-icon',[_vm._v("mdi-arrow-expand-right")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.childEpisodeInfoToDisplay),function(episodeTab){return _c('v-tab-item',{key:episodeTab.episodeID,attrs:{"eager":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card-title',_vm._g(_vm._b({staticClass:"black--text"},'v-card-title',attrs,false),on),[_vm._v(_vm._s(episodeTab.agencyCode + " - " + episodeTab.agencyName + ": " + "Episode " + episodeTab.episodeNumber))])]}}],null,true)},[_c('span',[_vm._v("Green highlighting signifies an improvement from the previous DLA score, while yellow highlighting signifies a decrease from the previous DLA score")]),_c('br')]),_c('v-card-text',{ref:'exportablePDFArea' + episodeTab.episodeID.toString(),refInFor:true,attrs:{"id":'exportablePDFArea' + episodeTab.episodeID.toString()}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.dynamicCollapseAndExpandHeaderSet,"items":_vm.sharedDlaTabData,"items-per-page":_vm.metricLabelSet.length},scopedSlots:_vm._u([{key:"item.metric",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"parent-table-container"},[_vm._v(_vm._s(item["metric"]))])]}},_vm._l((_vm.currentTabDlaEntryCount),function(index){return {key:"item.score"+(index-1).toString(),fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"parent-table-container",style:({'background-color': item["score"+(index-1).toString()].cellColorCode})},[_vm._v(_vm._s(item["score"+(index-1).toString()].rawScore))])]}}}),{key:"item.rowAverage",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"parent-table-container"},[_vm._v(_vm._s(item["rowAverage"]))])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mx-4",attrs:{"text":"","color":"deep-purple accent-4"},on:{"click":_vm.exportPdf}},[_vm._v("Export Client Level DLA-20 Data")])],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var path = 'api/improv/provider/'

function search(http, searchDto) {
    return http.post(path + 'search', searchDto)
}
function getProviderList(http) {
    return http.get(path + 'get-provider-list')
}
function getProviderListByUser(http) {
    return http.get(path + 'get-provider-list-by-user')
}

function getProviderWorkflowInstanceListByUser(http) {
    return http.get(path + 'get-provider-workflow-instance-list-by-user')
}

function getProviderWithDemographics(http, providerId) {
    return http.get(`${path}get-provider-with-demographics/${providerId}`);
}

function getBasicProviderInformation(http, providerId,) {
    return http.get(`${path}get-provider-basics/${providerId}`);
}

function getFullProviderObject(http, providerId,) {
    return http.get(`${path}get-provider-object/${providerId}`);
}

function GetAllFullProviderObjects(http) {
    return http.get(`${path}get-all-provider-objects`);
}

function saveProviderDemographicEdits(http, providerDemographicObject) {
    return http.post(`${path}admin-save-provider-with-demographics`, providerDemographicObject);
}

function migrationFix(http) {
    return http.get(path + 'provider-migration-fix')
}

export default {
    search: search,
    getProviderListByUser: getProviderListByUser,
    getProviderWorkflowInstanceListByUser: getProviderWorkflowInstanceListByUser,
    getProviderWithDemographics: getProviderWithDemographics,
    getBasicProviderInformation: getBasicProviderInformation,
    getFullProviderObject: getFullProviderObject,
    GetAllFullProviderObjects: GetAllFullProviderObjects,
    saveProviderDemographicEdits: saveProviderDemographicEdits,
    getProviderList: getProviderList,
    migrationFix: migrationFix
}
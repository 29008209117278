<script>
import { mapState } from 'vuex';
import { clientSummaryService } from '@/services/clientSummaryService';
import edbsClientService from '@/services/edbsClientService';
import utilService from '@/services/util/utilService';
import enumService from '@/services/util/enumService';
import agencyGroupService from '@/services/agencyGroupService';
import agencyService from '@/services/agencyService';
import misService from '@/services/misService';
import esrService from '@/services/esrService';
import rahsService from '@/services/rahsService';
import crisisService from '@/services/crisisService';
import userService from '@/services/userService';
import store from '@/store/store';
import JsonExcel from "vue-json-excel";
import { uuid } from 'vue-uuid';
import { Buffer } from 'buffer';
import Snackbar from '@/components/util/Snackbar'

import CL_ClientEditOrCreate from '@/components/CL_ClientEditOrCreate.vue';
import CL_ClientAuditHistory from '../components/CL_ClientAuditHistory.vue';
import ConfirmItemDeleteByObject from '@/components/ConfirmItemDeleteByObject.vue';
import AddOrEditProviderClient from '@/components/AddOrEditProviderClient.vue';
import ViewSoftDeletedItems from '@/components/ViewSoftDeletedItems.vue';
import RahsAdminActionsForClientSummary from '@/components/RahsAdminActionsForClientSummary.vue';
import AgencyDescriptionEdit from '@/components/EditComponents/AgencyDescriptionEdit.vue';
import ClientSummaryReport from '@/components/ReportComponents/ClientSummaryReport.vue';
import IndividualDLA20Report from '@/components/ReportComponents/IndividualDLA20Report.vue';
import carfService from '@/services/carfService';

export default {
    components: {
        "edit-client-dialog": CL_ClientEditOrCreate,
        "client-audit-history": CL_ClientAuditHistory,
        "confirm-delete": ConfirmItemDeleteByObject,
        "add-or-edit-provider-client": AddOrEditProviderClient,
        "restore-items-dialog": ViewSoftDeletedItems,
        "admin-rahs-actions": RahsAdminActionsForClientSummary,
        "agency-description-dialog": AgencyDescriptionEdit,
        "client-summary-report-dialog": ClientSummaryReport,
        "individual-dla20-report": IndividualDLA20Report,
        JsonExcel,
        Snackbar
    },

    data: () => ({
        isLoading: true,
        sentinelEvents: [],
        sentinelEventSearch: null,
        //For when the page itself is loaded and ready, but we need to do dynamic GET requests and data mutations that take time (longer than a second).
        //We have six distinct sections. See forms history for a good example on how to use this.
        isSectionLoading: [false,false,false,false,false,false],
        editClientDialog: false,
        viewAuditDialog: false,
        confirmDeleteDialog: false,
        addProviderClientDialog: false,
        editProviderClientDialog: false,
        restoreDialog: false,
        adminRahsDialog: false,
        agencyDescriptionDialogue: false,
        viewSummaryReportDialog: false, 
        isCrisisInstance: false,
        adminRahsIsRevert: false,
        defaultEpisodeSortDesc: false,
        isDla20DataLoading: false,
        isDla20WindowReady: false,
        individualDLA20Dialog: false,
        doesClientHaveMergedCrisisForms: false,
        defaultEpisodeSortField: 'dischargeDate',
        itemToDelete: {},
        providerObjectToEdit: {},
        providerObjectToDelete: {},
        deletedItemsToRecover: {},
        adminRahsItemToModify: {},
        clientId: null,
        client: {},
        mergedCrisisClient: null,
        providerIDSFields: new Array(),
        profileDataFields: [{ id: uuid.v4(), text: "Date of Birth", mapKey: "dateOfBirth", mapData: "" }, { id: uuid.v4(), text: "Sex", mapKey: "gender", mapData: "" }, { id: uuid.v4(), text: "Last Four SSN", mapKey: "ssn", mapData: "" }, { id: uuid.v4(), text: "Medicaid ID", mapKey: "medicaidID", mapData: "" }, { id: uuid.v4(), text: "Mother's First Name", mapKey: "mothersFirstName", mapData: "" }, { id: uuid.v4(), text: "Birth City", mapKey: "birthCity", mapData: "" }, { id: uuid.v4(), text: "Birth State", mapKey: "birthState", mapData: "" }, { id: uuid.v4(), text: "Birth Country", mapKey: "birthCountry", mapData: "" }],
        crisisProfileDataFields: [{ id: uuid.v4(), text: "Is Merged With BHMS Client?", mapKey: "isMergedToWCIS", mapData: "" }, { id: uuid.v4(), text: "BHMS Client ID", mapKey: "mergedBHMSClientID", mapData: "" }],
        tabs: null,
        dla20StartDate: null,
        dla20EndDate: null,
        searchFormsHistory: "",
        searchDemographics: "",
        searchTreatments: "",
        searchSummaryHistory: "",
        searchEpisodesHistory: "",
        searchRahsEpisodesHistory: "",
        searchCrisisHistory: "",
        rahsHeaders: [{text: "Episode Number", value: "episodeNumber"}, {text: "Agency", value: "agencyName"}, {text: "Agency Code", value: "agencyCode"}, {text: "Status", value: "tshStatus"}, {text: "On Waitlist", value: "onWaitlist"}, {text: "In Treatment", value: "inTreatment"}, {text: "Completed Treatment", value: "completedTreatment"}, {text: "Removed From Waitlist", value: "removedFromWaitlist"}, {text: "Removed From Treatment", value: "removedFromTreatment"}, {text: "", value: "modify", width: "12%"} ],
        summaryHeaders: [{text: "Agency Name", value: "agencyName"}, {text: "Agency Code", value: "agencyCode"}, {text: "Agency Type", value: "agencyType"}, {text: "Episode Status", value: "openProgram"}, {text: "Admit Date", value: "admitDate"}, {text: "Latest Form Date", value: "lastOverallFormDate"}, {text: "Discharge Date", value: "dischargeDate"}, {text: "Discharge Type", value: "dischargeType"} , {text: "Discharge Status", value: "dischargeStatus"} ],
        episodeHeaders: [{text: "Episode Number", value: "episodeNumber"}, {text: "Agency Name", value: "agencyName"}, {text: "Agency Code", value: "agencyCode"}, {text: "Agency Type", value: "agencyType"}, {text: "Admit Date", value: "admitDate"}, {text: "Episode Status", value: "openProgram"}, {text: "Latest Form Date", value: "formDate"}, {text: "Discharge Date", value: "dischargeDate"}, {text: "Discharge Type", value: "dischargeType"} , {text: "Discharge Status", value: "dischargeStatus"}, {text: "View Forms/Clear", value: "modifyActions"} ],
        formsHeaders: [{ text: "Agency", value: "agencyName", width: "30%" }, {text: "Admit Date", value: "admitDate"}, { text: "Form", value: "form", width: "10%" }, { text: "Transaction Date", value: "dateOfForm", width: "15%" }, { text: "Type", value: "formType", width: "30%" }, { text: "Episode", value: "relatedEpisode" }, { text: "Units", value: "units" }, { text: "", value: "modify", width: "25%" }],
        demographicsHeaders: [{ text: "Age", value: "age" }, { text: "County of Residence", value: "countyOfResidence" }, { text: "Gender", value: "gender" }, { text: "Race", value: "race" }, { text: "Hispanic Origin", value: "hispanicOrigin" }, { text: "Residence", value: "residence" }, { text: "Employment", value: "employment" }, { text: "Marital Status", value: "maritalStatus" }, { text: "Veteran Status", value: "veteranStatus" }, { text: "Education", value: "education" }],
        treatmentHeaders: [{ text: "Annual Income", value: "annualIncome" }, { text: "Probation", value: "probation" }, { text: "Parole", value: "parole" }, { text: "MH Population", value: "mhPopulation" }, { text: "SA Female", value: "saFemale" }, { text: "SUD Persons Who Inject Drugs (PWID)", value: "saivDrugUser" }, { text: "Presenting Problems", value: "commaJoinedPresentingProblems" }, { text: "Diagnoses", value: "commaJoinedDiagnoses" }],
        crisisHeaders: [{ text: "Agency", value: "agencyName", width: "30%" }, {text: "Date Of Creation", value: "createDate"}, { text: "Merged Date", value: "mergedDate", width: "10%" }, { text: "Date Of Service", value: "dateOfService", width: "15%" }, { text: "Type", value: "formType"}, { text: "Location Of Service", value: "locationOfService" }, { text: "Units", value: "units" }, { text: "", value: "modify", width: "25%" }],
        sentinelEventHeaders: [
            { text: 'Agency', value: 'agencyGroupName' },
            { text: 'Type', value: 'formType' },
            { text: 'Incident Date', value: 'dateOfIncidentDisplay' },
            { text: 'User', value: 'createdBy' },
            { text: 'Is Admin Acknowledged', value: 'isAcknowledged' },
            { text: 'Actions', value: 'actions', sortable: false }
        ],
        originalFormsData: [],
        filteredFormsData: [],
        crisisFormsData:[],
        allEpisodeData: [],
        latestEpisodeData: [],
        rahsEpisodeData: [],
        clientAuditHistory: [],
        clientValidationModelState: [],
        agencyDescriptionsToModify: [],
        fyList: [],
        formFYLockDateConfig: [],
        filteredByDateAgencyEpisodes: [],
        selectedEpisodeIdsForDLA20: [],
        individualDLA20Data: [],
        activeTableRowsPerPage: 10,
        snack: false,
        snackColor: '',
        snackText: '',
        //JSON to Excel Export variables ONLY. These tend to get complicated because I must internalize and lambda-ize a lot of the data formatting functions that are called in the data tables.
        episodeSummaryFieldsForExport: {"Agency Name": "agencyName", "Agency Code": {callback: (value) => {return value.agencyCode.toString()}}, "Agency Type": "agencyType", "Episode Status": {field: "luEpisodeStatusTypeId", callback: (value) => {return value === 1 ? "Open" : "Closed"}}, "Admit Date": "admitDate", "Latest Form Date": "lastFormDate", "Discharge Date": "dischargeDate", "Discharge Type": {callback: (value) => {if (value.luEpisodeStatusTypeId === 3) {return "Soft Discharge";} else if (value.luEpisodeStatusTypeId === 4) {return "Final System Discharge";} else if (!value.isSoftDischarge && value.dischargeDate !== null) {return "Hard Discharge";} else {return "-";}}}, "Discharge Status": {callback: (value) => {return value.dischargeStatus ? value.dischargeStatus : "N/A"}}},
        crisisFormsFieldsForExport: {"Agency": "agencyName", "Date Of Creation": "createDate", "Merged Date": "mergedDate", "Date Of Service": "dateOfService", "Type": "formType", "Location Of Service": "locationOfService", "Units": "units"},
        fullEpisodeFieldsForExport: {"Episode Number": "episodeNumber", "Agency Name": "agencyName", "Agency Code": "agencyCode", "Agency Type": "agencyType", "Admit Date": "admitDate", "Episode Status": {field: "luEpisodeStatusTypeId", callback: (value) => {return value === 1 ? "Open" : "Closed"}}, "Latest Form Date": "lastFormDate", "Discharge Date": "dischargeDate", "Discharge Type": {callback: (value) => {if (value.luEpisodeStatusTypeId === 3) {return "Soft Discharge";} else if (value.luEpisodeStatusTypeId === 4) {return "Final System Discharge";} else if (!value.isSoftDischarge && value.dischargeDate !== null) {return "Hard Discharge";} else {return "-";}}}, "Discharge Status": {callback: (value) => {return value.dischargeStatus ? value.dischargeStatus : "N/A"}}},
        fullFormsFieldsForExport: {"Agency": "agencyName", "Admit Date": "admitDate", "Form": "form", "Transaction Date": "dateOfForm", "Type": "formType", "Episode": "relatedEpisode", "Units": "units" },
        demographicFieldsForExport: {"Age": "age", "County of Residence": "countyOfResidence", "Gender": "gender", "Race": "race", "Hispanic Origin": "hispanicOrigin", "Residence": "residence", "Employment": "employment", "Marital Status": "maritalStatus", "Veteran Status": "veteranStatus","Education": "education"},
        treatmentDataFieldsForExport: {"Annual Income": "annualIncome", "Probation": "probation", "Parole": "parole", "MH Population": "mhPopulation", "SA Female": "saFemale", "SUD Persons Who Inject Drugs (PWID)": "saivDrugUser", "Presenting Problems": "commaJoinedPresentingProblems", "Diagnoses": "commaJoinedDiagnoses"},
        rahsFieldsForExport: {"Episode Number": "episodeNumber", "Agency": "agencyName", "Agency Code": {callback: (value) => {return value.agencyCode.toString()}}, "Status": "tshStatus", "On Waitlist": "waitlistDate", "In Treatment": "acceptedDate", "Completed Treatment": "completedDate", "Removed From Waitlist": {callback: (value) => {return value.luTSHRemovalTypeID === 1 || value.luTSHRemovalTypeID === 3 ? value.removalDate : "N/A" }}, "Removed From Treatment": {callback: (value) => {return value.luTSHRemovalTypeID === 2 ? value.removalDate : "N/A" }}},

    }),
    created() {
        //We have two options for how this page should be loaded. The first is when a normal CL_WCISClientId is provided.
        //This is when MIS and ESR forms are available, but no crisis forms. There will also be no crisis forms table. In addition
        //the provider client information will come from the CL_Provider client table
        //When we have a edbs id present, this is a crisis instance, and we will allow a crisis form to become available. Also, a crisis
        //forms table will be present. Finally, we will pull provider client information from the EDBS Provider Client table.
        if (this.$route.query.clientId) {
            this.clientId = Buffer.from(this.$route.query.clientId, 'base64').toString('ascii');
            //We must first always make sure that the wybhdsUserDto object is ready for role checks and other computed operations. Due to the hybrid setup of the current auth
            //system, we must use Vue store dispatch action to ensure all user information is loaded into the global object. Then we may perform REST operations. 
            Promise.all([store.dispatch('auth/authenticated'), this.loadAllClientAndAgencyGroupInformation(this.clientId), this.loadEpisodeAndRelatedFormInformation(this.clientId), this.loadRAHSEpisodeInformation(this.clientId), this.loadClientAuditHistory(this.clientId), this.getFYAndLockConfig(), this.loadMergedCrisisClient(), this.loadCrisisForms(this.clientId), this.loadSentinelEvents(this.clientId)]).then(() => {
                this.isLoading = false;
            });
        }
        else if (this.$route.query.edbsId) {
            this.clientId = Buffer.from(this.$route.query.edbsId, 'base64').toString('ascii');
            this.isCrisisInstance = true;
            Promise.all([store.dispatch('auth/authenticated'), this.loadAllClientAndAgencyGroupInformationCrisisOnly(this.clientId), this.loadClientAuditHistory(this.clientId), this.loadCrisisForms(this.clientId), this.getFYAndLockConfig()]).then(() => {
                this.isLoading = false;
            });
        }
    },
    mounted() {
        //'Dynamic' loading for admin table actions only
        if (this.isAdminOrStateUser) {
            this.rahsHeaders[this.rahsHeaders.length-1].text = "View/Delete";
            this.rahsHeaders.push({text: "Edit Dates/Revert Status", value: "adminActions" });
        }
        else {
            this.rahsHeaders[this.rahsHeaders.length-1].text = "View/Edit";
        }
        //At the request of BH-212
        if (localStorage.getItem("clientSummaryPageState") !== null) {
            let state = JSON.parse(localStorage.getItem("clientSummaryPageState"));
            localStorage.removeItem("clientSummaryPageState");
            this.searchFormsHistory = state.search;
            this.tabs = state.tab;
            this.activeTableRowsPerPage = state.rows;
        }
    },
    //We need this lifecycle hook to properly clear out all items on re-navigation. Unfortunately, the linter is set 
    //to VUE 3 rules, and we need to allow for VUE 2 rules, hence why I am disabling the linter on this next line.
    // eslint-disable-next-line
    beforeDestroy() {
        this.clientId = null;
        this.client = null;
        this.originalFormsData = null;
        this.filteredFormsData = null
        this.crisisFormsData = null;
        this.allEpisodeData = null;
        this.latestEpisodeData = null
        this.rahsEpisodeData = null
        this.clientAuditHistory = null;
    },
    computed: {
        ...mapState("auth", ["wybhdsUserDto"]),
        mapProfileData() {
            this.profileDataFields.forEach(x => {
                if (this.client[x.mapKey]) {
                    x.mapData = this.client[x.mapKey];
                }
                else {
                    x.mapData = null;
                }
            });
            return this.profileDataFields;
        },
        mapCrisisProfileData() {
            this.crisisProfileDataFields.forEach(x => {
                if (typeof this.client[x.mapKey] === "boolean") {
                    x.mapData = this.client[x.mapKey] ? "Yes" : "No";
                }
                else if (this.client[x.mapKey]) {
                    x.mapData = this.client[x.mapKey];
                }
                else {
                    x.mapData = null;
                }
            });
            return this.crisisProfileDataFields;
        },
        crisisItems() {
            if (this.crisisFormsData.length) return this.crisisFormsData
            if (this.originalFormsData[0]?.mergedCrisisForms) return this.originalFormsData[0].mergedCrisisForms
            return []
        },
        hasMergedCrisisClient() { return this.mergedCrisisClient },
        isAdminOrStateUser: function () { return (this.wybhdsUserDto && userService.isAdminOrStateUser()) },
        isAdminUser: function () { return (this.wybhdsUserDto && userService.isAdministrator()) },
        isStateUser: function () { return (this.wybhdsUserDto && userService.isStateUser()) },
        isAgencyGroupUser: function () { return (this.wybhdsUserDto && userService.isAgencyGroupUser()) },
        isRahsUser: function () { return (this.wybhdsUserDto && userService.isRahsUser()) },
        isWSHUser: function () { return (this.wybhdsUserDto && userService.isWSHUser()) },
        canEditClient() { return this.isAdminUser || this.isAgencyGroupUser },
        canEditAgencyDescriptions() { return this.isAdminUser },
        canCreateForm() { return this.isAgencyGroupUser },
        canRestoreForms() { return this.isAdminUser || this.isAgencyGroupUser },
        showRahs() { return this.isAdminUser || this.isRahsUser }
    },
    methods: {
        //I put these two actions together because they are entirely dependant on eachother. I cannot make another resolvable
        //call without first getting the client information.
        loadAllClientAndAgencyGroupInformation(id) {
            return new Promise((resolve, reject) => {
                try {
                    clientSummaryService.getClientForClientSummary(this.$http, id).then((clientResponse) => {
                        this.client = clientResponse.data;
                        this.client.dateOfBirth = utilService.formatDate(this.client.dateOfBirth);
                        this.client.birthCity = enumService.getCityDescription(this.client.luBirthCityID);
                        this.client.birthState = enumService.getStateDescription(this.client.luBirthStateID);
                        this.client.birthCountry = enumService.getCountryDescription(this.client.luBirthCountryID);
                        if (this.client.cL_WCISClient_Informations[0]) {
                            this.client.cL_WCISClient_Informations[0].age = utilService.calculateAgeFromDOB(this.client.dateOfBirth);
                            this.client.cL_WCISClient_Informations[0].gender = this.client.gender;
                        }
                        if (this.client.cL_ProviderClients !== null) {
                            this.client.cL_ProviderClients.forEach(provider => {
                                agencyGroupService.getAgencyGroups(this.$http, 0, provider.agencyGroupID).then((agencyResponse) => {
                                    this.providerIDSFields.push({ cL_ProviderClientID: provider.cL_ProviderClientID, providerClientId: provider.providerClientID, agency: agencyResponse.data.agencyGroupName, agencyGroupId: agencyResponse.data.agencyGroupID });
                                });
                            }); 
                        }

                        resolve();
                    });
                }
                catch (error) {
                    console.error(error);
                    reject(error);
                }
            });
        },
        loadAllClientAndAgencyGroupInformationCrisisOnly(id) {
             return new Promise((resolve, reject) => {
                try {
                    clientSummaryService.getCrisisClientForClientSummary(this.$http, id).then((crisisClientResponse) => {
                        this.client = crisisClientResponse.data;
                        this.client.dateOfBirth = utilService.formatDate(this.client.dateOfBirth);
                        if (this.client.cL_ProviderEDBSClientList !== null) {
                            this.client.cL_ProviderEDBSClientList.forEach(provider => {
                                agencyGroupService.getAgencyGroups(this.$http, 0, provider.agencyGroupID).then((agencyResponse) => {
                                    this.providerIDSFields.push({ cL_ProviderEDBSClientID: provider.cL_ProviderEDBSClientID, providerClientId: provider.providerClientID, agency: agencyResponse.data.agencyGroupName, agencyGroupId: agencyResponse.data.agencyGroupID });
                                });
                            }); 
                        }
                        resolve();
                    });
                }
                catch (error) {
                    console.error(error);
                    reject(error);
                }
            });
        },
        loadEpisodeAndRelatedFormInformation(id) {
            return new Promise((resolve, reject) => {
                try {
                    clientSummaryService.getClientEpisodeInformation(this.$http, id).then((latestEpisodeResponse) => {
                        this.allEpisodeData = latestEpisodeResponse.data;
                        for (const [index, val] of this.allEpisodeData.entries()) {
                            if (val.admitDate !== null) {
                                val.admitDate = utilService.formatDate(val.admitDate);
                            }
                            if (val.lastFormDate !== null) {
                                val.lastFormDate = utilService.formatDate(val.lastFormDate);
                            }
                            if (val.dischargeDate !== null) {
                                val.dischargeDate = utilService.formatDate(val.dischargeDate);
                            }
                            if (val.lastServiceDate !== null) {
                                val.lastServiceDate = utilService.formatDate(val.lastServiceDate);
                            }
                            val.episodeNumber = index+1;
                        }

                        this.allEpisodeData.sort((a,b) => {
                            return b.episodeNumber - a.episodeNumber
                        })
                        
                        this.latestEpisodeData = this.allEpisodeData.filter(x => {
                            if (x.lastEpisode === true) {
                                return x;
                            }
                        });
                        //We must load the form history in this way to attach the episode field to the actual episode number we assign above.
                        clientSummaryService.getClientUniqueFormsHistoryByClientId(this.$http, id).then((formsResponse) => {
                            this.originalFormsData = formsResponse.data;
                            this.filteredFormsData = formsResponse.data;
                            this.originalFormsData.forEach(x => {
                                if (x.admitDate !== null) {
                                    x.admitDate = utilService.formatDate(x.admitDate);
                                }
                                if (x.dateOfForm !== null) {
                                    x.dateOfForm = utilService.formatDate(x.dateOfForm);
                                }
                                let episodeNumber = this.allEpisodeData.find(e => e.episodeID === x.episodeId) != undefined ? this.allEpisodeData.find(e => e.episodeID === x.episodeId).episodeNumber : "-";
                                x.relatedEpisode = episodeNumber;
                            });
                            this.filteredFormsData.forEach(x => {
                                if (x.admitDate !== null) {
                                    x.admitDate = utilService.formatDate(x.admitDate);
                                }
                                if (x.dateOfForm !== null) {
                                    x.dateOfForm = utilService.formatDate(x.dateOfForm);
                                }
                                let episodeNumber = this.allEpisodeData.find(e => e.episodeID === x.episodeId) != undefined ? this.allEpisodeData.find(e => e.episodeID === x.episodeId).episodeNumber : "-";
                                x.relatedEpisode = episodeNumber;  
                            });
                            this.filteredFormsData.sort((a,b) => {
                                return new Date(b.dateOfForm) - new Date(a.dateOfForm);
                            });
                            if (this.originalFormsData && this.originalFormsData.length && this.originalFormsData[0].mergedCrisisForms)
                            {
                                this.doesClientHaveMergedCrisisForms = true;
                                this.originalFormsData[0].mergedCrisisForms.forEach(x => {
                                    x.createDate = x.createDate !== null ? utilService.formatDate(x.createDate) : null;
                                    x.mergedDate = x.mergedDate !== null ? utilService.formatDate(x.mergedDate) : null;
                                    x.dateOfService = x.dateOfService !== null ? utilService.formatDate(x.dateOfService) : null;
                                });
                            }
                            else
                            {
                                this.doesClientHaveMergedCrisisForms = false;
                            }
                            resolve();
                        });
                    });
                }
                catch (error) {
                    console.error(error);
                    reject(error);
                }
            });
        },
        loadMergedCrisisClient() {
            if (this.$route.query.clientId) {
                edbsClientService.getByWCISClientId(this.$http, this.clientId).then(response => {
                    this.mergedCrisisClient = response.data
                })
            }
        },
        loadCrisisForms(id) {
            if (this.$route.query.clientId) {
                return crisisService.getRelatedForms(this.$http, this.clientId).then(response => {
                    this.crisisFormsData = response.data
                    this.crisisFormsData.forEach(x => {
                        if (x.createDate) x.createDate = utilService.formatDate(x.createDate)
                        if (x.mergedDate) x.mergedDate = utilService.formatDate(x.mergedDate)
                        if (x.dateOfService) x.dateOfService = utilService.formatDate(x.dateOfService)
                    })
                })
            } else {
                return new Promise((resolve, reject) => {
                    try {
                        clientSummaryService.getClientCrisisInformation(this.$http, id).then((crisisResponse) => {
                            this.crisisFormsData = crisisResponse.data;
                            this.crisisFormsData.forEach(x => {
                                if (x.createDate) x.createDate = utilService.formatDate(x.createDate);
                                if (x.mergedDate) x.mergedDate = utilService.formatDate(x.mergedDate);
                                if (x.dateOfService) x.dateOfService = utilService.formatDate(x.dateOfService);
                            });
                            resolve();
                        });
                    }
                    catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            }
        },
        loadSentinelEvents(id) {
            return carfService.getSentinelEventsByClientId(this.$http, id).then(response => {
                this.sentinelEvents = response.data
            }).catch(err => {
                console.warn(err)
            })
        },
        loadRAHSEpisodeInformation(id) {
            return new Promise((resolve, reject) => {
                try {
                    clientSummaryService.getClientRAHSEpisodeInformation(this.$http, id).then((rahsEpisodeResponse) => {
                        let rahsEpisodeIndex = 0;
                        rahsEpisodeResponse.data.forEach(d => {
                            rahsEpisodeIndex++;
                            d.rahsRequests.forEach(r => {
                                r = {...r, ...{episodeNumber: rahsEpisodeIndex.toString()}};
                                r = {...r, ...{waitlistDate: r.waitlistDate !== null ? utilService.formatDate(r.waitlistDate) : null}};
                                r = {...r, ...{acceptedDate: r.acceptedDate !== null ? utilService.formatDate(r.acceptedDate) : null}};
                                r = {...r, ...{completedDate: r.completedDate !== null ? utilService.formatDate(r.completedDate) : null}};
                                r = {...r, ...{removalDate: r.removalDate !== null ? utilService.formatDate(r.removalDate) : null}};
                                this.rahsEpisodeData.push(r);
                            });
                        });
                        resolve();
                    });
                }
                catch (error) {
                    console.error(error);
                    reject(error);
                }
            });
        },
        loadClientAuditHistory(id) {
            return new Promise((resolve, reject) => {
                try {
                    clientSummaryService.getClientAuditHistory(this.$http, id).then((auditResponse) => {
                        this.clientAuditHistory = auditResponse.data;
                        resolve();
                    });
                }
                catch (error) {
                    console.error(error);
                    reject(error);
                }
            });
        },
        loadEpisodesWithinRangeView() {
            this.isDla20WindowReady = true;
            this.isDla20DataLoading = true;
            this.filteredByDateAgencyEpisodes = this.allEpisodeData.filter(e => new Date(e.admitDate) >= new Date(this.dla20StartDate) && new Date(e.admitDate) <= new Date(this.dla20EndDate));
            this.filteredByDateAgencyEpisodeHeadersForDLA20Selection = this.episodeHeaders.slice(0,5);
            this.filteredByDateAgencyEpisodeHeadersForDLA20Selection.push({text: "Select This Episode?", value: "checkboxActions"})
            this.isDla20DataLoading = false;
        },
        loadIndividualDla20Report() {
            this.individualDLA20Dialog = false
            let misIdsToSearch = this.originalFormsData.filter(x => this.selectedEpisodeIdsForDLA20.includes(x.episodeId) && x.form === "MIS").flatMap(e => e.formID);
            clientSummaryService.getFullDLA20HistoryByMISIds(this.$http, misIdsToSearch).then(dlaResponse => {
                this.individualDLA20Data = dlaResponse.data;
                this.individualDLA20Dialog = true;
            });
        },
        getFYAndLockConfig() {
            return new Promise((resolve, reject) => {
                try {
                    clientSummaryService.getFYEnum(this.$http).then(enumResponse => {
                        this.fyList = enumResponse.data;
                        clientSummaryService.getFormLockConfig(this.$http).then(lockResponse => {
                            this.formFYLockDateConfig = lockResponse.data;
                            resolve();
                        });
                    });
                }
                catch(error) {
                    console.error(error);
                    reject();
                }
            });
        },
        navigateToMISForm() {
            this.$router.push("/mis?cl_wcisclientid=" + Buffer.from(this.clientId.toString()).toString('base64'));
        },
        navigateToESRForm() {
            this.$router.push("/esr?cl_wcisclientid=" + Buffer.from(this.clientId.toString()).toString('base64'));
        },
        navigateToCrisisForm() {
            this.$router.push('/crisis?cl_edbsclientid=' + Buffer.from(this.clientId.toString()).toString('base64'));
        },
        editClient() {
            this.editClientDialog = true;
        },
        addProviderClientIdDialogStart() {
            this.addProviderClientDialog = true;
        },
        editProviderClientIdDialogStart(item) {
            this.providerObjectToEdit = item;
            this.editProviderClientDialog = true;
        },
        confirmDeleteDialogStart(item) {
            this.confirmDeleteDialog = true;
            this.itemToDelete = item;
        },
        viewClientAuditHistory() {
            this.viewAuditDialog = true;
        },
        beginClientSummaryReport() {
            this.viewSummaryReportDialog = true;
        },
        addProviderClient(options) {
            this.addProviderClientDialog = false;
            if (this.isCrisisInstance) {
                options.providerObject.cL_EDBSClientID = this.clientId;

            }
            else if (!this.isCrisisInstance) {
                options.providerObject.cL_WCISClientID = this.clientId;
            }
            clientSummaryService.addProviderClientFromClientSummary(this.$http, options.providerObject, this.isCrisisInstance).then((providerResponse) => {
                if (providerResponse.data.validationResults && providerResponse.data.validationResults.length > 0 ) {
                    this.clientValidationModelState = providerResponse.data.validationResults;
                    window.scrollTo(0, 0);
                    return;
                }
                agencyGroupService.getAgencyGroups(this.$http, 0, providerResponse.data.agencyGroupID).then((agencyResponse) => {
                    if (this.isCrisisInstance) {
                        this.providerIDSFields.push({cL_ProviderEDBSClientID: providerResponse.data.cL_ProviderEDBSClientID, providerClientId: providerResponse.data.providerClientID, agency: agencyResponse.data.agencyGroupName, agencyGroupId: agencyResponse.data.agencyGroupID });
                    }
                    else if (!this.isCrisisInstance) {
                        this.providerIDSFields.push({ cL_ProviderClientID: providerResponse.data.cL_ProviderClientID, providerClientId: providerResponse.data.providerClientID, agency: agencyResponse.data.agencyGroupName, agencyGroupId: agencyResponse.data.agencyGroupID });
                    }
                });
            });
        },
        editProviderClient(options) {
            this.editProviderClientDialog = false;
            clientSummaryService.editProviderClientFromClientSummary(this.$http, options.providerObject, this.isCrisisInstance).then(() => {
                if (this.isCrisisInstance) {
                    const exisitingIndex = this.providerIDSFields.findIndex(x => x.cL_ProviderEDBSClientID === options.providerObject.cL_ProviderEDBSClientID);
                    this.providerIDSFields.splice(exisitingIndex, 1, options.providerObject);
                }
                else if (!this.isCrisisInstance) {
                    const exisitingIndex = this.providerIDSFields.findIndex(x => x.cL_ProviderEDBSClientID=== options.providerObject.cL_ProviderEDBSClientID);
                    this.providerIDSFields.splice(exisitingIndex, 1, options.providerObject);
                }
            });
        },
        updateLiveClient(options) {
           this.editClientDialog = false;
           clientSummaryService.updateClientFromClientSummary(this.$http, options.modifiedClient, this.isCrisisInstance).then((postResponse) => {
                try {
                    if (postResponse.data.validationResults && postResponse.data.validationResults.length > 0 ) {
                        this.clientValidationModelState = postResponse.data.validationResults;
                        window.scrollTo(0, 0);
                        return;
                    }
                    else if (!postResponse.data.validationResults || postResponse.data.validationResults.length === 0 ) {
                        this.client = postResponse.data;
                        this.client.dateOfBirth = utilService.formatDate(this.client.dateOfBirth);
                        this.client.birthCity = (this.client.birthCity && this.client.birthCity !== "") ? this.client.birthCity : enumService.getCityDescription(this.client.luBirthCityID);
                        this.client.birthCountry = (this.client.birthCountry && this.client.birthCountry !== "") ? this.client.birthCountry : enumService.getCountryDescription(this.client.luBirthCountryID);
                        this.client.birthState = (this.client.birthState && this.client.birthState !== "") ? this.client.birthState : enumService.getStateDescription(this.client.luBirthStateID);
                        return;
                    }
                } catch(error) {
                    console.error(error);
                }
            });
        },
        deleteConfirmed(options) {
            this.confirmDeleteDialog = false;
            //Because MIS and ESR forms require semi-complex server side calculations for episode information, they will contain special error handling procedures, along with 
            //re-performing a GET request for correlated episode and form data after each successful delete.
            if (options.transferObject.form === "MIS" && options.transferObject.formID) {
                this.isSectionLoading[2] = true;
                misService.deleteByObject(this.$http, options.transferObject).then(deleteResponse => {
                    this.loadEpisodeAndRelatedFormInformation(this.clientId);
                    this.isSectionLoading[2] = false;
                }).catch(error => {
                    this.isSectionLoading[2] = false;
                    this.clientValidationModelState.push({errorMessage: "Form delete was not successful. If this problem persists, please submit a helpdesk ticket."});
                    window.scrollTo(0,0);
                });
                return;
            }
            else if (options.transferObject.form === "ESR" && options.transferObject.formID) {
                this.isSectionLoading[2] = true;
                esrService.deleteByObject(this.$http, options.transferObject).then(deleteResponse => {
                    this.loadEpisodeAndRelatedFormInformation(this.clientId);
                    this.isSectionLoading[2] = false;
                }).catch(error => {
                    this.isSectionLoading[2] = false;
                    this.clientValidationModelState.push({errorMessage: "Form delete was not successful. If this problem persists, please submit a helpdesk ticket."});
                    window.scrollTo(0,0);
                });
                return;
            }
            else if (options.transferObject.tsH_EpisodeID) {
                this.rahsEpisodeData.splice(this.rahsEpisodeData.findIndex(x => x.tsH_EpisodeID === options.transferObject.tsH_EpisodeID), 1);
                for (const [index, val] of this.rahsEpisodeData.entries()) {
                    val.episodeNumber = index+1;
                }
                rahsService.deleteByObject(this.$http, options.transferObject);
                return;
            }
            else if (options.transferObject.fmT_EDBSID) {
                this.crisisFormsData.splice(this.crisisFormsData.findIndex(x => x.fmT_EDBSID === options.transferObject.fmT_EDBSID), 1);
                crisisService.deleteByObject(this.$http, options.transferObject);
                return;
            }
            else if (options.transferObject.cL_ProviderClientID) {
                this.providerIDSFields.splice(this.providerIDSFields.findIndex(x => x.cL_ProviderClientID === options.transferObject.cL_ProviderClientID), 1);
                clientSummaryService.deleteByProviderObject(this.$http, options.transferObject, this.isCrisisInstance);
                return;
            }
            else if (options.transferObject.cL_ProviderEDBSClientID) {
                this.providerIDSFields.splice(this.providerIDSFields.findIndex(x => x.cL_ProviderEDBSClientID === options.transferObject.cL_ProviderEDBSClientID), 1);
                clientSummaryService.deleteByProviderObject(this.$http, options.transferObject, this.isCrisisInstance);
                return;
            }
        },
        adminRahsActionConfirmed(options) {
            this.adminRahsDialog = false;
            options.modifiedItem.acceptedDate = options.modifiedItem.acceptedDate ? utilService.toJSONParsableDate(options.modifiedItem.acceptedDate) : null;
            options.modifiedItem.completedDate = options.modifiedItem.completedDate ? utilService.toJSONParsableDate(options.modifiedItem.completedDate) : null;
            options.modifiedItem.removalDate = options.modifiedItem.removalDate ? utilService.toJSONParsableDate(options.modifiedItem.removalDate) : null;
            options.modifiedItem.waitlistDate = options.modifiedItem.waitlistDate ? utilService.toJSONParsableDate(options.modifiedItem.waitlistDate) : null;
            if (options.subject === "editDate") {
                this.isLoading = true;
                try {
                    rahsService.editActionDates(this.$http, options.modifiedItem).then(() => {
                        this.rahsEpisodeData = new Array();
                        this.loadRAHSEpisodeInformation(this.clientId).then(() => {
                            this.isLoading = false;
                        });
                    });
                }
                catch(error) {
                    console.error(error);
                }
            }
            else if (options.subject === "revertStatus") {
                this.isLoading = true;
                try {
                    rahsService.revertStatus(this.$http, options.modifiedItem).then(() => {
                        this.rahsEpisodeData = new Array();
                        this.loadRAHSEpisodeInformation(this.clientId).then(() => {
                            this.isLoading = false;
                        });
                    });
                }
                catch(error) {
                    console.error(error);
                }
            }
            else {
                return;
            }
        },
        //Only reason that I am not using a computed section call is because I need these arguments
        determineDischargeType(dischargeDate, isSoftDischarge, luEpisodeStatusTypeId) {
            if (luEpisodeStatusTypeId === 3) return "Soft Discharge"
            else if (luEpisodeStatusTypeId === 4) return "Final System Discharge"
            else if (!isSoftDischarge && dischargeDate !== null) return "Hard Discharge"
            return "-"         
        },
        filterFormTableData(isClear, episodeID = 0) {
            if (!isClear) {
                this.filteredFormsData = this.originalFormsData.filter(form => {
                    if (form.episodeId === episodeID) {
                        return form;
                    }
                });
                return this.filteredFormsData;
            }
            else if (isClear) {
                this.filteredFormsData = this.originalFormsData;
                return this.filteredFormsData;
            }
            else {
                this.filteredFormsData = this.originalFormsData;
                return this.filteredFormsData;
            }
        },
        editOrViewForm(formData, isViewOnly) {
            let state = {"search": this.searchFormsHistory, "tab": this.tabs, "rows": this.activeTableRowsPerPage };
            let queryString = "formId=" + Buffer.from(formData.formID.toString()).toString('base64');
            localStorage.setItem("clientSummaryPageState", JSON.stringify(state));

            if (isViewOnly) {
                queryString += "&viewOnly=" + Buffer.from("true").toString('base64')
            }
            if (formData.form === "MIS") {
                this.$router.push("/mis?" + queryString);
            }
            else if (formData.form === "ESR") {
                queryString += "&agencyType=" + Buffer.from(formData.luAgencyTypeId.toString()).toString('base64')
                this.$router.push("/esr?" + queryString);
            }
            else {
                return;
            }
        },
        editOrViewRahsForm(formData, isViewOnly) {
            if (formData.tsH_EpisodeID && formData.tsH_RequestID) {
                let queryString = "tshEpisode=" + Buffer.from(formData.tsH_EpisodeID.toString()).toString('base64') + "&tshRequest=" + Buffer.from(formData.tsH_RequestID.toString()).toString('base64') + "&luTshStatusId=" + Buffer.from(formData.luTSHStatusID.toString()).toString('base64');
                if (isViewOnly) {
                    queryString += "&viewOnly=" + Buffer.from("true").toString('base64');
                }
                this.$router.push("/rahs-acceptance?" + queryString);
            }
            else {
                return;
            }
        },
        performRahsAdminAction(actionEnum, rahsObject) {
            if (actionEnum === 1) {
                this.adminRahsItemToModify = rahsObject;
                this.adminRahsIsRevert = false;
                this.adminRahsDialog = true;
                return;
            }
            else if (actionEnum === 2) {
                this.adminRahsItemToModify = rahsObject;
                this.adminRahsIsRevert = true;
                this.adminRahsDialog = true;
                return;
            }
            else {
                return;
            }
        },
        editOrViewCrisisForm(formData, isViewOnly) {
            if (formData.fmT_EDBSID) {
                let queryString = "formId=" + Buffer.from(formData.fmT_EDBSID.toString()).toString('base64');
                if (isViewOnly) {
                    queryString += "&viewOnly=" + Buffer.from("true").toString('base64');
                }
                this.$router.push("/crisis?" + queryString);
            }
            else {
                return;
            }
        },
        getDeletedItemOption(option) {
            if (option === "MIS") {
                misService.getDeletedFormsByClientId(this.$http, this.clientId).then((deletedFormsResponse) => {
                    this.deletedItemsToRecover.deletedItems = deletedFormsResponse.data;
                    this.deletedItemsToRecover.deletedItems.forEach(x => {
                        x.createDate = x.createDate ? utilService.formatDate(x.createDate) : null;
                        x.dateOfLastService = x.dateOfLastService ? utilService.formatDate(x.dateOfLastService) : null;
                        x.deletedDate = x.deletedDate ? utilService.formatDate(x.deletedDate) : null;
                    });
                    this.deletedItemsToRecover.subject = option;
                    this.restoreDialog = true;
                });
            }
            else if (option === "ESR") {
                esrService.getDeletedFormsByClientId(this.$http, this.clientId).then((deletedFormsResponse) => {
                    this.deletedItemsToRecover.deletedItems = deletedFormsResponse.data;
                    this.deletedItemsToRecover.deletedItems.forEach(x => {
                        x.createDate = x.createDate ? utilService.formatDate(x.createDate) : null;
                        x.dateOfLastService = x.dateOfLastService ? utilService.formatDate(x.dateOfLastService) : null;
                        x.deletedDate = x.deletedDate ? utilService.formatDate(x.deletedDate) : null;
                    });
                    this.deletedItemsToRecover.subject = option;
                    this.restoreDialog = true;
                });
            }
            else if (option === "Crisis") {
                crisisService.getDeletedFormsByClientId(this.$http, this.clientId).then((deletedFormsResponse) => {
                    this.deletedItemsToRecover.deletedItems = deletedFormsResponse.data;
                    this.deletedItemsToRecover.deletedItems.forEach(x => {
                        x.createDate = x.createDate ? utilService.formatDate(x.createDate) : null;
                        x.dateOfLastService = x.dateOfLastService ? utilService.formatDate(x.dateOfLastService) : null;
                        x.deletedDate = x.deletedDate ? utilService.formatDate(x.deletedDate) : null;
                    });
                    this.deletedItemsToRecover.subject = option;
                    this.restoreDialog = true;
                });
            }
            else if (option === "RAHS") {
                rahsService.getDeletedFormsByClientId(this.$http, this.clientId).then((deletedFormsResponse) => {
                    this.deletedItemsToRecover.deletedItems = deletedFormsResponse.data;
                    this.deletedItemsToRecover.deletedItems.forEach(x => {
                        x.createDate = x.createDate ? utilService.formatDate(x.createDate) : null;
                        x.dateOfLastService = x.dateOfLastService ? utilService.formatDate(x.dateOfLastService) : null;
                        x.deletedDate = x.deletedDate ? utilService.formatDate(x.deletedDate) : utilService.formatDate(x.modifiedDate);
                        x.episodeFormTypeDescription = x.tshStatus;
                    });
                    this.deletedItemsToRecover.subject = option;
                    this.restoreDialog = true;
                });
            }
        },
        confirmRestoreItems(option) {
            this.restoreDialog = false;
            if(option.itemsToRestore.length > 0) {
                if (option.subject === "MIS") {
                    this.isLoading = true;
                    misService.restoreByObject(this.$http, option.itemsToRestore).then((restorationResponse) => {
                        if (restorationResponse?.data && restorationResponse?.data.length > 0) {
                            this.clientValidationModelState = restorationResponse?.data;
                            window.scrollTo(0, 0);
                            this.isLoading = false;
                            return;
                        }
                        else {
                            this.loadEpisodeAndRelatedFormInformation(this.clientId).then(() => {
                                this.isLoading = false;
                                return;
                            });
                        }
                    });
                }
                else if (option.subject === "ESR") {
                    this.isLoading = true;
                    esrService.restoreByObject(this.$http, option.itemsToRestore).then((restorationResponse) => {
                        if (restorationResponse?.data && restorationResponse?.data.length > 0 ) {
                            this.clientValidationModelState = restorationResponse?.data;
                            window.scrollTo(0, 0);
                            this.isLoading = false;
                            return;
                        }
                        else {
                            this.loadEpisodeAndRelatedFormInformation(this.clientId).then(() => {
                                this.isLoading = false;
                                return;
                            });
                        }
                    });
                }
                else if (option.subject === "Crisis") {
                    this.isLoading = true;
                    crisisService.restoreByObject(this.$http, option.itemsToRestore).then(() => {
                        this.loadCrisisForms(this.clientId).then(() => {
                            this.isLoading = false;
                        });
                    });
                }
                else if (option.subject === "RAHS") {
                    this.isLoading = true;
                    rahsService.restoreByObject(this.$http, option.itemsToRestore).then(() => {
                        this.loadRAHSEpisodeInformation(this.clientId).then(() => {
                            this.isLoading = false;
                        });
                    });
                }
                else {
                    return;
                }
            }
            else {
                return;
            }
        },
        confirmAgencyDescriptionChange(changes) {
            this.agencyDescriptionDialogue = false;
            if (changes) {
                agencyService.saveDescriptionsOnly(this.$http, changes).then((postResponse) => {
                    if (postResponse.data.length === 0) {
                        this.snack = true;
                        this.snackColor = 'success';
                        this.snackText = 'Descriptions Updated Successfully!';                        
                    }
                    else {
                        this.clientValidationModelState = postResponse.data;
                        window.scrollTo(0,0);
                    }
                });
            }
        },
        getAgenciesForDescriptionEditing() {
            agencyService.getAgenciesByIds(this.$http, [... new Set(this.allEpisodeData.map(e => e.agencyID))].join()).then((agenciesResponse) => {
                this.agencyDescriptionsToModify = agenciesResponse.data;
                this.agencyDescriptionDialogue = true;
            })
        },
        createFileNameBasedOnCategory(category) {
            let fileName = `Client_ID_${this.client.clientID}_`;
            if (category === 1) {
                return fileName += "Episode_Summary.xls";
            }
            else if (category === 2) {
                return fileName += "Crisis_Forms.xls";
            }
            else if (category === 3) {
                return fileName += "Full_Episode_History.xls";
            }
            else if (category === 4) {
                return fileName += "Full_Forms_History.xls";
            }
            else if (category === 5) {
                return fileName += "Demographic_Data.xls";
            }
            else if (category === 6) {
                return fileName += "Treatment_Data.xls";
            }
            else if (category === 7) {
                return fileName += "RAHS_Forms.xls";
            }
            else {
                return fileName += "No_Category_Found.xls";
            }

        },
        setActiveTableRowsPerPage(val) {
            this.activeTableRowsPerPage = val; 
        },
        checkIfFormIsPastLockDate(item) {
            if (item.agencyGroupId === 24 && new Date() < new Date('08-31-2024')) {
                return false;
            } else {
                let fy = this.fyList.find(x => new Date(x.startDate) <= new Date(item.dateOfForm) && new Date(x.endDate) >= new Date(item.dateOfForm))
                let fiscalYearId = 0
                if (fy) fiscalYearId = fy.id
                let lockConfig = this.formFYLockDateConfig.find(x => x.luFiscalYearID === fiscalYearId);
                if (lockConfig && new Date() > new Date(lockConfig.lockDate)) return true
                if (!lockConfig) return true
                return false
            }
        },
        updateEpisodesToGetDLA20Info(episodeId) {
            if (this.selectedEpisodeIdsForDLA20.find(x => x === episodeId) != undefined) {
                this.selectedEpisodeIdsForDLA20.splice(this.selectedEpisodeIdsForDLA20.indexOf(episodeId), 1);
            }
            else {
                this.selectedEpisodeIdsForDLA20.push(episodeId);
            }
        },
        customSortSentinelEvent(items, i, desc) { return utilService.vDataTableCustomSort(items, i, desc, i[0] == 'dateOfIncidentDisplay') },
        rowBackground: function (x) {
            if (x.sentinelEventId && this.queryId && x.sentinelEventId == this.queryId) return 'selected-style'
            if (x.leadershipChangeId && this.queryId && x.leadershipChangeId == this.queryId) return 'selected-style'
            if (x.requiresUserAttention) return 'requires-attention-style'
            return ''
        },
        viewSentinelEvent(item) {
            if (item.requiresUserAttention) this.$router.push('sentinel-event?sentinelEventId=' + Buffer.from(item.sentinelEventId.toString()).toString('base64'))
            else this.$router.push('sentinel-event?sentinelEventId=' + Buffer.from(item.sentinelEventId.toString()).toString('base64') + '&viewOnly=' + Buffer.from('true').toString('base64'))
        },
        acknowledgeSentinelEvent(item) {
            this.$router.push('sentinel-event?sentinelEventId=' + Buffer.from(item.sentinelEventId.toString()).toString('base64'))
        },
        canEditForm(formData) {
            if (new Date(formData.dateOfForm) >= new Date('2024-07-01')) {
                return formData.form === 'MIS' || (formData.form === 'ESR' && formData.isAgencyGrantFunded);
            } else {
                return true;
            }
        },
        canDeleteForm(formData) {
            if (new Date(formData.dateOfForm) >= new Date('2024-07-01')) {
                return (this.isAgencyGroupUser && formData.isAgencyGrantFunded) || this.isAdminUser;
            } else {
                return true;
            }
        },
    },
}
</script>

<template>
    <v-container v-if="this.isLoading">
        <v-progress-circular :size="60" :width="7" color="deep-purple accent-4" indeterminate :style="{left: '50%', transform:'translateX(-50%)'}"></v-progress-circular>
    </v-container>
    <v-container v-else-if="!this.isLoading">
        <v-alert :value="clientValidationModelState && clientValidationModelState.length > 0" text prominent dismissible elevation="2" type="error" icon="mdi-cloud-alert">
            <h5>Validation failed</h5>
            <v-divider></v-divider>
            <div v-for="error in clientValidationModelState" :key="error.errorMessage">
                <ul><li>{{ error.errorMessage }}</li></ul>
            </div>
        </v-alert>
        <v-card elevation="5" outlined color="#82a6b8">
            <v-card-title class="black--text">
                <v-icon large left>mdi-account-lock</v-icon>
                Client ID: {{this.client.clientID}}
            </v-card-title>
            <v-card-actions>
                <edit-client-dialog :parent-client="client" :dialog.sync="editClientDialog" @close-dialog="editClientDialog=false;" @save-dialog="updateLiveClient" />
                <v-btn text color="black accent-4" class="mx-4" @click="editClient" v-if="canEditClient">Edit Client</v-btn>
                <v-btn v-if="showRahs" text color="black accent-4" class="mx-4" @click="$router.push('/rahs?cl_wcisclientid=' + clientId)">rahs</v-btn>
                <client-audit-history :parent-audit="clientAuditHistory" :dialog.sync="viewAuditDialog" @close-dialog="viewAuditDialog=false;" />
                <v-btn text color="black accent-4" class="mx-4" @click="viewClientAuditHistory">Client Info Revision History</v-btn>
                <client-summary-report-dialog :dialog.sync="viewSummaryReportDialog" :isCrisisClient="isCrisisInstance" :clientForReport="client" :episodeList="allEpisodeData" :rahsEpisodeList="rahsEpisodeData" @close-dialog="viewSummaryReportDialog=false;" />
                <v-btn v-if="!isWSHUser" text color="black accent-4" class="mx-4" @click="beginClientSummaryReport">Client Summary Services Reporting</v-btn>
            </v-card-actions>
        </v-card>
        <v-row class="mt-8">
            <v-col md="5">
                <v-expansion-panels focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Profile Data
                        </v-expansion-panel-header>
                        <v-expansion-panel-content >
                            <v-card class="mt-4 ">
                                <v-card-title class="black--text orange lighten-4">
                                    <v-icon large left>mdi-account-search</v-icon>
                                    Profile Data 
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <v-list>
                                    <!--We have some conflicting vuetify rules that force keys on templates even though it is not needed-->
                                    <!-- eslint-disable vue/no-v-for-template-key-on-child -->
                                    <template v-for="(field, index) in mapProfileData">
                                        <v-list-item :key="field.id">
                                    <!-- eslint-enable vue/no-v-for-template-key-on-child -->
                                            <v-list-item-content>
                                                <span class="text-decoration-underline font-weight-bold">{{field.text}}</span><span>{{field.mapData}}</span>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider v-if="index < profileDataFields.length -1" :key="index"></v-divider>
                                    </template>
                                    <!-- eslint-disable vue/no-v-for-template-key-on-child -->
                                    <v-divider></v-divider>
                                    <template v-for="(field, index) in mapCrisisProfileData" >
                                        <v-list-item :key="field.id" v-if="isCrisisInstance">
                                    <!-- eslint-enable vue/no-v-for-template-key-on-child -->
                                            <v-list-item-content>
                                                <span class="text-decoration-underline font-weight-bold">{{field.text}}</span><span>{{field.mapData}}</span>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider v-if="index < crisisProfileDataFields.length -1" :key="index+10"></v-divider>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col md="5" class="ml-md-auto">
                <v-expansion-panels focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Provider ID's
                        </v-expansion-panel-header>
                        <v-expansion-panel-content >
                            <v-card class="mt-4">
                                <v-card-title class="black--text orange lighten-4">
                                    <v-icon med left>mdi-doctor</v-icon>
                                    Provider ID's 
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <span class="float-left font-weight-bold">Provider Client ID</span><span class="pl-4 font-weight-bold">Agency Group</span><span class="float-right pr-4 font-weight-bold" v-if="!isAdminOrStateUser">Actions</span>
                                <v-divider></v-divider>
                                <v-list>
                                    <v-list-item v-for="item in providerIDSFields" :key="item.providerClientId">
                                        <v-list-item-content>
                                            <span>{{item.providerClientId}}</span>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <span>{{item.agency}}</span>
                                        </v-list-item-content>
                                        <v-list-item-content display="flex" style="justify-content: end; align-items: unset; ">
                                            <v-btn dark x-small color="deep-purple accent-4" height="24px" max-width="30px" v-if="!isAdminOrStateUser" @click="editProviderClientIdDialogStart(item)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn dark x-small color="deep-purple accent-4" height="24px" max-width="30px" v-if="!isAdminOrStateUser" @click="confirmDeleteDialogStart(item)">
                                                <v-icon class="pb-1">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                            <add-or-edit-provider-client :dialog.sync="addProviderClientDialog" :isEdit="false" @close-provider-dialog="addProviderClientDialog=false;" @save-provider-dialog="addProviderClient" />
                            <add-or-edit-provider-client :parentProviderClientObject="providerObjectToEdit" :dialog.sync="editProviderClientDialog" :isEdit="true" @close-provider-dialog="editProviderClientDialog=false;" @save-provider-dialog="editProviderClient" />
                            <confirm-delete :parentObject="itemToDelete" :dialog.sync="confirmDeleteDialog" :retain-focus="false" @close-delete-dialog="confirmDeleteDialog=false;" @confirm-delete-dialog="deleteConfirmed"/>
                            <v-btn text color="deep-purple accent-4" @click="addProviderClientIdDialogStart" v-if="!isAdminOrStateUser" >Add Provider ID</v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="canCreateForm">
                        <v-expansion-panel-header>
                            Create A Form
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="mt-4">
                                <v-card-title class="black--text orange lighten-4">
                                    <v-icon med left>mdi-clipboard-file</v-icon>
                                    Create A Form 
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <v-card-text style="text-align:center;">
                                    <v-btn v-if="!isCrisisInstance" color="deep-purple accent-4" class="white--text mr-6" @click="navigateToMISForm()">
                                        MIS
                                    </v-btn>
                                    <v-btn v-if="!isCrisisInstance && !isWSHUser" color="deep-purple accent-4" class="white--text" @click="navigateToESRForm()">
                                        ESR
                                    </v-btn>
                                    <v-btn v-if="isCrisisInstance" color="deep-purple accent-4" class="white--text ml-6" @click="navigateToCrisisForm()">
                                        Crisis
                                    </v-btn>
                                </v-card-text>
                            </v-card>                            
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="canRestoreForms">
                        <v-expansion-panel-header>
                            Restore Deleted Items 
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="mt-4">
                                <v-card-title class="black--text orange lighten-4">
                                    <v-icon med left>mdi-recycle</v-icon>
                                    Restore Deleted Items 
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <v-card-text>
                                    <v-btn color="deep-purple accent-4" class="white--text mb-2" v-if="!isCrisisInstance" rounded @click="getDeletedItemOption('MIS')">
                                        See Deleted MIS Forms
                                    </v-btn>
                                    <v-btn color="deep-purple accent-4" class="white--text mb-2" v-if="!isCrisisInstance && !isWSHUser" rounded @click="getDeletedItemOption('ESR')">
                                        See Deleted ESR Forms
                                    </v-btn>
                                    <v-btn color="deep-purple accent-4" class="white--text mb-2" v-if="isCrisisInstance" rounded @click="getDeletedItemOption('Crisis')">
                                        See Deleted Crisis Forms
                                    </v-btn>
                                    <v-btn color="deep-purple accent-4" class="white--text mb-2" v-if="!isCrisisInstance && isRahsUser" rounded @click="getDeletedItemOption('RAHS')">
                                        See Deleted RAHS Forms
                                    </v-btn>
                                </v-card-text>
                            </v-card>                            
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <restore-items-dialog :parentDeletedItems="deletedItemsToRecover" :fyLockConfig="formFYLockDateConfig" :fyEnumList="fyList" :dialog.sync="restoreDialog" :retain-focus="false" @close-restore-dialog="confirmRestoreItems"/>
                    <v-expansion-panel v-if="canEditAgencyDescriptions">
                        <v-expansion-panel-header>
                            Edit Agency Descriptions 
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="mt-4">
                                <v-card-title class="black--text orange lighten-4">
                                    <v-icon med left>mdi-store-edit</v-icon>
                                    Edit Agency Descriptions 
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <v-card-text>
                                    <v-btn color="deep-purple accent-4" class="white--text mb-2" v-if="isAdminOrStateUser" rounded @click="getAgenciesForDescriptionEditing()">
                                        See Available Agencies
                                    </v-btn>
                                </v-card-text>
                            </v-card>                            
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <agency-description-dialog :parentAgencyArray="agencyDescriptionsToModify" :dialog.sync="agencyDescriptionDialogue" :retain-focus="false" @close-agency-description-dialog="confirmAgencyDescriptionChange" />
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-toolbar dark flat prominent color="#82a6b8" class="black--text mt-8" :height="95">
            <v-tabs centered dark slider-color="deep-purple accent-4" v-model="tabs" class="pa-6" :height="70">
                <v-tab v-if="!isCrisisInstance" style="color: black !important">
                    <v-icon left>mdi-heart-pulse</v-icon>
                    Episode Summary
                </v-tab>
                <v-tab v-if="!isCrisisInstance" style="color: black !important">
                    <v-icon left>mdi-folder-information</v-icon>
                    Forms History
                </v-tab>
                <v-tab v-if="!isCrisisInstance" style="color: black !important">
                    <v-icon left>mdi-information-outline</v-icon>
                    Stats
                </v-tab>
                <v-tab v-if="!isCrisisInstance && !isWSHUser" style="color: black !important">
                    <v-icon left>mdi-exclamation-thick</v-icon>
                    Sentinel Events
                </v-tab>
                <v-tab v-if="(isAdminOrStateUser || (!isCrisisInstance && isRahsUser)) && !isWSHUser" style="color: black !important">
                    <v-icon left>mdi-bed</v-icon>
                    RaHs
                </v-tab>
                <v-tab v-if="!isCrisisInstance && !isWSHUser" style="color: black !important">
                    <v-icon left>mdi-archive-search</v-icon>
                    DLA-20 Information
                </v-tab>
                <v-tab v-if="isCrisisInstance || hasMergedCrisisClient" style="color: black !important">
                    <v-icon left>mdi-folder-information</v-icon>
                    Crisis Form Information
                </v-tab>
            </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tabs">
            <v-tab-item v-if="!isCrisisInstance">
                <v-card flat>
                    <v-card-title class="black--text">Latest Episode Information:</v-card-title>
                    <v-card-title>
                        <v-text-field
                            v-model="searchSummaryHistory"
                            append-icon="mdi-magnify"
                            label="Search For Any Keyword In Any Row"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table dense :headers="summaryHeaders" :items="latestEpisodeData" :search="searchSummaryHistory" item-key="episodeID" class="elevation-1" :sort-by.sync="defaultEpisodeSortField" :sort-desc.sync="defaultEpisodeSortDesc">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{row.item.agencyName}}</td>
                                    <td>{{row.item.agencyCode}}</td>
                                    <td>{{row.item.agencyType}}</td>
                                    <td>{{row.item.luEpisodeStatusTypeId === 1 ? "Open" : "Closed"}}</td>
                                    <td>{{row.item.admitDate}}</td>
                                    <td>{{row.item.lastFormDate}}</td>
                                    <td>{{row.item.dischargeDate}}</td>
                                    <td>{{determineDischargeType(row.item.dischargeDate, row.item.softDischargeExists, row.item.luEpisodeStatusTypeId)}}</td>
                                    <td>{{row.item.dischargeStatus ? row.item.dischargeStatus : "N/A"}}</td>
                                </tr>
                            </template>
                        </v-data-table>
                        <JsonExcel :data="latestEpisodeData" :fields="episodeSummaryFieldsForExport" :name=createFileNameBasedOnCategory(1)>
                            <v-btn class="white--text my-2" color="deep-purple accent-4" depressed>
                                Export Summary Data
                                <v-icon right>
                                    mdi-file-excel
                                </v-icon>
                            </v-btn>
                        </JsonExcel>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="!isCrisisInstance">
                <v-card flat>
                    <v-card-title class="black--text">Full Episode History:</v-card-title>
                    <v-card-title>
                        <v-text-field
                            v-model="searchEpisodesHistory"
                            append-icon="mdi-magnify"
                            label="Search For Any Keyword In Any Row"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table dense :headers="episodeHeaders" :items="allEpisodeData" :search="searchEpisodesHistory" item-key="episodeID" class="elevation-1" :sort-by.sync="defaultEpisodeSortField" :sort-desc.sync="defaultEpisodeSortDesc">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{row.item.episodeNumber}}</td>
                                    <td>{{row.item.agencyName}}</td>
                                    <td>{{row.item.agencyCode}}</td>
                                    <td>{{row.item.agencyType}}</td>
                                    <td>{{row.item.admitDate}}</td>
                                    <td>{{row.item.luEpisodeStatusTypeId === 1 ? "Open" : "Closed"}}</td>
                                    <td>{{row.item.lastFormDate}}</td>
                                    <td>{{row.item.dischargeDate}}</td>
                                    <td>{{determineDischargeType(row.item.dischargeDate, row.item.softDischargeExists, row.item.luEpisodeStatusTypeId)}}</td>
                                    <td>{{row.item.dischargeStatus ? row.item.dischargeStatus : "N/A"}}</td>
                                    <td>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="filterFormTableData(false, row.item.episodeID)">
                                            <v-icon>mdi-archive-search</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="filterFormTableData(true)">
                                            <v-icon>mdi-close-octagon</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        <JsonExcel :data="allEpisodeData" :fields="fullEpisodeFieldsForExport" :name=createFileNameBasedOnCategory(3)>
                            <v-btn class="white--text my-2" color="deep-purple accent-4" depressed>
                                Export Full Episode Data
                                <v-icon right>
                                    mdi-file-excel
                                </v-icon>
                            </v-btn>
                        </JsonExcel>
                    </v-card-text>
                </v-card>
                <v-card flat>
                    <v-card-title class="black--text">Forms History:</v-card-title>
                    <v-card-title>
                        <v-text-field v-model="searchFormsHistory" append-icon="mdi-magnify" label="Search For Any Keyword In Any Row" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-card-text v-if="isSectionLoading[2]">
                        <v-progress-circular :size="50" :width="10" color="deep-purple accent-4" indeterminate :style="{left: '50%', transform:'translateX(-50%)'}"></v-progress-circular>
                    </v-card-text>
                    <v-card-text v-else-if="!isSectionLoading[2]">
                        <v-data-table dense :headers="formsHeaders" :items="filteredFormsData" :search="searchFormsHistory" :items-per-page="activeTableRowsPerPage" @update:items-per-page="setActiveTableRowsPerPage" item-key="formID" class="elevation-1">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{row.item.agencyName}}</td>
                                    <td>{{row.item.admitDate}}</td>
                                    <td>{{row.item.form}}</td>
                                    <td>{{row.item.dateOfForm}}</td>
                                    <td> <p v-html="row.item.formType"></p></td>
                                    <td>{{row.item.relatedEpisode}}</td>
                                    <td>{{row.item.units}}</td>
                                    <td>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="editOrViewForm(row.item, true)">
                                            <v-icon>mdi-file-find</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" v-if="canEditForm(row.item)" :disabled="checkIfFormIsPastLockDate(row.item)" @click="editOrViewForm(row.item, false)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" v-if="canDeleteForm(row.item)" :disabled="checkIfFormIsPastLockDate(row.item)" @click="confirmDeleteDialogStart(row.item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        <JsonExcel :data="filteredFormsData" :fields="fullFormsFieldsForExport" :name=createFileNameBasedOnCategory(4)>
                            <v-btn class="white--text my-2" color="deep-purple accent-4" depressed>Export Full Forms Data<v-icon right>mdi-file-excel</v-icon></v-btn>
                        </JsonExcel>
                        <confirm-delete :parentObject="itemToDelete" :dialog.sync="confirmDeleteDialog" :retain-focus="false" @close-delete-dialog="confirmDeleteDialog=false;" @confirm-delete-dialog="deleteConfirmed"/>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="!isCrisisInstance">
                 <v-card flat>
                     <v-card-title class="text-h5 black--text">Demographic Data</v-card-title>
                    <v-card-title>
                        <v-text-field v-model="searchDemographics" append-icon="mdi-magnify" label="Search For Any Keyword In Any Row" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table dense :headers="demographicsHeaders" :items="client.cL_WCISClient_Informations" :search="searchDemographics" item-key="cL_WCISClient_InformationID" class="elevation-1">
                            
                        </v-data-table>
                        <JsonExcel :data="client.cL_WCISClient_Informations" :fields="demographicFieldsForExport" :name=createFileNameBasedOnCategory(5)>
                            <v-btn class="white--text my-2" color="deep-purple accent-4" depressed>Export Demographic Data<v-icon right>mdi-file-excel</v-icon></v-btn>
                        </JsonExcel>
                    </v-card-text>
                </v-card>
                <v-card flat>
                    <v-card-title class="text-h5 black--text">Treatment Data</v-card-title>
                    <v-card-title>
                        <v-text-field v-model="searchTreatments" append-icon="mdi-magnify" label="Search For Any Keyword In Any Row" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table dense :headers="treatmentHeaders" :items="client.cL_WCISClient_Informations" :search="searchTreatments" item-key="cL_WCISClient_InformationID" class="elevation-1">
                        </v-data-table>
                        <JsonExcel :data="client.cL_WCISClient_Informations" :fields="treatmentDataFieldsForExport" :name=createFileNameBasedOnCategory(6)>
                            <v-btn class="white--text my-2" color="deep-purple accent-4" depressed>Export Treatment Data<v-icon right>mdi-file-excel</v-icon></v-btn>
                        </JsonExcel>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="!isCrisisInstance">
                <v-card flat>
                    <v-card-title class="text-h5 black--text">Sentinel Event History</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="sentinelEventHeaders"
                                      :items="sentinelEvents"
                                      :items-per-page="15"
                                      loading-text="Loading... Please wait"
                                      class="elevation-1"
                                      :item-class="rowBackground"
                                      item-key="sentinelEventId"
                                      :search="sentinelEventSearch"
                                      dense
                                      :custom-sort="customSortSentinelEvent">
                            <template v-slot:top>
                                <div class="d-flex">
                                    <v-text-field v-model="sentinelEventSearch" append-icon="mdi-magnify" label="Search" hide-details clearable></v-text-field>
                                </div>
                            </template>
                            <template v-slot:[`item.isAcknowledged`]="{ item }">
                                <v-simple-checkbox v-model="item.isAcknowledged" disabled></v-simple-checkbox>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip left color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-if="isAdminOrStateUser && !item.isAcknowledged" v-bind="attrs" v-on="on" small @click="acknowledgeSentinelEvent(item)">mdi-thumb-up</v-icon>
                                    </template>
                                    <span>Acknowledge</span>
                                </v-tooltip>
                                <v-tooltip left color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small @click="viewSentinelEvent(item)" class="mx-1">mdi-magnify</v-icon>
                                    </template>
                                    <span>View</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="isAdminOrStateUser || (!isCrisisInstance && isRahsUser)">
               <v-card flat>
                    <v-card-title class="black--text">RAHS Episode Information:</v-card-title>
                    <v-card-title>
                        <v-text-field v-model="searchRahsEpisodesHistory" append-icon="mdi-magnify" label="Search For Any Keyword In Any Row" single-line hide-details></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table dense :headers="rahsHeaders" :items="rahsEpisodeData" :search="searchRahsEpisodesHistory" item-key="id" class="elevation-1">
                             <template v-slot:item="row">
                                <tr>
                                    <td>{{row.item.episodeNumber}}</td>
                                    <td>{{row.item.agencyName}}</td>
                                    <td>{{row.item.agencyCode}}</td>
                                    <td>{{row.item.tshStatus}}</td>
                                    <td>{{row.item.waitlistDate}}</td>
                                    <td>{{row.item.acceptedDate}}</td>
                                    <td>{{row.item.completedDate}}</td>
                                    <td>{{row.item.luTSHRemovalTypeID === 1 || row.item.luTSHRemovalTypeID === 3 ? row.item.removalDate : "N/A"}}</td>
                                    <td>{{row.item.luTSHRemovalTypeID === 2 ? row.item.removalDate : "N/A"}}</td>
                                    <td>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="editOrViewRahsForm(row.item, true)">
                                            <v-icon>mdi-file-find</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="editOrViewRahsForm(row.item, false)" v-if="isRahsUser">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="confirmDeleteDialogStart(row.item)" v-if="isAdminUser">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                    <td v-if="isAdminUser">
                                        <v-btn dark x-small color="deep-purple accent-4" @click="performRahsAdminAction(1, row.item)" >
                                            <v-icon>mdi-calendar-refresh</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="performRahsAdminAction(2, row.item)">
                                            <v-icon>mdi-undo</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                        <admin-rahs-actions :parentRahsObject="adminRahsItemToModify" :isRevert="adminRahsIsRevert" :dialog.sync="adminRahsDialog" :retain-focus="false" @save-admin-rahs-dialog="adminRahsActionConfirmed" @close-admin-rahs-dialog="adminRahsDialog=false;"/>
                        <JsonExcel :data="rahsEpisodeData" :fields="rahsFieldsForExport" :name=createFileNameBasedOnCategory(7)>
                            <v-btn class="white--text my-2" color="deep-purple accent-4" depressed>
                                Export RAHS Forms
                                <v-icon right>
                                    mdi-file-excel
                                </v-icon>
                            </v-btn>
                        </JsonExcel>
                        <confirm-delete :parentObject="itemToDelete" :dialog.sync="confirmDeleteDialog" :retain-focus="false" @close-delete-dialog="confirmDeleteDialog=false;" @confirm-delete-dialog="deleteConfirmed"/>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="!isCrisisInstance">
               <v-card flat>
                <v-card-title class="black--text">DLA-20 Information:</v-card-title>
                <v-card-text>
                    <h5 v-if="!(dla20StartDate && dla20EndDate)">Please Select Your Desired Date Range</h5>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="dla20StartDate"
                                        label="Start Date"
                                        type="date" clearable></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="dla20EndDate"
                                        label="End Date"
                                        type="date" clearable></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col style="text-align: center;">
                            <v-btn text color="deep-purple accent-4" class="mx-4" @click="loadEpisodesWithinRangeView" :disabled="!(dla20StartDate && dla20EndDate)">Load Episodes For Selected Date Range</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-text v-if="(isDla20DataLoading && isDla20WindowReady)">
                    <v-progress-circular :size="60" :width="20" color="deep-purple accent-4" indeterminate :style="{left: '50%', transform:'translateX(-50%)'}"></v-progress-circular>
                </v-card-text>
                <v-card-text v-else-if="(!isDla20DataLoading && isDla20WindowReady)">
                    <v-data-table dense :headers="filteredByDateAgencyEpisodeHeadersForDLA20Selection" :items="filteredByDateAgencyEpisodes" class="elevation-1">
                        <template v-slot:item="row">
                                <tr>
                                    <td>{{row.item.episodeNumber}}</td>
                                    <td>{{row.item.agencyName}}</td>
                                    <td>{{row.item.agencyCode}}</td>
                                    <td>{{row.item.agencyType}}</td>
                                    <td>{{row.item.admitDate}}</td>
                                    <td>
                                        <v-checkbox color="orange lighten-4" @click="updateEpisodesToGetDLA20Info(row.item.episodeID)">
                                        </v-checkbox>
                                    </td>
                                </tr>
                            </template>
                    </v-data-table>
                    <v-row>
                        <v-col style="text-align: center;">
                            <v-btn text color="deep-purple accent-4" class="mx-4" @click="loadIndividualDla20Report" :disabled="selectedEpisodeIdsForDLA20.length < 1">Load DLA-20 Information</v-btn>
                        </v-col>
                    </v-row>
                    <individual-dla20-report :rawDLA20Data="individualDLA20Data" :episodeData="allEpisodeData.filter(x => selectedEpisodeIdsForDLA20.includes(x.episodeID))" :episodeFormsData="originalFormsData" :clientId="client.clientID" :dialog.sync="individualDLA20Dialog" :retain-focus="false" @close-dla-dialog="individualDLA20Dialog=false;"/>
                </v-card-text>
               </v-card>
            </v-tab-item>
            <v-tab-item v-if="isCrisisInstance || hasMergedCrisisClient">
                <v-card flat>
                    <v-card-title class="black--text">Crisis Form Information:</v-card-title>
                    <v-card-title>
                        <v-text-field
                            v-model="searchCrisisHistory"
                            append-icon="mdi-magnify"
                            label="Search For Any Keyword In Any Row"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table dense 
                                      :headers="crisisHeaders" 
                                      :items="crisisItems" 
                                      :search="searchCrisisHistory" 
                                      item-key="fmT_EDBSID" 
                                      class="elevation-1">
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{row.item.agencyName}}</td>
                                    <td>{{row.item.createDate}}</td>
                                    <td>{{row.item.mergedDate}}</td>
                                    <td>{{row.item.dateOfService}}</td>
                                    <td>{{row.item.formType}}</td>
                                    <td>{{row.item.locationOfService}}</td>
                                    <td>{{row.item.units}}</td>
                                    <td>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="editOrViewCrisisForm(row.item, true)">
                                            <v-icon>mdi-file-find</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="editOrViewCrisisForm(row.item, false)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn dark x-small color="deep-purple accent-4" @click="confirmDeleteDialogStart(row.item)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>                            
                        </v-data-table>
                        <JsonExcel :data="crisisFormsData" :fields="crisisFormsFieldsForExport" :name=createFileNameBasedOnCategory(2)>
                            <v-btn class="white--text my-2" color="deep-purple accent-4" depressed>
                                Export Crisis Data
                                <v-icon right>
                                    mdi-file-excel
                                </v-icon>
                            </v-btn>
                        </JsonExcel>
                        <confirm-delete :parentObject="itemToDelete" :dialog.sync="confirmDeleteDialog" :retain-focus="false" @close-delete-dialog="confirmDeleteDialog=false;" @confirm-delete-dialog="deleteConfirmed"/>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-row>
            <v-col>
                <Snackbar :snack="snack" :snackColor="snackColor" :snackText="snackText" @snackchange="snack = !snack"></Snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
var path = 'api/improv/providerapplication/'

function getProviderApplicationListByUser(http) {
    return http.get(path + 'get-provider-application-list-by-user')
}

function getProviderApplicationHistoryByProviderId(http, providerId) {
    return http.get(path + `get-provider-application-history-by-provider/${providerId}`)
}

function getProviderApplicationProcessInstanceByProcessInstanceId(http, processInstanceId) {
    return http.get(path + 'get-provider-application-process-instance-by-process-instance-id/' + processInstanceId)
}

function getProviderApplicationProcessInstanceListByUser(http) {
    return http.get(path + 'get-provider-application-process-instance-list-by-user')
}

function getProviderApplicationProcessInstanceListInProgressByUser(http) {
    return http.get(path + 'get-provider-application-process-instance-list-in-progress-by-user')
}

function save(http, dto) {
    return http.post(path + 'save', dto)
}

function submit(http, dto) {
    return http.post(path + 'submit', dto)
}

function approve(http, dto, expiryMonthsToAdd) {
    const url = `${path}approve?expiryMonthsToAdd=${expiryMonthsToAdd}`
    return http.post(url, dto)
}

function reject(http, dto) {
    return http.post(path + 'reject', dto)
}

function cancel(http, dto) {
    return http.post(path + 'cancel', dto)
}

function revertProcess(http, processInstanceId) {
    return http.post(path + 'revert-process/' + processInstanceId)
}

export default {
    getProviderApplicationListByUser: getProviderApplicationListByUser,
    getProviderApplicationHistoryByProviderId: getProviderApplicationHistoryByProviderId,
    getProviderApplicationProcessInstanceByProcessInstanceId: getProviderApplicationProcessInstanceByProcessInstanceId,
    getProviderApplicationProcessInstanceListByUser: getProviderApplicationProcessInstanceListByUser,
    getProviderApplicationProcessInstanceListInProgressByUser: getProviderApplicationProcessInstanceListInProgressByUser,
    save: save,
    submit: submit,
    approve: approve,
    reject: reject,
    cancel: cancel,
    revertProcess: revertProcess
}
var path = 'api/accessrequest/'

function get(http, id) {
    return http.get(`${path}${id}`)
}

function getAccessRequestByClaims(http) {
    return http.get(`${path}get-access-request-by-claims`)
}

function getList(http, reviewed, approved) {
    return http.get(`${path}getlist/${reviewed}/${approved}`)
}

function requestaccess(http, dto) {
    return http.post(`${path}requestaccess`, dto)
}

function approve(http, dto) {
    return http.post(`${path}approve`, dto)
}

function deny(http, dto) {
    return http.post(`${path}deny`, dto)
}

export default {
    get: get,
    getAccessRequestByClaims: getAccessRequestByClaims,
    getList: getList,
    requestaccess: requestaccess,
    approve: approve,
    deny: deny
}
<template>
    <v-card>
        <v-container fluid v-if="!$auth.loading">
            <v-row>
                <v-col v-for="w in filteredWidgets" :key="w.title" :lg="2" :md="4" :sm="6">
                    <router-link :to="formRouterInput(w)" v-if="w.isrouted">
                        <v-container>
                            <v-icon color="background" size="60" class="d-block text-center mx-auto mb-2">{{ w.image }}</v-icon>
                            <!--I see that this is used by CARF, but I also need a chip just for information purposes, doesn't need to route to anything-->
                            <v-chip v-if="w.chip" label color="warning" text-color="white" class="d-block text-center ma-3 text--text font-weight-bold" :to="formRouterInput(w)">{{ w.text }} {{ w.chip }}</v-chip>
                            <v-subheader v-else class="d-block text-center mb-2 text--text font-weight-bold" style="height: 16px">{{ w.text }}</v-subheader>
                            <v-chip v-if="isNewAnnouncementChipVisible && w.usesInfoChip" style="margin-left: 2rem !important;" color="red" outlined class="ma-2">{{ w.chipText }}</v-chip>
                            <v-subheader class="d-block text-center mb-2 text--text font-weight-bold"><span :class="getColor(w)" v-html="w.infotext"></span></v-subheader>
                        </v-container>
                    </router-link>
                    <v-container v-if="contractDeliverableOutline && !w.isrouted">
                        <v-icon color="background" size="60" class="d-block text-center mx-auto mb-2" @click="getClickEvent(w)">{{ w.image }}</v-icon>
                        <v-subheader class="d-block text-center mb-0 text--text font-weight-bold">{{ w.text }}</v-subheader>
                        <v-subheader class="d-block text-center mb-0 text--text font-weight-bold"><span :class="getColor(w)" v-html="w.infotext"></span></v-subheader>
                    </v-container>
                    <v-container v-if="!w.isrouted && w.title === 'provider-guidance'" @click="openLink(w.to)" class="clickable">
                        <v-icon color="background" size="60" class="d-block text-center mx-auto mb-2">{{ w.image }}</v-icon>
                        <v-chip v-if="w.chip" label color="warning" text-color="white" class="d-block text-center ma-3 text--text font-weight-bold">{{ w.text }} {{ w.chip }}</v-chip>
                        <v-subheader v-else class="d-block text-center mb-2 text--text font-weight-bold" style="height: 16px">{{ w.text }}</v-subheader>
                        <v-chip v-if="isNewAnnouncementChipVisible && w.usesInfoChip" style="margin-left: 2rem !important;" color="red" outlined class="ma-2">{{ w.chipText }}</v-chip>
                        <v-subheader class="d-block text-center mb-2 text--text font-weight-bold"><span :class="getColor(w)" v-html="w.infotext"></span></v-subheader>
                    </v-container>
                    <v-container v-if="!w.isrouted && w.title === 'provider-change'" @click="initializeUpdate(w.providerId)" class="clickable">
                        <v-icon color="background" size="60" class="d-block text-center mx-auto mb-2">{{ w.image }}</v-icon>
                        <v-chip v-if="w.chip" label color="warning" text-color="white" class="d-block text-center ma-3 text--text font-weight-bold">{{ w.text }} {{ w.chip }}</v-chip>
                        <v-subheader v-else class="d-block text-center mb-2 text--text font-weight-bold" style="height: 16px">{{ w.text }}</v-subheader>
                        <v-chip v-if="isNewAnnouncementChipVisible && w.usesInfoChip" style="margin-left: 2rem !important;" color="red" outlined class="ma-2">{{ w.chipText }}</v-chip>
                        <v-subheader class="d-block text-center mb-2 text--text font-weight-bold"><span :class="getColor(w)" v-html="w.infotext"></span></v-subheader>
                    </v-container>
                    <v-container v-if="!w.isrouted && w.title === 'provider-renewal'">
                        <v-icon color="background" size="60" class="d-block text-center mx-auto mb-2">{{ w.image }}</v-icon>
                        <v-chip v-if="w.chip" label color="warning" text-color="white" class="d-block text-center ma-3 text--text font-weight-bold">{{ w.text }} {{ w.chip }}</v-chip>
                        <v-subheader v-else class="d-block text-center mb-2 text--text font-weight-bold" style="height: 16px">{{ w.text }}</v-subheader>
                        <v-chip v-if="isNewAnnouncementChipVisible && w.usesInfoChip" style="margin-left: 2rem !important;" color="red" outlined class="ma-2">{{ w.chipText }}</v-chip>
                        <v-subheader class="d-block text-center mb-2 text--text font-weight-bold"><span class="success--text" v-html="w.infotext"></span></v-subheader>
                    </v-container>
                </v-col>
            </v-row>
            <v-overlay :value="isLoading">
                <v-progress-circular :size="60"
                                     :width="7"
                                     color="deep-purple accent-4"
                                     indeterminate>
                </v-progress-circular>
            </v-overlay>
        </v-container>
    </v-card>
</template>

<script>
// @ is an alias to /src

    import { mapState } from 'vuex'
    import userService from '@/services/userService'
    import uploadService from '@/services/uploadService'
    import misService from '@/services/misService'
    import esrService from '@/services/esrService'
    import rahsService from '@/services/rahsService'
    import carfService from '@/services/carfService'
    import mediaService from '@/services/mediaService'
    import { providerAnnouncementService } from '@/services/providerAnnouncementService'
    import providerApplicationService from '@/services/improv/providerApplicationService'
    import providerService from '@/services/improv/providerService'
    import providerUpdateService from '@/services/improv/providerUpdateService'
    import providerRenewalService from '@/services/improv/providerRenewalService'
    import utilService from '@/services/util/utilService'

export default {
    data: () => ({
        name: 'Home',
        logo: '@/assets/logo.png',
        //error: this.$route.query.error,
        widgets: [
            {
                title: 'accuracy',
                infotext: '',
                to: '/accuracy',
                text: 'Accuracy',
                image: 'mdi-checkbox-marked-circle-outline',
                isrouted: true,

                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: true,
            },
            {
                title: 'qol',
                infotext: '',
                to: '/qol',
                text: 'Quality of Life',
                image: 'mdi-currency-usd',
                isrouted: true,

                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'rahs',
                infotext: '',
                to: '/rahs',
                text: 'RAHS',
                image: 'mdi-bed-outline',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: true,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'upload',
                infotext: '',
                to: '/upload',
                text: 'Upload',
                image: 'mdi-cloud-upload-outline',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: false,
                isagencygroup: true,
                israhs: false,
                iswsh: true,
                isprovider: false,
                issor: false,
            },
            {
                title: 'form-entry',
                infotext: '',
                to: '/form-entry',
                text: 'Form Entry',
                image: 'mdi-file-document-outline',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: false,
                isagencygroup: true,
                israhs: false,
                iswsh: true,
                isprovider: false,
                issor: true,
            },
            {
                title: 'carf',
                infotext: '',
                to: '/carf',
                text: 'CARF',
                image: 'mdi-check-decagram-outline',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'task-list',
                infotext: '',
                to: '/task-list',
                text: 'Task List',
                image: 'mdi-bell-outline',
                isrouted: true,

                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'document-library',
                infotext: '',
                to: '/document-library',
                text: 'Document Library',
                image: 'mdi-file-document-multiple-outline',
                isrouted: true,

                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'invoice-generate',
                infotext: '',
                to: '/invoice-generate',
                text: 'Generate Invoices',
                image: 'mdi-cog-outline',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'client-merge',
                infotext: '',
                to: '/client-merge',
                text: 'Merge Client',
                image: 'mdi-merge',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'teds',
                infotext: '',
                to: '/teds',
                text: 'TEDS',
                image: 'mdi-file-export-outline',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'mhdig',
                infotext: '',
                to: '/mhdig',
                text: 'MH-CLD',
                image: 'mdi-file-delimited-outline',
                date: '',
                isrouted: true,

                isadmin: true,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'contracts-deliverables-outline',
                infotext: '',
                to: '/',
                text: 'Contracts Deliverables Outline',
                image: 'mdi-calendar',
                date: '',
                isrouted: false,

                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'training-forms',
                infotext: '',
                to: '/training-forms',
                text: "Training Forms",
                image: "mdi-application-edit-outline",
                date: '',
                isrouted: true,

                isadmin: false,
                isstateuser: false,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'dla20-guidance',
                infotext: '',
                to: '/document-library',
                text: "DLA-20 Guidance",
                image: "mdi-help-circle",
                date: '',
                isrouted: true,

                isadmin: false,
                isstateuser: false,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'provider-bulletin',
                infotext: '',
                to: '/provider-bulletin',
                text: "Provider Bulletins",
                image: "mdi-bulletin-board",
                date: '',
                isrouted: true,
                usesInfoChip: true,
                chipText: "New announcements!",

                isadmin: false,
                isstateuser: false,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
            },
            {
                title: 'provider-guidance',
                infotext: '',
                to: 'https://health.wyo.gov/behavioralhealth/mhsa/certification/',
                text: "Provider Guidance",
                image: "mdi-open-in-new",
                date: '',
                isrouted: false,
                usesInfoChip: true,
                chipText: "",

                isadmin: false,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: true,
                issor: false,
                alwaysshow: true
            },
            {
                title: 'new-application',
                infotext: '',
                to: '/provider-application',
                text: "New Application",
                image: "mdi-note-plus",
                date: '',
                isrouted: true,
                usesInfoChip: true,
                chipText: "",

                isadmin: false,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: true,
                issor: false,
                showfornewprovider: true
            },
            {
                title: 'provider-change',
                infotext: 'You cannot submit a change while a Renewal is in progress.',
                to: '',
                text: "Submit a Change",
                image: "mdi-file-document-edit-outline",
                date: '',
                isrouted: true,
                usesInfoChip: true,
                chipText: "",
                providerId: null,

                isadmin: false,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: true,
                issor: false,
                showforexistingprovider: true
            },
            {
                title: 'provider-renewal',
                infotext: '',
                to: '',
                text: "Application Renewal",
                image: "mdi-redo",
                date: "",
                isrouted: false,
                usesInfoChip: true,
                chipText: "",

                isadmin: false,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: true,
                issor: false,
                showforexistingprovider: true
            }
        ],
        isLoading: false,
        mis: null,
        esr_mh: null,
        esr_sa: null,
        latestFormDates: [],
        latestFormDate: null,
        upload_header: null,
        qol_header: null,
        rahs_request: null,
        sentinelEvent: null,
        leadershipChange: null,
        adminComm: null,
        latestAdminCommUploadList: [],
        latestCARFDates: [],
        latestCARFDate: null,
        unacknowledgedSentinelEventList: [],
        unacknowledgedLeadershipChangeList: [],
        requiresUserAttentionSentinelEventList: [],
        requiresUserAttentionLeadershipChangeList: [],
        contractDeliverableOutline: null,
        isNewAnnouncementChipVisible: false,
        isNewProvider: null,
        providerId: null,
        providerExpirationDate: null,

        accuracy_index: 0,
        qol_index: 1,
        rahs_index: 2,
        upload_index: 3,
        form_index: 4,
        carf_index: 5,
    }),
    computed: {
        ...mapState('auth', ['wybhdsUserDto']),
        isWSHUser: function () { return (this.wybhdsUserDto && userService.isWSHUser()) },
        isRahsUser: function () { return (this.wybhdsUserDto && userService.isRahsUser()) },
        isAgencyGroupUser: function () { return (this.wybhdsUserDto && userService.isAgencyGroupUser()) },
        isAdminUser: function () { return (this.wybhdsUserDto && userService.isAdministrator()) },
        isStateUser: function () { return (this.wybhdsUserDto && userService.isStateUser()) },
        isProvider: function () { return (this.wybhdsUserDto && userService.isProvider()) },
        isSorUser: function () { return (this.wybhdsUserDto && userService.isSorUser()) },
        filteredWidgets() {
            var w = [...this.widgets];
            const today = new Date();
            const cutoffDate = new Date('2024-07-16');

            if (this.isAdminUser) return w.filter(x => x.isadmin);
            if (this.isStateUser) return w.filter(x => x.isstateuser);
            if (this.isWSHUser) return w.filter(x => x.iswsh);

            w = w.filter(widget => {
                if (this.isAgencyGroupUser)
                {
                    if (widget.title === 'rahs' && today > cutoffDate && this.wybhdsUserDto.agencyGroupId !== 25) {
                        return false;
                    }
                } 
                return true;
            });

            let widgets = [];

            if (this.isProvider) {
                if (this.isNewProvider === null) return [];
                const providerWidgets = w.filter(x => x.isprovider && (
                    x.alwaysshow ||
                    (this.isNewProvider ? x.showfornewprovider : x.showforexistingprovider)
                ));
                widgets.push(...providerWidgets);
            }

            if (this.isAgencyGroupUser) {
                const agencyGroupWidgets = w.filter(x => x.isagencygroup);
                widgets.push(...agencyGroupWidgets);
            }

            if (this.isRahsUser) {
                const rahsWidgets = w.filter(x => x.israhs);
                widgets.push(...rahsWidgets);
            }

            if (this.isSorUser) {
                const sorWidgets = w.filter(x => x.issor);
                widgets.push(...sorWidgets);
            }

            // Filter out duplicates
            return widgets.filter((x, i, ar) => ar.findIndex(y => y === x) === i);
        }

    },
    mounted() {
        //if (this.$route.query.error) {
        //    this.$router.push(`/login-error?error=${this.$route.query.error}&error_description=${this.$route.query.error_description}`)
        //}
        if (this.isAdminUser) {
            this.getCARFAdminInfoText()
        }

        if (this.isAgencyGroupUser) {
            //this.loadUpload()
            this.getAccuracyDueDate()
            this.getLatestFormDate()
            //this.getLatestCARFDate()  obsolete, using RequiresUserAttention flag now
            this.getCARFAgencyInfoText()

            if (!this.isWSHUser) {
                this.getQolDueDate()
            }
        }

        if (this.isRahsUser) this.loadLatestRahs()

        if (!this.isProvider) {
            this.getContractDeliverablesOutline()
            this.getLatestProviderAnnouncementDate()
        }

        if (this.isProvider) {
            this.isLoading = true;
            this.setProviderWidgets()
        }
    },
    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        },
        getAccuracyDueDate() {
            let accuracyStartDay = 5
            let accuracyDueDay = 15
            var today = new Date()
            var day = today.getDate()

            //exception window (after 7-20-2023 but before 8-15-2023)
            //if (Date.parse(utilService.formatDate(today)) >= Date.parse(utilService.formatDate(new Date(2023, 6, 21))) &&
            //    Date.parse(utilService.formatDate(today)) <= Date.parse(utilService.formatDate(new Date(2023, 7, 15)))) {
            //    console.log('hit')
            //    accuracyDueDay = 15
            //}

            if (day >= accuracyStartDay && day <= accuracyDueDay) {
                var duedate = new Date(today.getFullYear(), today.getMonth(), accuracyDueDay)
                this.widgets[this.accuracy_index].infotext = 'Due: ' + utilService.formatDateWithDashes(duedate)
                this.widgets[this.accuracy_index].date = utilService.addDays(duedate, -day)
            } 
            else {
                if (day > accuracyDueDay) {
                    var remainingDaysInMonth = utilService.getRemainingDaysInCurrentMonth() + accuracyStartDay + 1
                    this.widgets[this.accuracy_index].infotext = `Available to generate in ${remainingDaysInMonth} days`
                    this.widgets[this.accuracy_index].date = today
                } else {
                    var availableInDays = accuracyStartDay - day
                    this.widgets[this.accuracy_index].infotext = `Available to generate in ${availableInDays} days`
                    this.widgets[this.accuracy_index].date = today
                }
            }
        },
        loadLatestMIS() {
            return misService.getLatest(this.$http)
                .then(response => {
                    if (response.data) {
                        this.mis = response.data
                        if (this.mis) this.latestFormDates.push(this.mis.createDate)
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadLatestESR_MH() {
            return esrService.getLatestMH(this.$http)
                .then(response => {
                    if (response.data) {
                        this.esr_mh = response.data
                        if (this.esr_mh) this.latestFormDates.push(this.esr_mh.createDate)
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadLatestESR_SA() {
            return esrService.getLatestSA(this.$http)
                .then(response => {
                    if (response.data) {
                        this.esr_sa = response.data
                        if (this.esr_sa) this.latestFormDates.push(this.esr_sa.createDate)
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        getLatestFormDate() {
            //we should just use latest form for both upload and online entry
            this.loadLatestMIS().then(() => {
                this.loadLatestESR_MH().then(() => {
                    this.loadLatestESR_SA().then(() => {
                        this.latestFormDates.sort((a, b) => (a.createDate > b.createDate) ? 1 : -1)
                        this.latestFormDate = this.latestFormDates.pop()

                        this.widgets[this.upload_index].infotext = this.latestFormDate ? 'Last Form: ' + utilService.formatDateWithDashes(this.latestFormDate) : 'No Activity'
                        this.widgets[this.upload_index].date = this.latestFormDate

                        this.widgets[this.form_index].infotext = this.latestFormDate ? 'Last Form: ' + utilService.formatDateWithDashes(this.latestFormDate) : 'No Activity'
                        this.widgets[this.form_index].date = this.latestFormDate
                    })
                })
            })
        },
        loadLatestSentinelEvent() {
            return carfService.getLatestSentinelEventsTake(this.$http, 1)
                .then(response => {
                    if (response.data && response.data.length) {
                        this.sentinelEvent = response.data[0]
                        if (this.sentinelEvent) this.latestCARFDates.push(this.sentinelEvent.createdDate)
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadLatestLeadershipChange() {
            return carfService.getLatestLeadershipChangesTake(this.$http, 1)
                .then(response => {
                    if (response.data && response.data.length) {
                        this.leadershipChange = response.data[0]
                        if (this.leadershipChange) this.latestCARFDates.push(this.leadershipChange.createdDate)
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadUnacknowledgedSentinelEventList() {
            return carfService.getUnacknowledgedSentinelEventList(this.$http, 1)
                .then(response => {
                    if (response.data && response.data.length) {
                        this.unacknowledgedSentinelEventList = response.data
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadUnacknowledgedLeadershipChangeList() {
            return carfService.getUnacknowledgedLeadershipChangeList(this.$http, 1)
                .then(response => {
                    if (response.data && response.data.length) {
                        this.unacknowledgedLeadershipChangeList = response.data
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadLatestAdminCommunication() {
            return carfService.getAdministrativeCommunicationListLatestUpload(this.$http)
                .then(response => {
                    if (response.data) {
                        this.adminComm = response.data
                        if (this.adminComm) this.latestCARFDates.push(this.adminComm.submittedDate)
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadTrailingDayAdminCommunication() {
            return carfService.getAdministrativeCommunicationListTrailingDayActivityAdmin(this.$http, 7)
                .then(response => {
                    if (response.data) {
                        this.latestAdminCommUploadList = response.data
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadRequiresUserAttentionSentinelEventList() {
            return carfService.getRequiresUserAttentionSentinelEventList(this.$http)
                .then(response => {
                    if (response.data) {
                        this.requiresUserAttentionSentinelEventList = response.data
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        loadRequiresUserAttentionLeadershipChangeList() {
            return carfService.getRequiresUserAttentionLeadershipChangeList(this.$http)
                .then(response => {
                    if (response.data) {
                        this.requiresUserAttentionLeadershipChangeList = response.data
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        getCARFAgencyInfoText() {
            //var events = 0
            this.widgets[this.carf_index].infotext = ''
            this.loadRequiresUserAttentionSentinelEventList().then(seresult => {
                this.loadRequiresUserAttentionLeadershipChangeList().then(lcresult => {
                    //events += this.requiresUserAttentionLeadershipChangeList.length
                    if (this.requiresUserAttentionLeadershipChangeList.length) {
                        this.widgets[this.carf_index].infotext += `Leadership changes require attention: ${this.requiresUserAttentionLeadershipChangeList.length}<br />`
                    }

                })

                //events += this.requiresUserAttentionSentinelEventList.length
                if (this.requiresUserAttentionSentinelEventList.length) {
                    this.widgets[this.carf_index].infotext += `Sentinel events require attention: ${this.requiresUserAttentionSentinelEventList.length}<br />`
                }

                //if (events > 0) this.widgets[this.carf_index].chip = `${events} events need attn`
                //console.log(this.widgets[this.carf_index])
            })
        },
        //getLatestCARFDate() {
        //    this.loadLatestSentinelEvent().then(sentinelEventResult => {
        //        this.loadLatestLeadershipChange().then(leadershipChangeResult => {
        //            this.loadLatestAdminCommunication().then(adminCommunicationResult => {
        //                console.log(this.latestCARFDates)
        //                this.latestCARFDates.sort((a, b) => (a > b) ? 1 : -1)
        //                console.log(this.sentinelEvent)
        //                console.log(this.leadershipChange)
        //                console.log(this.latestCARFDate)
        //                this.latestCARFDate = this.latestCARFDates.pop()

        //                this.widgets[this.carf_index].infotext = this.latestCARFDate ? 'Latest Activity: ' + utilService.formatDateWithDashes(this.latestCARFDate) : 'No Activity'
        //                this.widgets[this.carf_index].date = this.latestCARFDate
        //            })
        //        })
        //    })
        //},
        getCARFAdminInfoText() {
            this.loadUnacknowledgedSentinelEventList().then(sentinelEventResult => {
                this.loadUnacknowledgedLeadershipChangeList().then(leadershipChangeResult => {
                    var latestDate = new Date()
                    var count = 0
                    if (this.unacknowledgedSentinelEventList.length) {
                        this.unacknowledgedSentinelEventList.sort((a, b) => (a.createdDate > b.createdDate) ? 1 : -1)
                        latestDate = this.unacknowledgedSentinelEventList[0].createdDate
                        count += this.unacknowledgedSentinelEventList.length
                    }

                    if (this.unacknowledgedLeadershipChangeList.length) {
                        this.unacknowledgedLeadershipChangeList.sort((a, b) => (a.createdDate > b.createdDate) ? 1 : -1)
                        if (latestDate) latestDate = latestDate > this.unacknowledgedLeadershipChangeList[0].createdDate ? latestDate : this.unacknowledgedLeadershipChangeList[0].createdDate
                        else latestDate = this.unacknowledgedLeadershipChangeList[0].createdDate
                        count += this.unacknowledgedLeadershipChangeList.length
                    }

                    this.widgets[this.carf_index].infotext = count > 0 ? 'Events to review: ' + count : `You're all caught up!`
                    this.widgets[this.carf_index].date = latestDate
                })
            })

            this.loadTrailingDayAdminCommunication().then(result => {
                if (this.latestAdminCommUploadList.length) {
                    this.widgets[this.carf_index].chip = this.latestAdminCommUploadList.length + ' recent uploads'
                    console.log(this.widgets[this.carf_index])
                }
            })
        },
        loadUpload() {
            return uploadService.getLatestUploadHeaderViewModel(this.$http)
                .then(response => {
                   if (response.data) {
                        this.upload_header = response.data
                        this.widgets[this.upload_index].infotext = 'Last Upload: ' + utilService.formatDateWithDashes(this.upload_header.processingEnd)
                        this.widgets[this.upload_index].date = this.upload_header.processingEnd
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        getQolDueDate() {
            var today = new Date()
            var day = today.getDate()

            if (day <= 16) {
                var duedate = new Date(today.getFullYear(), today.getMonth(), 15)
                this.widgets[this.qol_index].infotext = 'Due: ' + utilService.formatDateWithDashes(duedate)
                this.widgets[this.qol_index].date = utilService.addDays(duedate, -day)
            }
            else {
                var remainingDaysInMonth = utilService.getRemainingDaysInCurrentMonth() + 1
                this.widgets[this.qol_index].infotext = `Next QOL Available in ${remainingDaysInMonth} days`
                this.widgets[this.qol_index].date = today
            }
        },
        loadLatestRahs() {
            return rahsService.getLatestRequest(this.$http)
                .then(response => {
                    if (response.data) {
                        this.rahs_request = response.data
                        this.widgets[this.rahs_index].infotext = 'Last Entry: ' + utilService.formatDateWithDashes(this.rahs_request.modifiedDate)
                        this.widgets[this.rahs_index].date = this.rahs_request.modifiedDate
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        getColor(w) {
            var d = w.date ? w.date : null
            const today = new Date()
            var days = 0

            var formattedDate = utilService.formatDateToJavascriptDate(d)
            var mdy = formattedDate.split('-')
            var date = new Date(mdy[0], mdy[1] - 1, mdy[2])

            if (date) days = Math.ceil((Math.abs(today.getTime() - date.getTime())) / (1000 * 60 * 60 * 24))

            //if (w.text == 'Quality of Life') {
            //    console.log(date)
            //    console.log(days)
            //}

            if (days <= 5) return 'success--text'
            if (days <= 15) return 'warning--text'
            else return 'error--text'
        },
        getClickEvent(w) {
            if (w.title == 'contracts-deliverables-outline') this.downloadContractDeliverablesOutline()
        },
        getContractDeliverablesOutline() {
            return mediaService.getContractDeliverableOutline(this.$http).then(response => {
                this.contractDeliverableOutline = response.data
            })
        },
        getLatestProviderAnnouncementDate() {
            return providerAnnouncementService.getLatestAnnouncementDate(this.$http).then(announcementResponse => {
                this.isNewAnnouncementChipVisible = (new Date() - new Date(announcementResponse.data)) < (60 * 60 * 24 * 1000);
            });
        },
        downloadContractDeliverablesOutline() {
            this.getContractDeliverablesOutline().then(() => {
                return mediaService.downloadFile(this.$http_blob, this.contractDeliverableOutline.mediaId).then(response2 => {
                    const blob = new Blob([response2.data], { type: `application/${this.contractDeliverableOutline.mimeType}` })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = this.contractDeliverableOutline.description
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
            })
        },
        formRouterInput(selectedIcon) {
            if (selectedIcon.title === 'dla20-guidance') {
                return {path: selectedIcon.to, query: {documentsOption: 1}};
            } else {
                return {path: selectedIcon.to};
            }
        },
        openLink(url) {
            window.open(url, '_blank');
        },
        daysUntilExpiration(expirationDate) {
            const currentDate = new Date();
            const expiryDate = new Date(expirationDate);
            const timeDiff = expiryDate - currentDate;
            return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        },
        async getProviderApplicationProcessInstanceList() {
            try {
                const response = await providerApplicationService.getProviderApplicationProcessInstanceListByUser(this.$http);
                return response.data;
            } catch (error) {
                console.error("Error fetching provider application process instance list:", error);
                return [];
            }
        },
        async getProviderListByUser() {
            try {
                const response = await providerService.getProviderListByUser(this.$http);
                return response.data;
            } catch (error) {
                console.error("Error fetching provider list by user:", error);
                return [];
            }
        },
        async setProviderWidgets() {
            const [applicationList, providerList] = await Promise.all([
                this.getProviderApplicationProcessInstanceList(),
                this.getProviderListByUser()
            ]);

            if (providerList.length !== 0) {
                // Existing provider
                this.isNewProvider = false;
                const providerId = providerList[0].providerId;
                this.providerId = providerId;
                // Check if a renewal is in progress before setting the change widget
                const renewalInProgress = await this.setProviderRenewalWidget(providerId);
                if (!renewalInProgress) {
                    await this.setProviderChangeWidget(providerId);
                }
            } else {
                const applicationWithStatus = applicationList.find(app => app.processInstance.currentStatus.id === 1 || app.processInstance.currentStatus.id === 2);

                if (applicationWithStatus) {
                    // New provider in progress
                    this.providerId = applicationWithStatus.providerApplication.providerId;
                    this.isNewProvider = true;
                    const newApplicationWidget = this.widgets.find(w => w.title === "new-application");
                    newApplicationWidget.text = 'Continue Application';
                    newApplicationWidget.to = '/provider-application?processInstanceId=' + applicationWithStatus.processInstance.processInstanceId;
                } else {
                    // New provider
                    this.isNewProvider = true;
                }
            }
            this.isLoading = false;
        },
        setProviderRenewalWidget(id) {
            return new Promise((resolve, reject) => {
                providerRenewalService.getProviderRenewalProcessInstanceListByUser(this.$http)
                    .then(response => {
                        console.log('Renewal instance list', response.data)
                        let renewalInProgress = false;
                        let widgetRouterPath = '';
                        let widgetTitle = '';
                        let widgetInfoText = '';
                        let expirationDate;

                        if (response.data.length) {
                            response.data.forEach(renewalInstance => {
                                if (renewalInstance.processInstance.currentStatus.id === 5 || renewalInstance.processInstance.currentStatus.id === 6) {
                                    renewalInProgress = true;
                                    expirationDate = utilService.formatDate(renewalInstance.providerRenewal.provider.expirationDate);
                                    widgetTitle = 'Continue Renewal';
                                    widgetRouterPath = '/provider-renewal?processInstanceId=' + renewalInstance.processInstance.processInstanceId
                                    widgetInfoText = `You have less than ${this.daysUntilExpiration(expirationDate)} days! Due by: ${expirationDate}`
                                } else {
                                    renewalInProgress = false;
                                    expirationDate = utilService.formatDate(renewalInstance.providerRenewal.provider.expirationDate);
                                    widgetInfoText = `You can renew in ${this.daysUntilExpiration(expirationDate) - 120} days. Expiration Date: ${expirationDate}`
                                }
                            })
                            const renewalWidget = this.widgets.find(w => w.title === "provider-renewal")
                            renewalWidget.to = widgetRouterPath;
                            renewalWidget.text = widgetTitle || renewalWidget.text;
                            renewalWidget.isrouted = renewalInProgress;
                            renewalWidget.infotext = widgetInfoText;
                        } else {
                            renewalInProgress = false;
                            providerService.getBasicProviderInformation(this.$http, id)
                                .then(response => {
                                    let expirationDate = utilService.formatDate(response.data?.expirationDate);
                                    widgetInfoText = `You can renew in ${this.daysUntilExpiration(expirationDate) - 120} days. Expiration Date: ${expirationDate}`
                                    const renewalWidget = this.widgets.find(w => w.title === "provider-renewal")
                                    renewalWidget.isrouted = renewalInProgress;
                                    renewalWidget.infotext = widgetInfoText;
                                })
                                .catch(error => {
                                    console.error("Error fetching provider expiration date:", error);
                                });
                        }
                        resolve(renewalInProgress);
                    })
                    .catch(error => {
                        console.error("Error fetching provider renewal process instance list:", error);
                        reject(error);
                    });
            });
        },
        setProviderChangeWidget(id) {
            providerUpdateService.getProviderUpdateProcessInstanceListByUser(this.$http)
                .then(response => {
                    let updateInProgress = false;
                    let widgetRouterPath = '';
                    let widgetTitle = '';

                    if (response.data.length) {
                        response.data.forEach(updateInstance => {
                            if (updateInstance.processInstance.currentStatus.id === 9 || updateInstance.processInstance.currentStatus.id === 10) {
                                updateInProgress = true;
                                widgetTitle = 'Continue Change Request';
                                widgetRouterPath = '/provider-update?processInstanceId=' + updateInstance.processInstance.processInstanceId
                            }
                        })
                    } else {
                        updateInProgress = false;
                    }

                    const updateWidget = this.widgets.find(w => w.title === "provider-change")
                    updateWidget.to = widgetRouterPath;
                    updateWidget.text = widgetTitle || updateWidget.text;
                    updateWidget.isrouted = updateInProgress;
                    updateWidget.providerId = id;
                    updateWidget.infotext = '';
                })
                .catch(error => {
                    console.error("Error fetching provider update process instance list:", error);
                });
        },
        initializeUpdate(id) {
            this.isLoading = true;
            return providerUpdateService.initialize(this.$http, id)
                .then(response => {
                if (response.data.validationResult.length) this.validations = response.data.validationResult
                    else this.$router.push(`/provider-update?processInstanceId=${response.data.processInstanceId}`)
                    this.isLoading = false;
                })
                .catch(error => {
                    console.error("Error initializing provider update process.", error);
                    this.isLoading = false;
                })
        }

    }
}

</script>

<style scoped>
    a {
        text-decoration: none;
    }

    .mdi:before, .mdi-set {
        -webkit-text-stroke: 2px var(--v-contentBackground);
    }

    .theme--light.v-subheader { /* or dark depending on your theme*/
        color: blue;
    }

    .div > v-subheader {
        color: red !important;
    }

    .clickable {
        cursor: pointer;
    }
</style>

import { luYesNoUnknown } from "@/data/yesNoUnknown.json";
import { luBirthCity } from "@/data/cities.json";
import { luBirthState } from "@/data/states.json";
import { luBirthCountry } from "@/data/countries.json";
import { luCounty } from "@/data/counties.json";
import { iCD10 } from "@/data/icd10.json";

import utilService from '@/services/util/utilService'

var path = 'api/enum/'

function getLuTSHStatusById(id) {
    const statuses = [
        {luTSHStatusID: 1, description: "On Waitlist"},
        {luTSHStatusID: 2, description: "Removed From Waitlist - Automatic"},
        {luTSHStatusID: 3, description: "Removed From Waitlist - Manual"},
        {luTSHStatusID: 4, description: "In Treatment"},
        {luTSHStatusID: 5, description: "Removed From Treatment"},
        {luTSHStatusID: 6, description: "Completed Treatment"},
    ];
    return statuses.find(x => x.luTSHStatusID === id);
}

function getRoles() {
    return [
        { roleID: 1, description: 'State Admin' },
        { roleID: 2, description: 'Agency Group User' },
        { roleID: 3, description: 'State User' },
        { roleID: 16, description: 'RaHS User' },
        { roleID: 17, description: 'Provider' },
        { roleID: 18, description: 'SOR User' },
    ]
}

function getICD10List(activeOnly, date) {
    if (activeOnly) { return iCD10.filter(x => x.isActive) }
    if (date) { return iCD10.filter(x => x.startDate <= date) }
    else { return iCD10 }
}

function getYesNoUnknownList() {
    return [
        { luYesNoUnknownID: 1, description: 'Yes' },
        { luYesNoUnknownID: 2, description: 'No' },
        { luYesNoUnknownID: 9, description: 'Unknown' }
    ]
}

function getYesNoList() {
    return [
        { luYesNoUnknownID: 1, description: 'Yes' },
        { luYesNoUnknownID: 2, description: 'No' }
    ]
}

function getYesNoUnknownDescription(id) {
    var x = luYesNoUnknown.find(x => x.luYesNoUnknownID === id)

    if (x) return x.description
    else return ""
}

function getCities() {
    return utilService.sortJSON(luBirthCity, 'sortOrder')
    //return utilService.sortJSON(luBirthCity, 'description', -1); //descending sort
}
function getCityDescription(id) {
    var city = luBirthCity.find(x => x.luBirthCityID === id)

    if (city) return city.description
    else return ""
}

function getStates() {
    return utilService.sortJSON(luBirthState, 'sortOrder')
}
function getStateDescription(id) {
    var state = luBirthState.find(x => x.luBirthStateID === id)

    if (state) return state.description
    else return ""
}

function getCountries() {
    //var x = utilService.sortJSON(luBirthCountry, 'sortOrder')
    //const i = x.findIndex(x => x.description === 'UNITED STATES')
    //var us = x.splice(i, 1)
    //x.unshift(us[0])
    //return x

    return utilService.sortJSON(luBirthCountry, 'sortOrder')
}
function getCountryDescription(id) {
    var x = luBirthCountry.find(x => x.luBirthCountryID === id)

    if (x) return x.description
    else return ""
}

function getCounties() {
    return utilService.sortJSON(luCounty, 'sortOrder')
}
function getCountyDescription(id) {
    var x = luCounty.find(x => x.luCountyID === id)

    if (x) return x.description
    else return ""
}

function getGenderList() {
    return [
        { luGenderID: 1, description: 'Male' },
        { luGenderID: 2, description: 'Female' },
        { luGenderID: 3, description: 'Unknown' }
    ]
}
function getGenderByChar(c) {
    const genders = getGenderList()
    switch (c) {
        case 'm':
        case 'M':
            return genders[0]

        case 'f':
        case 'F':
            return genders[1]

        case 'u':
        case 'U':
            return genders[2]
    }
}

function getGenderIdentityList() {
    return [
        { id: 1, description: 'Cisgender Male' },
        { id: 2, description: 'Cisgender Female' },
        { id: 3, description: 'Transgender Man/Trans Man/Female-To-Male' },
        { id: 4, description: 'Transgender Woman/Trans Woman/Male-To-Female' },
        { id: 5, description: 'Genderqueer/Gender Non-Conforming/Neither Exclusively Male Nor Female' },
        { id: 6, description: 'Additonal Gender Category (or Other)' },
        { id: 9, description: 'Unknown/Preferred Not to Respond' },
    ]
}

function getFiscalYears(take) {
    var x = [
        { fiscalYearId: 1, description: 'FY2007', startDate: new Date(2006, 6, 1), endDate: new Date(2007, 5, 30) },
        { fiscalYearId: 2, description: 'FY2008', startDate: new Date(2007, 6, 1), endDate: new Date(2008, 5, 30) },
        { fiscalYearId: 3, description: 'FY2009', startDate: new Date(2008, 6, 1), endDate: new Date(2009, 5, 30) },
        { fiscalYearId: 4, description: 'FY2010', startDate: new Date(2009, 6, 1), endDate: new Date(2010, 5, 30) },
        { fiscalYearId: 5, description: 'FY2011', startDate: new Date(2010, 6, 1), endDate: new Date(2011, 5, 30) },
        { fiscalYearId: 6, description: 'FY2012', startDate: new Date(2011, 6, 1), endDate: new Date(2012, 5, 30) },
        { fiscalYearId: 7, description: 'FY2013', startDate: new Date(2012, 6, 1), endDate: new Date(2013, 5, 30) },
        { fiscalYearId: 8, description: 'FY2014', startDate: new Date(2013, 6, 1), endDate: new Date(2014, 5, 30) },
        { fiscalYearId: 9, description: 'FY2015', startDate: new Date(2014, 6, 1), endDate: new Date(2015, 5, 30) },
        { fiscalYearId: 10, description: 'FY2016', startDate: new Date(2015, 6, 1), endDate: new Date(2016, 5, 30) },
        { fiscalYearId: 11, description: 'FY2017', startDate: new Date(2016, 6, 1), endDate: new Date(2017, 5, 30) },
        { fiscalYearId: 12, description: 'FY2018', startDate: new Date(2017, 6, 1), endDate: new Date(2018, 5, 30) },
        { fiscalYearId: 13, description: 'FY2019', startDate: new Date(2018, 6, 1), endDate: new Date(2019, 5, 30) },
        { fiscalYearId: 14, description: 'FY2020', startDate: new Date(2019, 6, 1), endDate: new Date(2020, 5, 30) },
        { fiscalYearId: 15, description: 'FY2021', startDate: new Date(2020, 6, 1), endDate: new Date(2021, 5, 30) },
        { fiscalYearId: 16, description: 'FY2022', startDate: new Date(2021, 6, 1), endDate: new Date(2022, 5, 30) },
        { fiscalYearId: 17, description: 'FY2023', startDate: new Date(2022, 6, 1), endDate: new Date(2023, 5, 30) },
        { fiscalYearId: 18, description: 'FY2024', startDate: new Date(2023, 6, 1), endDate: new Date(2024, 5, 30) },
        { fiscalYearId: 19, description: 'FY2025', startDate: new Date(2024, 6, 1), endDate: new Date(2025, 5, 30) },
    ]

    if (take) {
        x = x.reverse();
        return x.slice(0, take);
    } else {
        x = x.reverse();
        return x;
    }
}

function getFiscalYearByDate(date) {
    return this.getFiscalYears().find(x => utilService.dateBetween(x.startDate, x.endDate, date))
}

function getFiscalYearsBetween(startDate, endDate) {
    return getFiscalYears().filter(fiscalYear =>
        (fiscalYear.startDate >= startDate && fiscalYear.startDate <= endDate) ||
        (fiscalYear.endDate >= startDate && fiscalYear.endDate <= endDate) ||
        (fiscalYear.startDate <= startDate && fiscalYear.endDate >= endDate)
    );
}

function getCalendarYearList() {
    var x = [
        { id: 2022, description: '2022', startDate: new Date(2022, 0, 1), endDate: new Date(2022, 11, 31) },
        { id: 2023, description: '2023', startDate: new Date(2023, 0, 1), endDate: new Date(2023, 11, 31) },
        { id: 2024, description: '2024', startDate: new Date(2024, 0, 1), endDate: new Date(2024, 11, 31) },
        { id: 2025, description: '2025', startDate: new Date(2025, 0, 1), endDate: new Date(2025, 11, 31) },
    ]

    x = x.filter(y => Date.parse(utilService.formatDate(y.startDate)) <= Date.parse(utilService.formatDate(new Date())))
    x = x.reverse()
    return x
}

function getMonthList(sortByFiscalMonth) {
    var x = [
        { monthId: 1, description: 'January', short: 'Jan', fiscalMonthId: 7 },
        { monthId: 2, description: 'February', short: 'Feb', fiscalMonthId: 8 },
        { monthId: 3, description: 'March', short: 'Mar', fiscalMonthId: 9 },
        { monthId: 4, description: 'April', short: 'Apr', fiscalMonthId: 10 },
        { monthId: 5, description: 'May', short: 'May', fiscalMonthId: 11 },
        { monthId: 6, description: 'June', short: 'Jun', fiscalMonthId: 12 },
        { monthId: 7, description: 'July', short: 'Jul', fiscalMonthId: 1 },
        { monthId: 8, description: 'August', short: 'Aug', fiscalMonthId: 2 },
        { monthId: 9, description: 'September', short: 'Sep', fiscalMonthId: 3 },
        { monthId: 10, description: 'October', short: 'Oct', fiscalMonthId: 4 },
        { monthId: 11, description: 'November', short: 'Nov', fiscalMonthId: 5 },
        { monthId: 12, description: 'December', short: 'Dec', fiscalMonthId: 6 }
    ]

    return sortByFiscalMonth ? utilService.sortJSON(x, 'fiscalMonthId') : x
}

function getLuFiscalMonth(http, id) {
    return http.get(`${path}lufiscalmonth/${id}`)
}

function getLuFiscalMonthByDate(http, date) {
    return http.get(`${path}lufiscalmonth/get-by-date/${date}`)
}

function getLuFiscalMonthsTake(http, startid, take) {
    return http.get(`${path}lufiscalmonth/get-lufiscalmonths-take/${startid}/${take}`)
}

function getLuFiscalMonthsByFiscalYear(http, fiscalYearId) {
    return http.get(`${path}lufiscalmonth/getByFiscalYear/${fiscalYearId}`)
}

function getServiceMatrix(http, luFiscalYearId, luAgencyTypeId, luLevelOfCareId) {
    return http.get(`${path}services/get-service-matrix/${luFiscalYearId}/${luAgencyTypeId}/${luLevelOfCareId}`)
}

function getActualPaymentSourceList() {
    return [
        { luPrincipalPaymentSourceID: 0, description: 'Not Applicable' },
        { luPrincipalPaymentSourceID: 6, description: 'Medicaid' },
        { luPrincipalPaymentSourceID: 11, description: 'Other (VA, DFS, DVR, etc.)' },
        { luPrincipalPaymentSourceID: 12, description: 'Pending Medicaid' },
        { luPrincipalPaymentSourceID: 13, description: 'Medicare' },
        { luPrincipalPaymentSourceID: 14, description: 'Health Insurance' },
        { luPrincipalPaymentSourceID: 15, description: 'Full Fee' },
        { luPrincipalPaymentSourceID: 17, description: 'State Contract' }
    ]
}

function getCarfFormTypeList(uploadOnly) {
    var x = [
        { luCARFFormTypeId: 1, isWCISForm: true, description: 'Sentinel Event (includes Sentinel and Death)' },
        { luCARFFormTypeId: 2, isWCISForm: true, description: 'Change in Leadership' },
        { luCARFFormTypeId: 3, isWCISForm: false, description: 'Change in Ownership' },
        { luCARFFormTypeId: 4, isWCISForm: false, description: 'Change in Organization Name' },
        { luCARFFormTypeId: 5, isWCISForm: false, description: 'Change in Mail and/or Email Address' },
        { luCARFFormTypeId: 6, isWCISForm: false, description: 'Relocation, Expansion, or Elimination of an Accredited Program or Location' },
        { luCARFFormTypeId: 7, isWCISForm: false, description: 'Financial Distress' },
        { luCARFFormTypeId: 8, isWCISForm: false, description: 'Investigation' },
        { luCARFFormTypeId: 9, isWCISForm: false, description: 'Material Litigation' },
        { luCARFFormTypeId: 10, isWCISForm: false, description: 'Catastrophe' },
        { luCARFFormTypeId: 11, isWCISForm: false, description: 'Bans on Admission, Fines, Penalties, or Other Governmental Sanctions' },
        { luCARFFormTypeId: 13, isWCISForm: false, description: 'Survey Report' },
        { luCARFFormTypeId: 14, isWCISForm: false, description: 'QIP' },
        { luCARFFormTypeId: 15, isWCISForm: false, description: 'Accreditation Letter' },
    ]

    if (uploadOnly) return x.filter(y => !y.isWCISForm)
    else return x
}

function getContractFormList() {
    return [
        { luContractFormID: 1, luAgencyTypeId: 2, description: 'Monthly Quality of Life Form' },
        { luContractFormID: 2, luAgencyTypeId: 1, description: 'Monthly Quality of Life Form' },
        { luContractFormID: 3, luAgencyTypeId: 2, description: 'Monthly Shadow Billing Form - Outpatient Contract' },
        { luContractFormID: 4, luAgencyTypeId: 1, description: 'Monthly Shadow Billing Form - Outpatient Contract' },
        { luContractFormID: 5, luAgencyTypeId: 1, description: 'Monthly Shadow Billing Form - Residential Contract' }
    ]
}

function getContractPeriodList() {
    return [
        { contractPeriodId: 1, luFiscalYearIdStart: 13, luFiscalYearIdEnd: 14, description: 'FY19/20' },
        { contractPeriodId: 2, luFiscalYearIdStart: 15, luFiscalYearIdEnd: 16, description: 'FY21/22' },
        { contractPeriodId: 3, luFiscalYearIdStart: 17, luFiscalYearIdEnd: 17, description: 'FY23' },
        { contractPeriodId: 4, luFiscalYearIdStart: 18, luFiscalYearIdEnd: 18, description: 'FY24' },
        { contractPeriodId: 5, luFiscalYearIdStart: 19, luFiscalYearIdEnd: 19, description: 'FY25' },
    ].reverse()
}

function getAgencyTypeList() {
    return [
        { agencyTypeId: 0, description: 'Not Defined', longDescription: 'Not Defined' },
        { agencyTypeId: 1, description: 'SUD', longDescription: 'Substance Use Disorder' },
        { agencyTypeId: 2, description: 'MH', longDescription: 'Mental Health' },
        { agencyTypeId: 3, description: 'WSH', longDescription: 'Wyoming State Hospital' }
    ]
}

function getBedCategoryList() {
    return [
        { luBedCategoryID: 1, description: 'MH Crisis Stabilization' },
        { luBedCategoryID: 2, description: 'MH Supervised Living' },
        { luBedCategoryID: 3, description: 'MH Group Home' },
        { luBedCategoryID: 4, description: 'SUD Primary RES' },
        { luBedCategoryID: 5, description: 'SUD Social Detox' },
        { luBedCategoryID: 6, description: 'SUD Transitional' }
    ]
}

function getBedTypeList(agencytypeid) {
    var x = [
        { id: 1, isMH: false, isSUD: true, description: 'SUD Residential' },
        { id: 2, isMH: false, isSUD: true, description: 'SUD Transitional' },
        { id: 3, isMH: true, isSUD: false, description: 'MH Residential' },
        { id: 4, isMH: false, isSUD: true, description: 'Social Detox' },
    ]
    if (agencytypeid && agencytypeid == 1) return x.filter(y => y.isSUD)
    else if (agencytypeid && agencytypeid == 2) return x.filter(y => y.isMH)
    else if (agencytypeid && agencytypeid == 3) return x.filter(y => y.isWSH)
    else return x
}

function getCigaretteUseList() {
    return [
        { luCigaretteUseID: 0, description: 'Never Used' },
        { luCigaretteUseID: 1, description: 'Yes (traditional cigarettes/cigars/pipes)' },
        { luCigaretteUseID: 2, description: 'No' },
        { luCigaretteUseID: 3, description: 'E-Cigarettes' },
        { luCigaretteUseID: 4, description: 'Snuff/Chew' },
        { luCigaretteUseID: 5, description: 'More than one form of tobacco' },
        { luCigaretteUseID: 8, description: 'No Use Due to Recent Stay in Controlled Environment' }
    ]
}

function getDeathReportCauseOfDeathList() {
    return [
        { luDeathReportCauseOfDeathId: 1, description: 'Natural Causes' },
        { luDeathReportCauseOfDeathId: 2, description: 'Homicide' },
        { luDeathReportCauseOfDeathId: 3, description: 'Accidental Death' },
        { luDeathReportCauseOfDeathId: 4, description: 'Suicide' },
        { luDeathReportCauseOfDeathId: 5, description: 'Undetermined' },
        { luDeathReportCauseOfDeathId: 6, description: 'Pending' },
        { luDeathReportCauseOfDeathId: 7, description: 'Unknown' }
    ]
}

function getDeathReportClientStatusList() {
    return [
        { luDeathReportClientStatusId: 1, description: 'Former' },
        { luDeathReportClientStatusId: 2, description: 'Current' },
        { luDeathReportClientStatusId: 3, description: 'Pending - new or returning client' }
    ]
}

function getDischargeStatusList() {
    return [
        { luDischargeStatusID: 1, description: 'Treatment Completed' },
        { luDischargeStatusID: 2, description: 'AMA(Against Medical Advice.Client self - terminates treatment by not following recommendations)' },
        { luDischargeStatusID: 3, description: 'Terminated by Facility (Facility terminates treatment - not client drop-out, incarceration, or other client reason)' },
        { luDischargeStatusID: 4, description: 'Transferred to Another Facility' },
        { luDischargeStatusID: 5, description: 'Evaluation Only' },
        { luDischargeStatusID: 6, description: 'Death' },
        { luDischargeStatusID: 7, description: 'Other  (Illness, hospitalization, or other reason somewhat out of client\'s control)' },
        { luDischargeStatusID: 8, description: 'Incarcerated (Jail, prison, or house confinement)' },
        { luDischargeStatusID: 9, description: 'Unknown (Includes Data Not Available)' },
        { luDischargeStatusID: 10, description: 'No Show (Client makes appointments but never returns for services)' },
        { luDischargeStatusID: 12, description: 'Moved' },
        { luDischargeStatusID: 13, description: 'Recommended for Another Level of Care' },
        { luDischargeStatusID: 14, description: 'Convalescent Leave' },
    ]
}

function getDlaResponseList() {
    return [
        { luDlaResponseID: 1, description: '1 - Extremely severe functional impairment, needs pervasive level of continuous paid supports' },
        { luDlaResponseID: 2, description: '2 - Very severe functional impairment, needs extensive level of continuous paid supports' },
        { luDlaResponseID: 3, description: '3 - Severe functional impairment, needs moderate level of continuous paid supports' },
        { luDlaResponseID: 4, description: '4 - Moderate functional impairment, needs low level of continuous paid supports' },
        { luDlaResponseID: 5, description: '5 - WNL/Strength, Mild functional impairment, needs moderate level of intermittent paid supports' },
        { luDlaResponseID: 6, description: '6 - WNL/Strength, Intermittent mild functional impairment, needs low level of paid supports' },
        { luDlaResponseID: 7, description: '7 - WNL/Strength, Independent, Optimal functioning, no need for paid supports' }
    ]
}

function getDocumentGroupList() {
    return [
        { luDocumentGroupID: 4, description: 'Help' },
        { luDocumentGroupID: 9, description: 'Bulletin' }
    ]
}

function getEducationList() {
    return [
        { luEducationID: 0, description: 'No Schooling' },
        { luEducationID: 1, description: 'Kindergarten or 1st Grade' },
        { luEducationID: 2, description: '2nd Grade' },
        { luEducationID: 3, description: '3rd Grade' },
        { luEducationID: 4, description: '4th Grade' },
        { luEducationID: 5, description: '5th Grade' },
        { luEducationID: 6, description: '6th Grade' },
        { luEducationID: 7, description: '7th Grade' },
        { luEducationID: 8, description: '8th Grade' },
        { luEducationID: 9, description: '9th Grade' },
        { luEducationID: 10, description: '10th Grade' },
        { luEducationID: 11, description: '11th Grade' },
        { luEducationID: 12, description: 'High School Degree/GED' },
        { luEducationID: 13, description: '1 Year of College' },
        { luEducationID: 14, description: '2 Years of College/Associates Degree' },
        { luEducationID: 15, description: '3 Years of College' },
        { luEducationID: 16, description: 'Bachelors Degree' },
        //{ luEducationID: 17, description: '5 years of College' },
        { luEducationID: 18, description: 'Masters Degree' },
        //{ luEducationID: 19, description: 'Masters+1' },
        { luEducationID: 20, description: 'Doctoral Degree' },
        //{ luEducationID: 21, description: 'Doctoral + 1' },
        //{ luEducationID: 22, description: 'Doctoral + 2' },
        //{ luEducationID: 23, description: 'Doctoral + 3' },
        //{ luEducationID: 24, description: 'Doctoral + 4' },
        { luEducationID: 99, description: 'Unknown' }
    ]
}

function getEmailTypeList() {
    return [
        { id: 1, luEmailTypeId: 1, description: 'Administrative Communication' },
    ]
}

function getEmploymentList(luFiscalYearId) {
    var x = [
        { luEmploymentID: 1, description: 'Unemployed:  Employable; no work available' },
        { luEmploymentID: 2, description: 'Part Time:  less than 30 hours per week' },
        { luEmploymentID: 3, description: 'Full Time:  greater than or equal to 30 hours per week' },
        { luEmploymentID: 4, description: 'Homemaker:  Adult not in workforce' },
        { luEmploymentID: 5, description: 'Retired:  Retired from active employment' },
        { luEmploymentID: 6, description: 'Disabled Unemployed:  Unable to work for disability reasons' },
        { luEmploymentID: 7, description: 'Child:  Under 16 years of age, not in the labor force' },
        { luEmploymentID: 8, description: 'Student:  16 years of age or older, not in the labor force' },
        { luEmploymentID: 9, description: 'Inmate of an Institution' },
        { luEmploymentID: 10, description: 'Other' },
        { luEmploymentID: 11, description: 'Unknown' },
        { luEmploymentID: 12, description: 'Volunteer Position' },
    ]

    if (!luFiscalYearId) return x.filter(y => y.luEmploymentID != 10 && y.luEmploymentID != 11)

    if (luFiscalYearId <= 15) return x
    else return x.filter(y => y.luEmploymentID != 10 && y.luEmploymentID != 11)
}

function getEmploymentListWSH() {
    var x = [
        { luEmploymentID: 1, description: 'Unemployed:  Employable; no work available' },
        { luEmploymentID: 2, description: 'Part Time:  less than 30 hours per week' },
        { luEmploymentID: 3, description: 'Full Time:  greater than or equal to 30 hours per week' },
        { luEmploymentID: 4, description: 'Homemaker:  Adult not in workforce' },
        { luEmploymentID: 5, description: 'Retired:  Retired from active employment' },
        { luEmploymentID: 6, description: 'Disabled Unemployed:  Unable to work for disability reasons' },
        { luEmploymentID: 7, description: 'Child:  Under 16 years of age, not in the labor force' },
        { luEmploymentID: 8, description: 'Student:  16 years of age or older, not in the labor force' },
        { luEmploymentID: 9, description: 'Inmate of an Institution' },
        { luEmploymentID: 10, description: 'Other' },
        { luEmploymentID: 11, description: 'Unknown' },
        { luEmploymentID: 12, description: 'Volunteer Position' },
    ]

    return x.filter(y => y.luEmploymentID != 10)
}

function getEpisodeStatusTypeList() {
    return [
        { luEpisodeStatusTypeID: 1, description: 'Open' },
        { luEpisodeStatusTypeID: 2, description: 'Closed' },
        { luEpisodeStatusTypeID: 3, description: 'Closed - System Discharge' },
        { luEpisodeStatusTypeID: 4, description: 'Closed - Final System Discharge' },
    ]
}

function getEpisodeTypeList() {
    return [
        { luEpisodeTypeID: 2, description: 'Empty' },
        { luEpisodeTypeID: 3, description: 'Event' },
        { luEpisodeTypeID: 4, description: 'Non - Event' },
    ]
}

function getFundingSourceList(luFiscalYearId) {
    var x = [
        { luFundingSourceID: 1, description: 'Medicaid' },
        { luFundingSourceID: 2, description: 'Insurance' },
        { luFundingSourceID: 3, description: 'State' },
        { luFundingSourceID: 4, description: 'Other (VA, DFS, DVR, etc.)' },
        { luFundingSourceID: 5, description: 'Self Pay (Client responsible for full service amount)' }
    ]

    if (!luFiscalYearId) return x
    if (luFiscalYearId >= 19) return x.filter(y => y.luFundingSourceID != 2 && y.luFundingSourceID != 5)
    else return x
}

function getFacilityReferralList() {
    return [
        { luFacilityReferralID: 1, description: 'Homeless Shelter' },
        { luFacilityReferralID: 2, description: 'Private MH Treatment Provider' },
        { luFacilityReferralID: 3, description: 'CMHC Group Home' },
        { luFacilityReferralID: 4, description: 'CMHC Outpatient Treatment' },
        { luFacilityReferralID: 5, description: 'CMHC Crisis Stabilization Program' },
        { luFacilityReferralID: 6, description: 'CMHC SA Residential Treatment Facility' },
        { luFacilityReferralID: 7, description: 'Residential Treatment Facility' },
        { luFacilityReferralID: 8, description: 'Nursing Home' },
        { luFacilityReferralID: 9, description: 'Medical Hospital' },
        { luFacilityReferralID: 10, description: 'Developmental Disability Program' },
        { luFacilityReferralID: 11, description: 'VA' },
        { luFacilityReferralID: 12, description: 'Jail' },
        { luFacilityReferralID: 13, description: 'Out of State' },
        { luFacilityReferralID: 14, description: 'Other' },
        { luFacilityReferralID: 15, description: 'None' },
        { luFacilityReferralID: 16, description: 'CMHC Supervised Living' },
        { luFacilityReferralID: 17, description: 'Refused' },
        { luFacilityReferralID: 18, description: 'Deceased' },
        { luFacilityReferralID: 19, description: 'Elopement' },
    ]
}

function getHispanicOriginList() {
    return [
        { luHispanicOriginID: 2, description: 'Not of Hispanic Origin' },
        { luHispanicOriginID: 3, description: 'Cuban' },
        { luHispanicOriginID: 4, description: 'Puerto Rican' },
        { luHispanicOriginID: 5, description: 'Mexican' },
        { luHispanicOriginID: 6, description: 'Other Hispanic' },
        { luHispanicOriginID: 7, description: 'Unknown' }
    ]
}

function getLevelOfCareList(agencytypeid) {
    var x = [
        { luLevelOfCareID: 1, isMH: true, isSA: true, isWSH: false, isresidential: false, issor: false, description: 'Outpatient' },
        { luLevelOfCareID: 2, isMH: false, isSA: true, isWSH: false, isresidential: true, issor: false, description: 'SUD Primary Residential' },
        { luLevelOfCareID: 3, isMH: false, isSA: true, isWSH: false, isresidential: true, issor: false, description: 'Social Detox' },
        { luLevelOfCareID: 5, isMH: false, isSA: false, isWSH: true, isresidential: false, issor: false, description: 'WSH' },
        { luLevelOfCareID: 6, isMH: true, isSA: false, isWSH: false, isresidential: true, issor: false, description: 'Subacute Residential' },
        { luLevelOfCareID: 8, isMH: false, isSA: true, isWSH: false, isresidential: true, issor: false, description: 'SUD Regional Transitional' },
        { luLevelOfCareID: 10, isMH: true, isSA: false, isWSH: false, isresidential: true, issor: false, description: 'Supervised Living' },
        { luLevelOfCareID: 11, isMH: true, isSA: false, isWSH: false, isresidential: true, issor: false, description: 'MH Regional Group Homes' },
        { luLevelOfCareID: 17, isMH: false, isSA: true, isWSH: false, isresidential: true, issor: false, description: 'SUD Adolescent Residential' },
        { luLevelOfCareID: 18, isMH: true, isSA: false, isWSH: false, isresidential: false, issor: false, description: 'MH Standalone' },
        //{ luLevelOfCareID: 19, isMH: false, isSA: true, isWSH: false, isresidential: false, issor: false, description: 'SUD Standalone' },
        //{ luLevelOfCareID: 21, isMH: true, isSA: true, isWSH: false, isresidential: false, issor: false, description: 'Exceptional Discharge' },
        { luLevelOfCareID: 22, isMH: false, isSA: true, isWSH: false, isresidential: false, issor: true, description: 'SOR' },
        { luLevelOfCareID: 23, isMH: false, isSA: true, isWSH: false, isresidential: true, issor: true, description: 'SOR Res' },
        { luLevelOfCareID: 24, isMH: false, isSA: true, isWSH: false, isresidential: true, issor: false, description: 'SUD Primary Residential Programs Funded by Grants' },
        { luLevelOfCareID: 25, isMH: true, isSA: true, isWSH: false, isresidential: false, issor: false, description: 'Outpatient Funded by Grants'  }
    
        ]
    if (agencytypeid && agencytypeid == 1) return x.filter(y => y.isSA)
    else if (agencytypeid && agencytypeid == 2) return x.filter(y => y.isMH)
    else if (agencytypeid && agencytypeid == 3) return x.filter(y => y.isWSH)
    else return x
}

function getLevelOfCareListResidential() {
    return this.getLevelOfCareList().filter(x => x.isresidential)
}

function getLocationOfServiceList() {
    return [
        { luLocationOfServiceID: 1, description: 'Office' },
        { luLocationOfServiceID: 2, description: 'Community' },
        { luLocationOfServiceID: 3, description: 'Correctional Facility (Jail)' },
        { luLocationOfServiceID: 4, description: 'Inpatient' },
        { luLocationOfServiceID: 5, description: 'Homeless/Emergency Shelter' },
        { luLocationOfServiceID: 6, description: 'Faith-based (Church)' },
        { luLocationOfServiceID: 7, description: 'Primary Care/Health Care' },
        { luLocationOfServiceID: 8, description: 'Home' },
        { luLocationOfServiceID: 9, description: 'Client Job Site' },
        { luLocationOfServiceID: 10, description: 'Residential Care (Not SA or MH Specialty)' },
        { luLocationOfServiceID: 11, description: 'School' },
        { luLocationOfServiceID: 12, description: 'Telehealth/Telepsychiatry' },
        { luLocationOfServiceID: 13, description: 'Early Childcare Setting' },
        { luLocationOfServiceID: 14, description: 'Hospital' },
        { luLocationOfServiceID: 15, description: 'Nursing Home/Assisted Skilled Nursing Facility' },
        { luLocationOfServiceID: 16, description: 'MH Supervised Apartment' },
        { luLocationOfServiceID: 17, description: 'MH Group Home' },
        { luLocationOfServiceID: 18, description: 'RTC for Co-Occuring' },
        { luLocationOfServiceID: 19, description: 'SA Residential' },
        { luLocationOfServiceID: 20, description: 'SA Transitional Residential' },
        { luLocationOfServiceID: 21, description: 'Social Detox Facility' },
        { luLocationOfServiceID: 22, description: 'Phone' },
        { luLocationOfServiceID: 23, description: 'Other/Unknown' },
        { luLocationOfServiceID: 24, description: 'Senior Citizen Center' },
        { luLocationOfServiceID: 25, description: 'Mobile Crisis Team' }
    ]
}

function getLocusHarmList() {
    return [
        { luLocusHarmID: 1, description: 'Minimal Risk of Harm' },
        { luLocusHarmID: 2, description: 'Low Risk of Harm' },
        { luLocusHarmID: 3, description: 'Moderate Risk of Harm' },
        { luLocusHarmID: 4, description: 'Serious Risk of Harm' },
        { luLocusHarmID: 5, description: 'Extreme Risk of Harm' }
    ]
}

function getLocusEngagementList() {
    return [
        { luLocusEngagementID: 1, description: 'Optimal Engagement and Recovery' },
        { luLocusEngagementID: 2, description: 'Positive Engagement and Recovery' },
        { luLocusEngagementID: 3, description: 'Limited Engagement and Recovery' },
        { luLocusEngagementID: 4, description: 'Minimal Engagement and Recovery' },
        { luLocusEngagementID: 4, description: 'Unengaged and Stuck' }
    ]
}

function getLocusFunctionalList() {
    return [
        { luLocusFunctionalID: 1, description: 'Minimal Impairment' },
        { luLocusFunctionalID: 2, description: 'Mild Impairment' },
        { luLocusFunctionalID: 3, description: 'Moderate Impairment' },
        { luLocusFunctionalID: 4, description: 'Serious Impairment' },
        { luLocusFunctionalID: 5, description: 'Severe Impairment' }
    ]
}

function getLocusCoMorbidityList() {
    return [
        { luLocusCoMorbidityID: 1, description: 'No Co-Morbidity' },
        { luLocusCoMorbidityID: 2, description: 'Minor Co-Morbidity' },
        { luLocusCoMorbidityID: 3, description: 'Significant Co-Morbidity' },
        { luLocusCoMorbidityID: 4, description: 'Major Co-Morbidity' },
        { luLocusCoMorbidityID: 5, description: 'Severe Co-Morbidity' }
    ]
}

function getLocusStressList() {
    return [
        { luLocusStressID: 1, description: 'Low Stress Environment' },
        { luLocusStressID: 2, description: 'Mildly Stressful Environment' },
        { luLocusStressID: 3, description: 'Moderately Stressful Environment' },
        { luLocusStressID: 4, description: 'Highly Stressful Environment' },
        { luLocusStressID: 5, description: 'Extremely Stressful Environment' }
    ]
}

function getLocusRecommendedCareList() {
    return [
        { luLocusRecommendedCareID: 1, description: 'Level One - Recovery Maintenance and Health Management' },
        { luLocusRecommendedCareID: 2, description: 'Level Two - Low Intensity Community Based Services' },
        { luLocusRecommendedCareID: 3, description: 'Level Three - High Intensity Community Based Services' },
        { luLocusRecommendedCareID: 4, description: 'Level Four - Medically Monitored Non-Residential Services' },
        { luLocusRecommendedCareID: 5, description: 'Level Five - Medically Monitored Residential Services' },
        { luLocusRecommendedCareID: 6, description: 'Level Six - Medically Managed Residential Services' }
    ]
}

function getLocusSupportList() {
    return [
        { luLocusSupportID: 1, description: 'Highly Supportive Environment' },
        { luLocusSupportID: 2, description: 'Supportive Environment' },
        { luLocusSupportID: 3, description: 'Limited Support in Environment' },
        { luLocusSupportID: 4, description: 'Minimal Support in Environment' },
        { luLocusSupportID: 5, description: 'No Support in Environment' }
    ]
}

function getLocusTreatmentList() {
    return [
        { luLocusTreatmentID: 1, description: 'Fully Responsive to Treatment and Recovery Management' },
        { luLocusTreatmentID: 2, description: 'Significant Response to Treatment and Recovery Management' },
        { luLocusTreatmentID: 3, description: 'Moderate or Equivocal Response to Treatment and Recovery Management' },
        { luLocusTreatmentID: 4, description: 'Poor response to Treatment and Recovery Management' },
        { luLocusTreatmentID: 5, description: 'Negligible Response to Treatment' }
    ]
}

function getMaritalStatusList() {
    return [
        { luMaritalStatusID: 1, description: 'Never Married' },
        { luMaritalStatusID: 2, description: 'Now Married' },
        { luMaritalStatusID: 3, description: 'Legally or Otherwise Absent' },
        { luMaritalStatusID: 4, description: 'Divorced' },
        { luMaritalStatusID: 5, description: 'Widowed' },
        { luMaritalStatusID: 6, description: 'Minor Child' },
        { luMaritalStatusID: 7, description: 'Unknown' }
    ]
}

function getMediaFolderRootList() {
    return [
        { mediaFolderId: 1, id: 1, parentId: null, mediaId: null, mimeTypeId: null, modifyDate: null, modifyUsername: null, description: 'BHMS', agencyGroupId: null, file: '', isNew: false, isGrantAgency: false, isLocked: true, isLegacy: false, isProjectRoot: true, isDLA20Guidance: false, isMonthlyProviderReportRoot: false, isDocumentationRoot: false, isContractsDeliverablesOutlineRoot: false, isMOURoot: false, isGlobalDirectory: false, isDeletable: false, validationResults: [], children: [] },
        { mediaFolderId: 13868, id: 13868, parentId: null, mediaId: null, mimeTypeId: null, modifyDate: null, modifyUsername: null, description: 'DLA-20 Guidance', agencyGroupId: null, file: '', isNew: false, isGrantAgency: false, isLocked: true, isLegacy: false, isProjectRoot: false, isDLA20Guidance: true, isMonthlyProviderReportRoot: false, isDocumentationRoot: false, isContractsDeliverablesOutlineRoot: false, isMOURoot: false, isGlobalDirectory: true, isDeletable: false, validationResults: [], children: [] },
        { mediaFolderId: 13925, id: 13925, parentId: null, mediaId: null, mimeTypeId: null, modifyDate: null, modifyUsername: null, description: 'Monthly Provider Reports', agencyGroupId: null, file: '', isNew: false, isGrantAgency: false, isLocked: true, isLegacy: false, isProjectRoot: false, isDLA20Guidance: false, isMonthlyProviderReportRoot: true, isDocumentationRoot: false, isContractsDeliverablesOutlineRoot: false, isMOURoot: false, isGlobalDirectory: true, isDeletable: false, validationResults: [], children: [] },
        { mediaFolderId: 14192, id: 14192, parentId: null, mediaId: null, mimeTypeId: null, modifyDate: null, modifyUsername: null, description: 'Documentation', agencyGroupId: null, file: '', isNew: false, isGrantAgency: false, isLocked: true, isLegacy: false, isProjectRoot: false, isDLA20Guidance: false, isMonthlyProviderReportRoot: false, isDocumentationRoot: true, isContractsDeliverablesOutlineRoot: false, isMOURoot: false, isGlobalDirectory: true, isDeletable: false, validationResults: [], children: [] },
        { mediaFolderId: 14242, id: 14242, parentId: null, mediaId: null, mimeTypeId: null, modifyDate: null, modifyUsername: null, description: 'Contracts Deliverables Outline', agencyGroupId: null, file: '', isNew: false, isGrantAgency: false, isLocked: true, isLegacy: false, isProjectRoot: false, isDLA20Guidance: false, isMonthlyProviderReportRoot: false, isDocumentationRoot: false, isContractsDeliverablesOutlineRoot: true, isMOURoot: false, isGlobalDirectory: true, isDeletable: false, validationResults: [], children: [] },
        { mediaFolderId: 14737, id: 14737, parentId: null, mediaId: null, mimeTypeId: null, modifyDate: null, modifyUsername: null, description: `MOU's`, agencyGroupId: null, file: '', isNew: false, isGrantAgency: false, isLocked: true, isLegacy: false, isProjectRoot: false, isDLA20Guidance: false, isMonthlyProviderReportRoot: false, isDocumentationRoot: false, isContractsDeliverablesOutlineRoot: false, isMOURoot: true, isGlobalDirectory: true, isDeletable: false, validationResults: [], children: [] },
    ]
}

function getMHLegalStatusList() {
    return [
        { luMHLegalStatusID: 1, description: 'Voluntary' },
        { luMHLegalStatusID: 2, description: 'Involuntary Civil' },
        { luMHLegalStatusID: 3, description: 'Involuntary Criminal (IH/LO)' },
        { luMHLegalStatusID: 4, description: 'Unknown' },
        { luMHLegalStatusID: 5, description: 'Court Order Evaluation' },
        { luMHLegalStatusID: 6, description: 'Court Order/Patient'  },
        { luMHLegalStatusID: 7, description: 'Juvenile Court Order ' },
        { luMHLegalStatusID: 8, description: 'Juvenile Court/Patient' },
        { luMHLegalStatusID: 9, description: 'Outpatient' },
        { luMHLegalStatusID: 10, description: 'Voluntary/Legal Obligation' },
        { luMHLegalStatusID: 11, description: 'Not Guilty by Reason of Insanity' },
        { luMHLegalStatusID: 12, description: 'Institutional Transfer' },
        { luMHLegalStatusID: 13, description: 'Outpatient Commitment' },
        { luMHLegalStatusID: 14, description: 'Directors Authority' }
    ]
}

function getMHArrestList() {
    return [
        { luYesNoUnknownID: 1, description: 'Yes (Arrested)' },
        { luYesNoUnknownID: 2, description: 'No (Not Arrested)' },
        { luYesNoUnknownID: 9, description: 'Unknown' }
    ]
}

function getMHPopulationList(luFiscalYearId) {
    var x = [
        { luMHPopulationID: 0, description: 'Not SED/SMI' },
        { luMHPopulationID: 2, description: 'SPMI at Transaction Date' },
        { luMHPopulationID: 3, description: 'SED at Transaction Date' },
        { luMHPopulationID: 4, description: 'SMI at Transaction Date' }
    ]

    if (!luFiscalYearId) return x.filter(y => y.luMHPopulationID != 2)

    if (luFiscalYearId <= 15) return x
    else return x.filter(y => y.luMHPopulationID != 2)
}

function getMHServiceList() {
    return [
        { luMentalHealthServiceID: 1, description: 'Clinical Assessment' },
        { luMentalHealthServiceID: 2, description: 'Agency-based Individual/Family Therapy' },
        { luMentalHealthServiceID: 3, description: 'Group Therapy' },
        { luMentalHealthServiceID: 4, description: 'Day Treatment' },
        { luMentalHealthServiceID: 5, description: 'Community-based Individual/Family Therapy' },
        { luMentalHealthServiceID: 6, description: 'Case Management' },
        { luMentalHealthServiceID: 7, description: 'Group Case Management' },
        { luMentalHealthServiceID: 8, description: 'Individual Rehabilitative Services' },
        { luMentalHealthServiceID: 9, description: 'Intensive Individual Rehabilitative Services' },
        { luMentalHealthServiceID: 10, description: 'Group Rehabilitation Services' },
        { luMentalHealthServiceID: 11, description: 'Individual Recreation/Socialization' },
        { luMentalHealthServiceID: 12, description: 'Emergency Services' },
        { luMentalHealthServiceID: 13, description: 'Medication Management Services by Psychiatrist' },
        { luMentalHealthServiceID: 14, description: 'Medication Management Services by General Physician' },
        { luMentalHealthServiceID: 15, description: 'Medication Management Services by Advanced Practitioner of Nursing' },
        { luMentalHealthServiceID: 16, description: 'Medication Management Services by Physician Assistant' },
        { luMentalHealthServiceID: 17, description: 'Medication Management Services by RN' },
        { luMentalHealthServiceID: 18, description: 'Medication Management Services by LPN' },
        { luMentalHealthServiceID: 19, description: 'Vocational/Educational Services' },
        { luMentalHealthServiceID: 20, description: 'Group Recreation/Socialization' },
        { luMentalHealthServiceID: 21, description: 'Supported Education' },
        { luMentalHealthServiceID: 22, description: 'MH Crisis Stabilization' },
        { luMentalHealthServiceID: 23, description: 'MH Residential' },
        { luMentalHealthServiceID: 24, description: 'Supported Employment' },
        { luMentalHealthServiceID: 25, description: 'ACT (Assertive Community Treatment)' },
        { luMentalHealthServiceID: 26, description: 'Peer Specialist - Individual' },
        { luMentalHealthServiceID: 27, description: 'Peer Specialist - Group' },
        { luMentalHealthServiceID: 28, description: 'SUD Co-Occuring Service' },
        { luMentalHealthServiceID: 29, description: 'Title 25 Assessment' },
        { luMentalHealthServiceID: 30, description: 'MH Crisis Stabilization - Title 25 Client' },
        { luMentalHealthServiceID: 31, description: 'Family Support Peer Specialist - Individual' },
        { luMentalHealthServiceID: 32, description: 'Family Support Peer Specialist - Group' },
        { luMentalHealthServiceID: 33, description: 'Family Support Peer Specialist - Other' },
        { luMentalHealthServiceID: 34, description: 'Peer Specialist - Other' },
        { luMentalHealthServiceID: 35, description: 'Gatekeeping' },
        { luMentalHealthServiceID: 36, description: 'MH START' },
        { luMentalHealthServiceID: 37, description: 'MH START - T 25 Client' },
        { luMentalHealthServiceID: 38, description: 'Exceptional Discharge' },
        { luMentalHealthServiceID: 39, description: 'Emergency and Diversion Bundled Services' },
        { luMentalHealthServiceID: 40, description: 'Crisis Clinical Response Services(CCRS)' },
        { luMentalHealthServiceID: 41, description: 'Emergency Care Coordination and Gatekeeping Services(ECCGS)' },
        { luMentalHealthServiceID: 42, description: 'Jail-based Treatment and Re-entry' },
        { luMentalHealthServiceID: 43, description: 'Short Term Crisis LOCUS 4 and 5 Residential' }
    ]
}

function getMHServiceList_Current() {
    return [
        { luMentalHealthServiceID: 1, description: 'Clinical Assessment' },
        { luMentalHealthServiceID: 2, description: 'Agency-based Individual/Family Therapy' },
        { luMentalHealthServiceID: 3, description: 'Group Therapy' },
        { luMentalHealthServiceID: 4, description: 'Day Treatment' },
        { luMentalHealthServiceID: 5, description: 'Community-based Individual/Family Therapy' },
        { luMentalHealthServiceID: 6, description: 'Case Management' },
        { luMentalHealthServiceID: 7, description: 'Group Case Management' },
        { luMentalHealthServiceID: 8, description: 'Individual Rehabilitative Services' },
        { luMentalHealthServiceID: 9, description: 'Intensive Individual Rehabilitative Services' },
        { luMentalHealthServiceID: 10, description: 'Group Rehabilitation Services' },
        { luMentalHealthServiceID: 11, description: 'Individual Recreation/Socialization' },
        { luMentalHealthServiceID: 12, description: 'Emergency Services' },
        { luMentalHealthServiceID: 13, description: 'Medication Management Services by Psychiatrist' },
        { luMentalHealthServiceID: 14, description: 'Medication Management Services by General Physician' },
        { luMentalHealthServiceID: 15, description: 'Medication Management Services by Advanced Practitioner of Nursing' },
        { luMentalHealthServiceID: 16, description: 'Medication Management Services by Physician Assistant' },
        { luMentalHealthServiceID: 17, description: 'Medication Management Services by RN' },
        { luMentalHealthServiceID: 18, description: 'Medication Management Services by LPN' },
        { luMentalHealthServiceID: 19, description: 'Vocational/Educational Services' },
        { luMentalHealthServiceID: 20, description: 'Group Recreation/Socialization' },
        { luMentalHealthServiceID: 21, description: 'Supported Education' },
        { luMentalHealthServiceID: 23, description: 'MH Residential (1 Unit = 1 Day)' },
        { luMentalHealthServiceID: 24, description: 'Supported Employment' },
        { luMentalHealthServiceID: 25, description: 'ACT (Assertive Community Treatment)' },
        { luMentalHealthServiceID: 26, description: 'Peer Specialist - Individual' },
        { luMentalHealthServiceID: 27, description: 'Peer Specialist - Group' },
        { luMentalHealthServiceID: 28, description: 'SUD Co-Occuring Service' },
        { luMentalHealthServiceID: 29, description: 'Title 25 Assessment' },
        { luMentalHealthServiceID: 30, description: 'MH Crisis Stabilization - Title 25 Client' },
        { luMentalHealthServiceID: 31, description: 'Family Support Peer Specialist - Individual' },
        { luMentalHealthServiceID: 32, description: 'Family Support Peer Specialist - Group' },
        { luMentalHealthServiceID: 33, description: 'Family Support Peer Specialist - Other' },
        { luMentalHealthServiceID: 34, description: 'Peer Specialist - Other' },
        { luMentalHealthServiceID: 35, description: 'Gatekeeping' },
        { luMentalHealthServiceID: 36, description: 'MH START' },
        { luMentalHealthServiceID: 37, description: 'MH START - T 25 Client' },
        { luMentalHealthServiceID: 38, description: 'Exceptional Discharge' },
        { luMentalHealthServiceID: 39, description: 'Emergency and Diversion Bundled Services' },
        { luMentalHealthServiceID: 40, description: 'Crisis Clinical Response Services(CCRS)' },
        { luMentalHealthServiceID: 41, description: 'Emergency Care Coordination and Gatekeeping Services(ECCGS)' },
        { luMentalHealthServiceID: 42, description: 'Jail-based Treatment and Re-entry' },
        { luMentalHealthServiceID: 43, description: 'Short Term Crisis LOCUS 4 and 5 Residential' }
    ]
}

/*function getMHServiceUnitList() {
    return [
        { luMentalHealthServiceUnitID: 1, description: 'Outpatient Service (1 Unit = 15 Minutes)' },
        { luMentalHealthServiceUnitID: 2, description: 'Residential Services (1 Unit = 1 Day)' },
        { luMentalHealthServiceUnitID: 3, description: 'Short Term Crisis LOCUS 4 and 5 Residential Services (1 Unit - 1 Day)' }
    ]
}*/

function getMISStandardReportsList() {
    return [
        { reportTypeID: 1, description: 'Client Demographics' },
        { reportTypeID: 2, description: 'Key Indicators' },
        { reportTypeID: 3, description: 'Clinical' }
    ]
}

function getNoSSNReasonList() {
    return [
        { noSSNReasonID: 1, description: 'Doesn\'t have an SSN because client is undocumented alien' },
        { noSSNReasonID: 2, description: 'Doesn\'t have an SSN (because not required to. Example: minor, international student, etc.)' },
        { noSSNReasonID: 3, description: 'Has an SSN but refused to disclose' }
    ]
}

function getOriginationTypes() {
    return [
        { luOriginationTypeId: 1, description: 'Agency' },
        { luOriginationTypeId: 2, description: 'Facility' },
        { luOriginationTypeId: 3, description: 'Jail' },
        { luOriginationTypeId: 4, description: 'Home' }
    ]
}

function getPresentingProblems() {
    return [
        { luPresentingProblemID: 0, description: 'Not Applicable' },
        { luPresentingProblemID: 1, description: 'Marital/Family' },
        { luPresentingProblemID: 2, description: 'Social' },
        { luPresentingProblemID: 3, description: 'Coping' },
        { luPresentingProblemID: 4, description: 'Medical' },
        { luPresentingProblemID: 5, description: 'Mood Disorder' },
        { luPresentingProblemID: 6, description: 'Suicide' },
        { luPresentingProblemID: 7, description: 'Alcohol' },
        { luPresentingProblemID: 8, description: 'Drugs' },
        { luPresentingProblemID: 9, description: 'Criminal Justice System' },
        { luPresentingProblemID: 10, description: 'Eating Disorder' },
        { luPresentingProblemID: 11, description: 'Thought Disorder' },
        { luPresentingProblemID: 12, description: 'Abuse/Assault/Rape Victim' },
        { luPresentingProblemID: 13, description: 'Runaway Behavior' },
        { luPresentingProblemID: 14, description: 'Perpetrator' },
        { luPresentingProblemID: 15, description: 'Evaluation' },
        { luPresentingProblemID: 16, description: 'Gambling' }
    ]
}

function getPrimaryIncomeSourceList() {
    return [
        { luPrimaryIncomeSourceID: 1, description: 'Self' },
        { luPrimaryIncomeSourceID: 2, description: 'Family (Parent/Guardian/Spouse/Adult Children)' },
        { luPrimaryIncomeSourceID: 3, description: 'SSI (Supplemental Security Income)' },
        { luPrimaryIncomeSourceID: 4, description: 'SSDI (Social Security Disability Income)' },
        { luPrimaryIncomeSourceID: 5, description: 'Other Disability' },
        { luPrimaryIncomeSourceID: 6, description: 'Retirement' },
        { luPrimaryIncomeSourceID: 7, description: 'DFS (Department of Family Services/Welfare)' },
        { luPrimaryIncomeSourceID: 8, description: 'Other/Unemployment' },
        { luPrimaryIncomeSourceID: 99, description: 'Unknown' }
    ]
}

function getPriorityPopulationReportList(agencytypeid) {
    var x = [
        { luPriorityPopulationReportId: 1, id: 1, isMH: true, isSA: true, description: 'Veteran'},
        //{ luPriorityPopulationReportId: 2, id: 2, isMH: true, isSA: true, description: 'Parole',},
        { luPriorityPopulationReportId: 3, id: 3, isMH: true, isSA: true, description: 'Probation'},
        { luPriorityPopulationReportId: 4, id: 4, isMH: true, isSA: true, description: 'Probation Under Supervision',},
        { luPriorityPopulationReportId: 5, id: 5, isMH: false, isSA: true, description: 'Persons Who Inject Drugs'},
        { luPriorityPopulationReportId: 6, id: 6, isMH: false, isSA: true, description: 'Female Pregnant' },
        { luPriorityPopulationReportId: 7, id: 7, isMH: false, isSA: true, description: 'Female With Dependent Children' },
        { luPriorityPopulationReportId: 8, id: 8, isMH: true, isSA: false, description: 'SMI'},
        { luPriorityPopulationReportId: 9, id: 9, isMH: true, isSA: false, description: 'SED'},
    ]
    if (agencytypeid && agencytypeid == 1) {
        return x.filter(y => y.isSA)
    }
    else if (agencytypeid && (agencytypeid == 2 || agencytypeid == 3)) return x.filter(y => y.isMH)
    else return x
}

function getPriorityPopulations() {
    var x = [
        { luPriorityPopulationID: 1, description: 'Pregnant', sortOrder: 6 },
        { luPriorityPopulationID: 2, description: 'Woman with Dependent Child(ren)', sortOrder: 10 },
        { luPriorityPopulationID: 3, description: 'Persons Who Inject Drugs (PWID)', sortOrder: 3 },
        { luPriorityPopulationID: 9, description: 'Not Applicable', sortOrder: 20 },
        { luPriorityPopulationID: 20, description: 'SMI (includes SPMI)', sortOrder: 9 },
        { luPriorityPopulationID: 21, description: 'SED', sortOrder: 8 },
        { luPriorityPopulationID: 22, description: 'Criminal Justice', sortOrder: 2 },
        { luPriorityPopulationID: 23, description: 'Juvenile Justice', sortOrder: 4 },
        { luPriorityPopulationID: 24, description: 'Probation', sortOrder: 7 },
        { luPriorityPopulationID: 25, description: 'Parole', sortOrder: 5 },
        { luPriorityPopulationID: 26, description: 'Co-Occurring - MH/SUD', sortOrder: 1 }
    ]
    return utilService.sortJSON(x, 'sortOrder')
}

function getPriorityPopulationsOther() {
    return [
        { luPriorityPopulationOtherID: 1, description: 'Co-Occurring - Medical' },
        { luPriorityPopulationOtherID: 2, description: 'Geriatric' },
        { luPriorityPopulationOtherID: 3, description: 'Adolescent' },
        { luPriorityPopulationOtherID: 4, description: 'Title 25' }
    ]
}

function getParoleList() {
    return [
        { luParoleID: 1, description: 'Yes - I am currently on Parole (at Transaction Date)' },
        { luParoleID: 2, description: 'No - I am not currently on Parole (at Transaction Date)' }
    ]
}

function getProbationUnderSupervisionList() {
    return [
        { luProbationUnderSupervisionID: 1, description: 'Yes - I am currently on Probation Under Supervision (at Transaction Date)' },
        { luProbationUnderSupervisionID: 2, description: 'No - I am not currently on Probation Under Supervision (at Transaction Date)' }
    ]
}

function getProbationNotUnderSupervisionList() {
    return [
        { luProbationNotUnderSupervisionID: 1, description: 'Yes - I am currently on Probation Not Under Supervision (at Transaction Date)' },
        { luProbationNotUnderSupervisionID: 2, description: 'No - I am not currently on Probation Not Under Supervision (at Transaction Date)' }
    ]
}

function getProcessList() {
    return [
        { id: 1, description: 'Provider Application' },
        { id: 2, description: 'Provider Renewal' },
        { id: 3, description: 'Provider Update' },
    ]
}

function getProcessStatusList(processId) {
    var x = [
        { id: 1, processId: 1, description: 'Application In Progress' },
        { id: 2, processId: 1, description: 'Application Under Review' },
        { id: 3, processId: 1, description: 'Application Approved' },
        { id: 4, processId: 1, description: 'Application Rejected' },
        { id: 5, processId: 2, description: 'Provider Renewal In Progress' },
        { id: 6, processId: 2, description: 'Provider Renewal Under Review' },
        { id: 7, processId: 2, description: 'Provider Renewal Approved' },
        { id: 8, processId: 2, description: 'Provider Renewal Rejected' },
        { id: 9, processId: 3, description: 'Provider Update In Progress' },
        { id: 10, processId: 3, description: 'Provider Update Under Review' },
        { id: 11, processId: 3, description: 'Provider Update Approved' },
        { id: 12, processId: 3, description: 'Provider Update Rejected' },
    ]

    if (processId) return x.filter(y => y.processId === processId)
    else return x
}

function getQOLInvoiceCategoryList() {
    return [
        { luInvoiceCategoryID: 1, description: 'SMI' },
        { luInvoiceCategoryID: 2, description: 'SED' },
        { luInvoiceCategoryID: 3, description: 'Other' },
        { luInvoiceCategoryID: 5, description: 'Adults' },
        { luInvoiceCategoryID: 6, description: 'Adolescents' },
        { luInvoiceCategoryID: 12, description: 'Transfer Funds' },
    ]
}

function getRaces() {
    return [
        { luRaceID: 1, description: 'White' },
        { luRaceID: 2, description: 'Black' },
        { luRaceID: 3, description: 'Native American/Alaskan' },
        { luRaceID: 4, description: 'Asian' },
        { luRaceID: 5, description: 'Other/Unknown' },
        { luRaceID: 6, description: 'Native Hawaiian/Other Pacific Islander' },
        { luRaceID: 7, description: 'More Than One Race Reported' }
    ]
}

function getReasonForTransferList() {
    return [
        { luReasonForTransferId: 1, description: 'Needs Higher Level of Care' },
        { luReasonForTransferId: 2, description: 'Needs Lower Level of Care' },
        { luReasonForTransferId: 3, description: 'ASI/ASAM Recommendation' },
        { luReasonForTransferId: 4, description: 'T25 Detained' }
    ]
}

function getReferalSourceList(luFiscalYearId) {
    switch (luFiscalYearId) {
        case 19: //FY25
            return [
                { luReferalSourceID: 1, description: 'Self' },
                { luReferalSourceID: 2, description: 'Family/Friends' },
                { luReferalSourceID: 3, description: 'Police/Law Enforcement' },
                { luReferalSourceID: 4, description: 'Court (not Title 25 clients)' },
                { luReferalSourceID: 5, description: 'Private Psychiatrist' },
                { luReferalSourceID: 6, description: 'Other Physician' },
                { luReferalSourceID: 7, description: 'Other Private Mental Health Practitioner' },
                { luReferalSourceID: 8, description: 'Clergy' },
                { luReferalSourceID: 9, description: 'Wyoming State Hospital' },
                { luReferalSourceID: 10, description: 'Other Inpatient Psychiatric Service' },
                { luReferalSourceID: 11, description: 'Drug/Alcohol Abuse Treatment Center' },
                { luReferalSourceID: 13, description: 'Shelter' },
                { luReferalSourceID: 14, description: 'Behavioral Health Center' },
                { luReferalSourceID: 15, description: 'Other' },
                { luReferalSourceID: 16, description: 'Schools' },
                { luReferalSourceID: 17, description: 'Employer' },
                { luReferalSourceID: 18, description: 'DFS (Department of Family Services)' },
                { luReferalSourceID: 19, description: 'DVR (Division of Vocational Rehabilitation)' },
                { luReferalSourceID: 20, description: 'Nursing Home' },
                { luReferalSourceID: 21, description: 'Medical Hospital' },
                { luReferalSourceID: 22, description: 'DD (Developmental Disability)' },
                { luReferalSourceID: 23, description: 'Drug Court' },
                { luReferalSourceID: 25, description: 'Adult Probation and Parole' },
                { luReferalSourceID: 26, description: 'Juvenile Probation (DFS)' },
                { luReferalSourceID: 27, description: 'Early Childhood Setting' },
                { luReferalSourceID: 28, description: 'Attorney' },
                { luReferalSourceID: 29, description: 'Veterans Affairs' },
                { luReferalSourceID: 30, description: 'Department of Corrections' },
                { luReferalSourceID: 31, description: 'Court Order (Title 25 clients)' },
                { luReferalSourceID: 32, description: 'Social Security/Disability' },
                { luReferalSourceID: 33, description: 'WLRC (Wyoming Life Resource Center)' },
                { luReferalSourceID: 34, description: '988 System' },
                { luReferalSourceID: 35, description: 'Youth Crisis Center' },
                { luReferalSourceID: 36, description: 'WBI (Wyoming Behavioral Institute)' },
                { luReferalSourceID: 99, description: 'Unknown' },
            ]
        default:
            return [
                { luReferalSourceID: 1, description: 'Self' },
                { luReferalSourceID: 2, description: 'Family/Friends' },
                { luReferalSourceID: 3, description: 'Police/Law Enforcement' },
                { luReferalSourceID: 4, description: 'Court (not Title 25 clients)' },
                { luReferalSourceID: 5, description: 'Private Psychiatrist' },
                { luReferalSourceID: 6, description: 'Other Physician' },
                { luReferalSourceID: 7, description: 'Other Private Mental Health Practitioner' },
                { luReferalSourceID: 8, description: 'Clergy' },
                { luReferalSourceID: 9, description: 'Wyoming State Hospital' },
                { luReferalSourceID: 10, description: 'Other Inpatient Psychiatric Service' },
                { luReferalSourceID: 11, description: 'Drug/Alcohol Abuse Treatment Center' },
                { luReferalSourceID: 13, description: 'Shelter' },
                { luReferalSourceID: 14, description: 'Community Mental Health Center' },
                { luReferalSourceID: 15, description: 'Other' },
                { luReferalSourceID: 16, description: 'Schools' },
                { luReferalSourceID: 17, description: 'Employer' },
                { luReferalSourceID: 18, description: 'DFS (Department of Family Services)' },
                { luReferalSourceID: 19, description: 'DVR (Division of Vocational Rehabilitation)' },
                { luReferalSourceID: 20, description: 'Nursing Home' },
                { luReferalSourceID: 21, description: 'Medical Hospital' },
                { luReferalSourceID: 22, description: 'DD (Developmental Disability)' },
                { luReferalSourceID: 23, description: 'Drug Court' },
                { luReferalSourceID: 25, description: 'Adult Probation and Parole' },
                { luReferalSourceID: 26, description: 'Juvenile Probation (DFS)' },
                { luReferalSourceID: 27, description: 'Early Childhood Setting' },
                { luReferalSourceID: 28, description: 'Attorney' },
                { luReferalSourceID: 29, description: 'Veterans Affairs' },
                { luReferalSourceID: 30, description: 'Department of Corrections' },
                { luReferalSourceID: 31, description: 'Court Order (Title 25 clients)' },
                { luReferalSourceID: 32, description: 'Social Security/Disability' },
                { luReferalSourceID: 33, description: 'WLRC (Wyoming Life Resource Center)' },
                { luReferalSourceID: 34, description: '988 System'},
                { luReferalSourceID: 99, description: 'Unknown' },
            ]
    }
}

function getResidenceList(luFiscalYearId) {
    switch (luFiscalYearId) {
        case 19: //FY25
            return [
                { luResidenceID: 1, description: 'Lacks a fixed, regular, night-time residence (shelters, transitional housing, street, friends/relatives)' },
                { luResidenceID: 2, description: 'Private Residence/Household' },
                { luResidenceID: 3, description: 'Group Home' },
                { luResidenceID: 4, description: 'Residential Treatment Center' },
                { luResidenceID: 5, description: 'Boarding/Foster Home' },
                { luResidenceID: 6, description: 'Jail/Correctional Facility' },
                { luResidenceID: 7, description: 'Hospital' },
                { luResidenceID: 8, description: 'Other Residential Setting' },
                { luResidenceID: 9, description: 'Unknown' },
                { luResidenceID: 10, description: 'Nursing Home' },
            ]
        default:
            return [
                { luResidenceID: 1, description: 'Lacks a fixed, regular, night-time residence (shelters, transitional housing, street, friends/relatives)' },
                { luResidenceID: 2, description: 'Private Residence/Household' },
                { luResidenceID: 3, description: 'Group Home' },
                { luResidenceID: 4, description: 'Residential Treatment Center' },
                { luResidenceID: 5, description: 'Boarding/Foster Home' },
                { luResidenceID: 6, description: 'Jail/Correctional Facility' },
                { luResidenceID: 7, description: 'Hospital' },
                { luResidenceID: 8, description: 'Other Residential Setting' },
                { luResidenceID: 9, description: 'Unknown' },
                { luResidenceID: 10, description: 'Nursing Home' },
            ]
    }
}

function getSADrugProblemList(date) {
    var x = [
        { luSADrugProblemID: 0, description: 'None' },
        { luSADrugProblemID: 1, description: 'Heroin' },
        { luSADrugProblemID: 2, description: 'Non-Rx Methadone' },
        { luSADrugProblemID: 3, description: 'Other Opiates and Synthetics' },
        { luSADrugProblemID: 4, description: 'Alcohol' },
        { luSADrugProblemID: 5, description: 'Barbiturates' },
        { luSADrugProblemID: 6, description: 'Other Sedatives' },
        { luSADrugProblemID: 7, description: 'Other Amphetamines' },
        { luSADrugProblemID: 8, description: 'Cocaine/Crack' },
        { luSADrugProblemID: 9, description: 'Marijuana/Hashish' },
        { luSADrugProblemID: 10, description: 'Hallucinogens' },
        { luSADrugProblemID: 11, description: 'Inhalants' },
        { luSADrugProblemID: 12, description: 'Over-the-counter' },
        { luSADrugProblemID: 13, description: 'Tranquilizers' },
        { luSADrugProblemID: 14, description: 'PCP/Other Hallucinogens' },
        { luSADrugProblemID: 15, description: 'Other' },
        { luSADrugProblemID: 16, description: 'Methamphetamine' },
        { luSADrugProblemID: 17, description: 'Other Stimulates' },
        { luSADrugProblemID: 18, description: 'Benzodiazepines' },
        { luSADrugProblemID: 19, description: 'MDMA/Ecstasy' },
        { luSADrugProblemID: 20, description: 'Rohypnol' },
        { luSADrugProblemID: 21, description: 'GHB/GBL' },
        { luSADrugProblemID: 22, description: 'Ketamine' },
        { luSADrugProblemID: 23, description: 'Clonazepam' },
        { luSADrugProblemID: 24, description: 'Buprenorphine' },
        { luSADrugProblemID: 25, description: 'Fentanyl', start: new Date(2022, 6, 1), end: null  },
        { luSADrugProblemID: 99, description: 'Unknown' }
    ]

    if (date) return x.filter(y => utilService.dateBetween(y.start, y.end, date, true))
    return x
}

function getSAFemaleList() {
    return [
        { luSAFemaleID: 0, description: 'Not Applicable' },
        { luSAFemaleID: 1, description: 'Female Not Pregnant and No Dependent Children' },
        { luSAFemaleID: 2, description: 'Female with Dependent Children' },
        { luSAFemaleID: 3, description: 'Female Pregnant and No Dependent Children' },
        { luSAFemaleID: 4, description: 'Female Pregnant with Dependent Children' },
        { luSAFemaleID: 9, description: 'Unknown' }
    ]
}

function getSAFrequencyOfUseList(luFiscalYearId) {
    var x = [
        { luSAFrequencyOfUseID: 0, description: 'No Use' },
        { luSAFrequencyOfUseID: 1, description: 'Less Than Once Per Week' },
        { luSAFrequencyOfUseID: 2, description: 'Once Per Week' },
        { luSAFrequencyOfUseID: 3, description: 'Several Times Per Week' },
        { luSAFrequencyOfUseID: 4, description: 'Once Daily' },
        { luSAFrequencyOfUseID: 5, description: 'Two to Three Times Daily' },
        { luSAFrequencyOfUseID: 6, description: 'More than Three Times Daily' },
        { luSAFrequencyOfUseID: 7, description: 'Unknown' },
        { luSAFrequencyOfUseID: 8, description: 'No Use due to recent stay in Controlled Environment' }
    ]

    if (!luFiscalYearId) return x.filter(y => y.luSAFrequencyOfUseID != 7)

    if (luFiscalYearId <= 15) return x
    else return x.filter(y => y.luSAFrequencyOfUseID != 7)
}

function getSALevelOfCareList_Current() {
    return [
        { luSALevelOfCareID: 0, description: 'No Treatment Recommended' },
        { luSALevelOfCareID: 12, description: 'Level 0.5 Early Intervention' },
        { luSALevelOfCareID: 13, description: 'Level I Outpatient Adolescent' },
        { luSALevelOfCareID: 14, description: 'Level I Outpatient Adult' },
        { luSALevelOfCareID: 17, description: 'Level II.1 Intensive Outpatient Adolescent' },
        { luSALevelOfCareID: 18, description: 'Level II.1 Intensive Outpatient Adult' },
        { luSALevelOfCareID: 19, description: 'Level II.5 Partial Hospitalization Adolescent' },
        { luSALevelOfCareID: 20, description: 'Level II.5 Partial Hospitalization Adult' },
        { luSALevelOfCareID: 21, description: 'Level III.1 Clinically Managed Low-Intensity Residential Adolescent' },
        { luSALevelOfCareID: 22, description: 'Level III.1 Clinically Managed Low-Intensity Residential Adult' },
        { luSALevelOfCareID: 23, description: 'Level III.2-D Clinically Managed Residential Detoxification Adult' },
        { luSALevelOfCareID: 24, description: 'Level III.3 Clinically Managed Medium-Intensity Residential Adult' },
        { luSALevelOfCareID: 25, description: 'Level III.5 Clinically Managed High-Intensity Residential Adolescent' },
        { luSALevelOfCareID: 26, description: 'Level III.5 Clinically Managed High-Intensity Residential Adult' },
        { luSALevelOfCareID: 27, description: 'Level III.5-D Clinically Managed Residential Detoxification Adolescent' },
        { luSALevelOfCareID: 28, description: 'Level III.7 Medically Monitored High-Intensity Residential/Inpatient Adolescent' },
        { luSALevelOfCareID: 29, description: 'Level III.7 Medically Monitored Intensive Inpatient Adult' },
        { luSALevelOfCareID: 30, description: 'Level III.7-D Medically Monitored Inpatient Detoxification Adolescent' },
        { luSALevelOfCareID: 31, description: 'Level III.7-D Medically Monitored Inpatient Detoxification Adult' },
        { luSALevelOfCareID: 32, description: 'Level IV Medically Managed Intensive Inpatient Adolescent' },
        { luSALevelOfCareID: 33, description: 'Level IV Medically Managed Intensive Inpatient Adult' },
        { luSALevelOfCareID: 34, description: 'Level IV-D Medically Managed Intensive Inpatient Detoxification Adolescent' },
        { luSALevelOfCareID: 35, description: 'Level IV-D Medically Managed Intensive Inpatient Detoxification Adult' }
    ]
}

function getSANumberOfArrestsList() {
    return [
        { luSANumberOfArrestsID: 0, description: 'None' },
        { luSANumberOfArrestsID: 1, description: '1' },
        { luSANumberOfArrestsID: 2, description: '2' },
        { luSANumberOfArrestsID: 3, description: '3' },
        { luSANumberOfArrestsID: 4, description: 'More than 3' },
        { luSANumberOfArrestsID: 9, description: 'Unknown' }
    ]
}

function getSANumberOfChildrenList() {
    return [
        { luSANumberOfChildrenID: 0, description: 'Not Applicable' },
        { luSANumberOfChildrenID: 1, description: '1 Child' },
        { luSANumberOfChildrenID: 2, description: '2 Children' },
        { luSANumberOfChildrenID: 3, description: '3 Children' },
        { luSANumberOfChildrenID: 4, description: 'More Than 3 Children' }
    ]
}

function getSAPrimaryServiceCategoryList_Current() {
    return [
        { luSAPrimaryServiceCategoryID: 0, description: 'No Treatment Recommended' },
        { luSAPrimaryServiceCategoryID: 12, description: 'Level 0.5 Early Intervention' },
        { luSAPrimaryServiceCategoryID: 13, description: 'Level I Outpatient Adolescent' },
        { luSAPrimaryServiceCategoryID: 14, description: 'Level I Outpatient Adult' },
        { luSAPrimaryServiceCategoryID: 17, description: 'Level II.1 Intensive Outpatient Adolescent' },
        { luSAPrimaryServiceCategoryID: 18, description: 'Level II.1 Intensive Outpatient Adult' },
        { luSAPrimaryServiceCategoryID: 19, description: 'Level II.5 Partial Hospitalization Adolescent' },
        { luSAPrimaryServiceCategoryID: 20, description: 'Level II.5 Partial Hospitalization Adult' },
        { luSAPrimaryServiceCategoryID: 21, description: 'Level III.1 Clinically Managed Low-Intensity Residential Adolescent' },
        { luSAPrimaryServiceCategoryID: 22, description: 'Level III.1 Clinically Managed Low-Intensity Residential Adult' },
        { luSAPrimaryServiceCategoryID: 23, description: 'Level III.2-D Clinically Managed Residential Detoxification Adult' },
        { luSAPrimaryServiceCategoryID: 24, description: 'Level III.3 Clinically Managed Medium-Intensity Residential Adult' },
        { luSAPrimaryServiceCategoryID: 25, description: 'Level III.5 Clinically Managed High-Intensity Residential Adolescent' },
        { luSAPrimaryServiceCategoryID: 26, description: 'Level III.5 Clinically Managed High-Intensity Residential Adult' },
        { luSAPrimaryServiceCategoryID: 27, description: 'Level III.5-D Clinically Managed Residential Detoxification Adolescent' },
        { luSAPrimaryServiceCategoryID: 28, description: 'Level III.7 Medically Monitored High-Intensity Residential/Inpatient Adolescent' },
        { luSAPrimaryServiceCategoryID: 29, description: 'Level III.7 Medically Monitored Intensive Inpatient Adult' },
        { luSAPrimaryServiceCategoryID: 30, description: 'Level III.7-D Medically Monitored Inpatient Detoxification Adolescent' },
        { luSAPrimaryServiceCategoryID: 31, description: 'Level III.7-D Medically Monitored Inpatient Detoxification Adult' },
        { luSAPrimaryServiceCategoryID: 32, description: 'Level IV Medically Managed Intensive Inpatient Adolescent' },
        { luSAPrimaryServiceCategoryID: 33, description: 'Level IV Medically Managed Intensive Inpatient Adult' },
        { luSAPrimaryServiceCategoryID: 34, description: 'Level IV-D Medically Managed Intensive Inpatient Detoxification Adolescent' },
        { luSAPrimaryServiceCategoryID: 35, description: 'Level IV-D Medically Managed Intensive Inpatient Detoxification Adult' }
    ]
}

function getSARouteOfAdministration() {
    return [
        { luSARouteOfAdministrationID: 0, description: 'None' },
        { luSARouteOfAdministrationID: 1, description: 'Oral' },
        { luSARouteOfAdministrationID: 2, description: 'Smoking' },
        { luSARouteOfAdministrationID: 3, description: 'Inhalation' },
        { luSARouteOfAdministrationID: 4, description: 'Intramuscular' },
        { luSARouteOfAdministrationID: 5, description: 'Intravenous' },
        { luSARouteOfAdministrationID: 6, description: 'Unknown' }
    ]
}

function getSentinelEventIncidentTypeList() {
    return [
        { luSentinelEventIncidentTypeId: 1, description: 'Physical injury to self or others' },
        { luSentinelEventIncidentTypeId: 2, description: 'Risk of physical injury to self or others' },
        { luSentinelEventIncidentTypeId: 3, description: 'Pyschological injury to self or others' },
        { luSentinelEventIncidentTypeId: 4, description: 'Risk of psychological injury to self or others' },
    ]
}

function getSexualOrientationList(luFiscalYearId) {
    switch (luFiscalYearId) {
        case 19: //FY25
            return [
                { id: 1, description: 'Straight or Heterosexual' },
                { id: 2, description: 'Lesbian or Gay' },
                { id: 3, description: 'Bisexual' },
                { id: 5, description: 'Additional Sexual Orientation Category (or Other)' },
                { id: 6, description: 'Queer' },
                { id: 7, description: 'Pansexual' },
                { id: 8, description: 'Questioning' },
                { id: 9, description: 'Unknown/Preferred Not to Respond' },
                { id: 10, description: 'Asexual' },
            ]
        default:
            return [
                { id: 1, description: 'Straight or Heterosexual' },
                { id: 2, description: 'Lesbian or Gay' },
                { id: 3, description: 'Bisexual' },
                { id: 5, description: 'Additional Sexual Orientation Category (or Other)' },
                { id: 6, description: 'Queer' },
                { id: 7, description: 'Pansexual' },
                { id: 8, description: 'Questioning' },
                { id: 9, description: 'Unknown/Preferred Not to Respond' },
                { id: 10, description: 'Asexual' },
            ]
    }
}

function getSAServiceList() {
    return [
        { luSubstanceAbuseServiceID: 1, description: 'Client Engagement Services' },
        { luSubstanceAbuseServiceID: 2, description: 'Clinical Assessment' },
        { luSubstanceAbuseServiceID: 3, description: 'Agency-based Individual/Family Therapy' },
        { luSubstanceAbuseServiceID: 4, description: 'Community-based Individual/Family Therapy' },
        { luSubstanceAbuseServiceID: 5, description: 'Group Therapy' },
        { luSubstanceAbuseServiceID: 6, description: 'Intensive Outpatient Group' },
        { luSubstanceAbuseServiceID: 7, description: 'Intensive Outpatient Individual' },
        { luSubstanceAbuseServiceID: 8, description: 'Women\'s Intensive Outpatient Group' },
        { luSubstanceAbuseServiceID: 9, description: 'Case Management' },
        { luSubstanceAbuseServiceID: 10, description: 'Medication Management Services by Psychiatrist' },
        { luSubstanceAbuseServiceID: 11, description: 'Medication Management Services by General Physician' },
        { luSubstanceAbuseServiceID: 12, description: 'Medication Management Services by Advanced Practitioner of Nursing' },
        { luSubstanceAbuseServiceID: 13, description: 'Medication Management Services by Physician Assistant' },
        { luSubstanceAbuseServiceID: 14, description: 'Medication Management Services by RN' },
        { luSubstanceAbuseServiceID: 15, description: 'Medication Management Services by LPN' },
        { luSubstanceAbuseServiceID: 16, description: 'Social Detox' },
        { luSubstanceAbuseServiceID: 17, description: 'Medically Monitored Detox/Inpatient' },
        { luSubstanceAbuseServiceID: 18, description: 'Primary Residential Treatment - Adult' },
        { luSubstanceAbuseServiceID: 19, description: 'Primary Residential Treatment - Adolescent' },
        { luSubstanceAbuseServiceID: 20, description: 'Transitional Housing' },
        { luSubstanceAbuseServiceID: 21, description: 'Peer Specialist - Individual' },
        { luSubstanceAbuseServiceID: 22, description: 'Peer Specialist - Group' },
        { luSubstanceAbuseServiceID: 23, description: 'MH Co - Occurring Service' },
        { luSubstanceAbuseServiceID: 24, description: 'Emergency Services' },
        { luSubstanceAbuseServiceID: 25, description: 'Intensive Outpatient Group(308 Dollars)' },
        { luSubstanceAbuseServiceID: 26, description: 'MAT(Medicated Assisted Treatment)' },
        { luSubstanceAbuseServiceID: 27, description: 'Supported Employment' },
        { luSubstanceAbuseServiceID: 28, description: 'Family Support Peer Specialist - Individual' },
        { luSubstanceAbuseServiceID: 29, description: 'Family Support Peer Specialist - Group' },
        { luSubstanceAbuseServiceID: 30, description: 'Family Support Peer Specialist - Other' },
        { luSubstanceAbuseServiceID: 31, description: 'Peer Specialist - Other' },
        { luSubstanceAbuseServiceID: 32, description: 'MAT for Alcohol Use' },
        { luSubstanceAbuseServiceID: 33, description: 'MAT for Opioid Use' },
        { luSubstanceAbuseServiceID: 34, description: 'MAT for Meth Use' },
        { luSubstanceAbuseServiceID: 35, description: 'Group Case Management' },
        { luSubstanceAbuseServiceID: 36, description: 'Individual Rehabilitative Services' },
        { luSubstanceAbuseServiceID: 37, description: 'Agency-based Individual/Family Therapy(308 Dollars)' },
        { luSubstanceAbuseServiceID: 38, description: 'Women\'s Intensive Outpatient Group (308 Dollars)' },
        { luSubstanceAbuseServiceID: 39, description: 'Case Management(308 Dollars)' },
        { luSubstanceAbuseServiceID: 40, description: 'Medication Management Services by Psychiatrist(308 Dollars)' },
        { luSubstanceAbuseServiceID: 41, description: 'Medication Management Services by Advanced Practitioner of Nursing(308 Dollars)' },
        { luSubstanceAbuseServiceID: 42, description: 'Peer Specialist - Individual(308 Dollars)' },
        { luSubstanceAbuseServiceID: 43, description: 'Peer Specialist - Group(308 Dollars)' },
        { luSubstanceAbuseServiceID: 44, description: 'Supported Employment(308 Dollars)' },
        { luSubstanceAbuseServiceID: 45, description: 'Individual Rehabilitative Services(308 Dollars)' },
        { luSubstanceAbuseServiceID: 46, description: 'SA Rehabilitative Services - Group' },
        { luSubstanceAbuseServiceID: 47, description: 'Crisis Clinical Response Services(CCRS)' },
        { luSubstanceAbuseServiceID: 48, description: 'Jail-based Treatment and Re-entry' }
    ]
}

function getSAServiceList_Current() {
    return [
        { luSubstanceAbuseServiceID: 1, description: 'Client Engagement Services' },
        { luSubstanceAbuseServiceID: 2, description: 'Clinical Assessment' },
        { luSubstanceAbuseServiceID: 3, description: 'Agency-based Individual/Family Therapy' },
        { luSubstanceAbuseServiceID: 4, description: 'Community-based Individual/Family Therapy' },
        { luSubstanceAbuseServiceID: 5, description: 'Group Therapy' },
        { luSubstanceAbuseServiceID: 6, description: 'Intensive Outpatient Group' },
        { luSubstanceAbuseServiceID: 7, description: 'Intensive Outpatient Individual' },
        { luSubstanceAbuseServiceID: 8, description: 'Women�s Intensive Outpatient Group' },
        { luSubstanceAbuseServiceID: 9, description: 'Case Management' },
        { luSubstanceAbuseServiceID: 10, description: 'Medication Management Services by Psychiatrist' },
        { luSubstanceAbuseServiceID: 11, description: 'Medication Management Services by General Physician' },
        { luSubstanceAbuseServiceID: 12, description: 'Medication Management Services by Advanced Practitioner of Nursing' },
        { luSubstanceAbuseServiceID: 13, description: 'Medication Management Services by Physician Assistant' },
        { luSubstanceAbuseServiceID: 14, description: 'Medication Management Services by RN' },
        { luSubstanceAbuseServiceID: 15, description: 'Medication Management Services by LPN' },
        { luSubstanceAbuseServiceID: 16, description: 'Social Detox' },
        { luSubstanceAbuseServiceID: 17, description: 'Medically Monitored Detox/Inpatient' },
        { luSubstanceAbuseServiceID: 18, description: 'Primary Residential Treatment - Adult' },
        { luSubstanceAbuseServiceID: 19, description: 'Primary Residential Treatment - Adolescent' },
        { luSubstanceAbuseServiceID: 20, description: 'Transitional Housing' },
        { luSubstanceAbuseServiceID: 21, description: 'Peer Specialist - Individual' },
        { luSubstanceAbuseServiceID: 22, description: 'Peer Specialist - Group' },
        { luSubstanceAbuseServiceID: 23, description: 'MH Co - Occurring Service' },
        { luSubstanceAbuseServiceID: 24, description: 'Emergency Services' },
        { luSubstanceAbuseServiceID: 25, description: 'Intensive Outpatient Group(308 Dollars)' },
        { luSubstanceAbuseServiceID: 26, description: 'MAT(Medicated Assisted Treatment)' },
        { luSubstanceAbuseServiceID: 27, description: 'Supported Employment' },
        { luSubstanceAbuseServiceID: 28, description: 'Family Support Peer Specialist - Individual' },
        { luSubstanceAbuseServiceID: 29, description: 'Family Support Peer Specialist - Group' },
        { luSubstanceAbuseServiceID: 30, description: 'Family Support Peer Specialist - Other' },
        { luSubstanceAbuseServiceID: 31, description: 'Peer Specialist - Other' },
        { luSubstanceAbuseServiceID: 32, description: 'MAT for Alcohol Use' },
        { luSubstanceAbuseServiceID: 33, description: 'MAT for Opioid Use' },
        { luSubstanceAbuseServiceID: 34, description: 'MAT for Meth Use' },
        { luSubstanceAbuseServiceID: 35, description: 'Group Case Management' },
        { luSubstanceAbuseServiceID: 36, description: 'Individual Rehabilitative Services' },
        { luSubstanceAbuseServiceID: 37, description: 'Agency-based Individual/Family Therapy(308 Dollars)' },
        { luSubstanceAbuseServiceID: 38, description: 'Women\'s Intensive Outpatient Group (308 Dollars)' },
        { luSubstanceAbuseServiceID: 39, description: 'Case Management(308 Dollars)' },
        { luSubstanceAbuseServiceID: 40, description: 'Medication Management Services by Psychiatrist(308 Dollars)' },
        { luSubstanceAbuseServiceID: 41, description: 'Medication Management Services by Advanced Practitioner of Nursing(308 Dollars)' },
        { luSubstanceAbuseServiceID: 42, description: 'Peer Specialist - Individual(308 Dollars)' },
        { luSubstanceAbuseServiceID: 43, description: 'Peer Specialist - Group(308 Dollars)' },
        { luSubstanceAbuseServiceID: 44, description: 'Supported Employment(308 Dollars)' },
        { luSubstanceAbuseServiceID: 45, description: 'Individual Rehabilitative Services(308 Dollars)' },
        { luSubstanceAbuseServiceID: 46, description: 'SA Rehabilitative Services - Group' },
        { luSubstanceAbuseServiceID: 47, description: 'Crisis Clinical Response Services(CCRS)' },
        { luSubstanceAbuseServiceID: 48, description: 'Jail-based Treatment and Re-entry' }
    ]
}

function getSchoolAttendanceList() {
    return [
        { luSchoolAttendanceID: 0, description: 'Not Applicable (not a school-aged client)' },
        { luSchoolAttendanceID: 1, description: 'Yes' },
        { luSchoolAttendanceID: 2, description: 'No' },
        { luSchoolAttendanceID: 3, description: 'Not Applicable (school-aged client, but has been on summer vacation)' }
    ]
}

function getSchoolSuspensionList() {
    return [
        { luSchoolSuspensionID: 0, description: 'Not Applicable (Not a school-aged client)' },
        { luSchoolSuspensionID: 1, description: 'Yes (Suspended)' },
        { luSchoolSuspensionID: 2, description: 'No (Not Suspended)' },
        { luSchoolSuspensionID: 9, description: 'Unknown' }
    ]
}

function getSocialConectednessList() {
    return [
        { socialConnectednessID: 1, description: 'Yes - Attended 1 or More Meeting(s) in Past Month' },
        { socialConnectednessID: 2, description: 'No - No Attendance in Past Month' }
    ]
}

function getTestingResultsList() {
    return [
        { luTestingResultsID: 1, description: 'Positive' },
        { luTestingResultsID: 2, description: 'Negative' },
        { luTestingResultsID: 3, description: 'Unknown' }
    ]
}

function getTransactionStatusList() {
    return [
        { luTransactionStatusID: 1, description: 'Admission' },
        { luTransactionStatusID: 2, description: 'Discharge' },
        { luTransactionStatusID: 3, description: 'Admission/Discharge' },
        { luTransactionStatusID: 4, description: 'Interim' }
    ]
}

function getTSHStatusList() {
    return [
        { luTSHStatusID: 1, description: 'On Waitlist' },
        { luTSHStatusID: 2, description: 'Removed From Waitlist - Automatic' },
        { luTSHStatusID: 3, description: 'Removed From Waitlist - Manual' },
        { luTSHStatusID: 4, description: 'In Treatment' },
        { luTSHStatusID: 5, description: 'Removed From Treatment' },
        { luTSHStatusID: 6, description: 'Completed Treatment' }
    ]
}

function getTSHRemovalReasons() {
    return [
        { luTSHRemovalReasonID: 1, description: 'Accepted into Residential Program' },
        { luTSHRemovalReasonID: 2, description: 'No Longer Needs Residential Treatment' },
        { luTSHRemovalReasonID: 3, description: 'Dropped - out' },
        { luTSHRemovalReasonID: 4, description: 'Moved' },
        { luTSHRemovalReasonID: 5, description: 'Incarcerated' },
        { luTSHRemovalReasonID: 6, description: 'Lost Contact/No Show' },
        { luTSHRemovalReasonID: 7, description: 'Hospitalized' },
        { luTSHRemovalReasonID: 8, description: 'Died' },
        { luTSHRemovalReasonID: 9, description: 'Other' },
        { luTSHRemovalReasonID: 10, description: 'Terminated by Provider' }
    ]
}

function getVeteranStatusList() {
    return [
        { luVeteranStatusID: 1, description: 'Veteran:  Non-Combat' },
        { luVeteranStatusID: 2, description: 'Not a Veteran' },
        { luVeteranStatusID: 3, description: 'Veteran:  Combat' },
        { luVeteranStatusID: 9, description: 'Unknown' }
    ]
}

function getWaitlistReferalSourceList() {
    return [
        { luWaitListReferalSourceID: 1, description: 'DOC' },
        { luWaitListReferalSourceID: 2, description: 'DFS' },
        { luWaitListReferalSourceID: 3, description: 'CMHC (include name)' },
        { luWaitListReferalSourceID: 4, description: 'SUD Provider (include name)' },
        { luWaitListReferalSourceID: 5, description: 'Self-referral' },
        { luWaitListReferalSourceID: 6, description: 'Court' },
        { luWaitListReferalSourceID: 7, description: 'District Attorney' },
        { luWaitListReferalSourceID: 8, description: 'Inpatient Facility (include name)' },
        { luWaitListReferalSourceID: 9, description: 'Wyoming State Hospital' },
        { luWaitListReferalSourceID: 10, description: 'Other Inpatient Facility' },
        { luWaitListReferalSourceID: 11, description: 'Drug Court' },
        { luWaitListReferalSourceID: 12, description: 'Private Provider' },
        { luWaitListReferalSourceID: 99, description: 'Unknown' },
    ]
}

function getInvoiceCategoryList() {
    return [
        { id:2, luCategoryId: 2, luSubtypeId: 1, description: 'Outpatient Quality of Life Payment' },
        { id: 10, luCategoryId: 10, luSubtypeId: 1, description: 'Outpatient State Funded Payment' },
        { id: 6, luCategoryId: 6, luSubtypeId: 2, description: 'Residential Quality of Life Payment' },
        { id: 5, luCategoryId: 5, luSubtypeId: 2, description: 'Residential Monthly Payment' }
    ]
}

function getInvoiceTypeList() {
    return [
        { luInvoiceTypeId: 0, description: 'General' },
        { luInvoiceTypeId: 1, description: 'Initial' },
        { luInvoiceTypeId: 2, description: 'Addendum' },
        //{ luInvoiceTypeId: 3, description: 'Exception' }
    ]
}

function getAccreditationTypes() {
    return [
        {accreditationTypeId: 1, description: "ACQR"},
        {accreditationTypeId: 2, description: "Expiring"},
        {accreditationTypeId: 3, description: "QIP"},
        {accreditationTypeId: 4, description: "Survey"},
    ]
}

export default {
    getICD10List: getICD10List,
    getYesNoList: getYesNoList,
    getYesNoUnknownList: getYesNoUnknownList,
    getCities: getCities,
    getStates: getStates,
    getCountries: getCountries,
    getCounties: getCounties,
    getGenderList: getGenderList,
    getGenderByChar: getGenderByChar,
    getGenderIdentityList: getGenderIdentityList,
    getRoles: getRoles,
    getFiscalYears: getFiscalYears,
    getFiscalYearByDate: getFiscalYearByDate,
    getFiscalYearsBetween: getFiscalYearsBetween,
    getCalendarYearList: getCalendarYearList,
    getMonthList: getMonthList,
    getContractPeriodList: getContractPeriodList,

    getActualPaymentSourceList: getActualPaymentSourceList,
    getAgencyTypeList: getAgencyTypeList,
    getBedCategoryList: getBedCategoryList,
    getBedTypeList: getBedTypeList,
    getCarfFormTypeList: getCarfFormTypeList,
    getCigaretteUseList: getCigaretteUseList,
    getContractFormList: getContractFormList,
    getDeathReportCauseOfDeathList: getDeathReportCauseOfDeathList,
    getDeathReportClientStatusList: getDeathReportClientStatusList,
    getDischargeStatusList: getDischargeStatusList,
    getDlaResponseList: getDlaResponseList,
    getDocumentGroupList: getDocumentGroupList,
    getEducationList: getEducationList,
    getEmailTypeList: getEmailTypeList,
    getEmploymentList: getEmploymentList,
    getEmploymentListWSH: getEmploymentListWSH,
    getEpisodeStatusTypeList: getEpisodeStatusTypeList,
    getEpisodeTypeList: getEpisodeTypeList,
    getFacilityReferralList: getFacilityReferralList,
    getFundingSourceList: getFundingSourceList,
    getHispanicOriginList: getHispanicOriginList,
    getLevelOfCareList: getLevelOfCareList,
    getLevelOfCareListResidential: getLevelOfCareListResidential,
    getLocationOfServiceList: getLocationOfServiceList,
    getLocusHarmList: getLocusHarmList,
    getLocusEngagementList: getLocusEngagementList,
    getLocusFunctionalList: getLocusFunctionalList,
    getLocusCoMorbidityList: getLocusCoMorbidityList,
    getLocusRecommendedCareList: getLocusRecommendedCareList,
    getLocusStressList: getLocusStressList,
    getLocusSupportList: getLocusSupportList,
    getLocusTreatmentList: getLocusTreatmentList,
    getMaritalStatusList: getMaritalStatusList,
    getMediaFolderRootList: getMediaFolderRootList,
    getMHArrestList: getMHArrestList,
    getMHLegalStatusList: getMHLegalStatusList,
    getMHPopulationList: getMHPopulationList,
    getMHServiceList_Current: getMHServiceList_Current,
    getMHServiceList: getMHServiceList,
    getMISStandardReportsList: getMISStandardReportsList,
    getNoSSNReasonList: getNoSSNReasonList,
    getOriginationTypes: getOriginationTypes,
    getParoleList: getParoleList,
    getPresentingProblems: getPresentingProblems,
    getPrimaryIncomeSourceList: getPrimaryIncomeSourceList,
    getPriorityPopulationReportList: getPriorityPopulationReportList,
    getPriorityPopulations: getPriorityPopulations,
    getPriorityPopulationsOther: getPriorityPopulationsOther,
    getProbationUnderSupervisionList: getProbationUnderSupervisionList,
    getProbationNotUnderSupervisionList: getProbationNotUnderSupervisionList,
    getProcessList: getProcessList,
    getProcessStatusList: getProcessStatusList,
    getQOLInvoiceCategoryList: getQOLInvoiceCategoryList,
    getRaces: getRaces,
    getReasonForTransferList: getReasonForTransferList,
    getResidenceList: getResidenceList,
    getReferalSourceList: getReferalSourceList,
    getSADrugProblemList: getSADrugProblemList,
    getSAFemaleList: getSAFemaleList,
    getSAFrequencyOfUseList: getSAFrequencyOfUseList,
    getSALevelOfCareList_Current: getSALevelOfCareList_Current,
    getSANumberOfArrestsList: getSANumberOfArrestsList,
    getSANumberOfChildrenList: getSANumberOfChildrenList,
    getSAPrimaryServiceCategoryList_Current: getSAPrimaryServiceCategoryList_Current,
    getSARouteOfAdministration: getSARouteOfAdministration,
    getSAServiceList: getSAServiceList,
    getSAServiceList_Current: getSAServiceList_Current,
    getSchoolAttendanceList: getSchoolAttendanceList,
    getSchoolSuspensionList: getSchoolSuspensionList,
    getSentinelEventIncidentTypeList: getSentinelEventIncidentTypeList,
    getSexualOrientationList: getSexualOrientationList,
    getSocialConectednessList: getSocialConectednessList,
    getTestingResultsList: getTestingResultsList,
    getTransactionStatusList: getTransactionStatusList,
    getTSHStatusList: getTSHStatusList,
    getTSHRemovalReasons: getTSHRemovalReasons,
    getVeteranStatusList: getVeteranStatusList,
    getWaitlistReferalSourceList: getWaitlistReferalSourceList,

    getYesNoUnknownDescription: getYesNoUnknownDescription,
    getCityDescription: getCityDescription,
    getStateDescription: getStateDescription,
    getCountryDescription: getCountryDescription,
    getCountyDescription: getCountyDescription,

    getLuFiscalMonth: getLuFiscalMonth,
    getLuFiscalMonthByDate: getLuFiscalMonthByDate,
    getLuFiscalMonthsTake: getLuFiscalMonthsTake,
    getLuFiscalMonthsByFiscalYear: getLuFiscalMonthsByFiscalYear,

    getServiceMatrix: getServiceMatrix,

    getInvoiceCategoryList: getInvoiceCategoryList,
    getInvoiceTypeList: getInvoiceTypeList,
    getLuTSHStatusById: getLuTSHStatusById,
    getAccreditationTypes: getAccreditationTypes
}
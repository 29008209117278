var path = 'api/agency/'

function getAgencies(http) {
    return http.get(path).then(response => {
        return response
    }).catch(error => {
        console.log('error retrieving agencies')
        throw error
    })
}

function getAgenciesByIds(http, ids) {
    return http.get(`${path}get-by-ids/${ids}`);
}

function getByAgencyGroupId(http, agencyGroupId) {
    return http.get(`${path}get-by-agencygroupid/${agencyGroupId}`)
}

function getCrisisAgencies(http) {
    return http.get(`${path}get-crisis-agencies`)
}

function getRahsAgencies(http) {
    console.log('getRahsAgencies')
    return http.get(`${path}getrahsagencies`).then(response => {
        //console.log(response.data)
        return response
    }).catch(error => {
        console.log('error retrieving rahs agencies')
        throw error
    })
}

function getRahsAgenciesWithAvailableBeds(http) {
    console.log('getRahsAgenciesWithAvailableBeds')
    return http.get(`${path}getRahsAgenciesWithAvailableBeds`).then(response => {
        //console.log(response.data)
        return response
    }).catch(error => {
        console.log('error retrieving rahs agencies')
        throw error
    })
}

// eslint-disable-next-line
function getRahsAgenciesClientNotOnWaitlist(http, cl_wcisclientid) {
    return http.get(`${path}get-rahs-agencies-client-not-on-waitlist/${cl_wcisclientid}`)
}

function getAgencyMergeInfo(http, agencyGroupId, dischargeDate) {
    return http.get(`${path}get-agency-merge-info/${agencyGroupId}/${dischargeDate}`)
}

function getLevelsOfCareByAgencyGroupId(http, agencyGroupId) {
    return http.get(`${path}get-levels-of-care-by-agencygroupid/${agencyGroupId}`)
}

function save(http, dto) {
    return http.post(path, dto)
}

function saveDescriptionsOnly(http, array) {
    return http.post(`${path}descriptions-only`, array)
}

export default {
    getAgencies: getAgencies,
    getAgenciesByIds: getAgenciesByIds,
    getByAgencyGroupId: getByAgencyGroupId,
    getRahsAgencies: getRahsAgencies,
    getCrisisAgencies: getCrisisAgencies,
    getRahsAgenciesWithAvailableBeds: getRahsAgenciesWithAvailableBeds,
    // eslint-disable-next-line
    getRahsAgenciesClientNotOnWaitlist, getRahsAgenciesClientNotOnWaitlist,
    getAgencyMergeInfo: getAgencyMergeInfo,
    getLevelsOfCareByAgencyGroupId : getLevelsOfCareByAgencyGroupId,
    save: save,
    saveDescriptionsOnly: saveDescriptionsOnly
}
<script>
import utilService from '@/services/util/utilService';

export default{
    data: () => ({
        childDeletedItemsArray: [],
        idsToRestore: [],
        deletedItemHeaders: [{text: "Created Date", value: "createDate"}, {text: "Date Of Last Service", value: "dateOfLastService"}, {text: "Deleted Date", value: "deletedDate"}, {text: "Type", value: "episodeFormTypeDescription"}, {text: "Agency", value: "agencyName"}, {text: "Agency Code", value: "agencyCode"}, {text: "Restore", value: "restore"}],
        searchDeletedHistory: "",
        title: "",
    }),
    methods: {
        finish() {
            this.$emit("close-restore-dialog", {itemsToRestore: this.idsToRestore, subject: this.parentDeletedItems.subject});
            this.idsToRestore = new Array();
        },
        onHandlerEvent() {
            this.childDeletedItemsArray = this.parentDeletedItems.deletedItems ? JSON.parse(JSON.stringify(this.parentDeletedItems.deletedItems)) : [];
            this.title = this.parentDeletedItems.subject;
        },
        addItemToRestoreArray(item) {
            if (this.parentDeletedItems.subject === "MIS") {
                this.idsToRestore.push(item.fmT_MISID);
                this.childDeletedItemsArray.splice(this.childDeletedItemsArray.findIndex(x => x.fmT_MISID === item.fmT_MISID), 1);
                return;
            }
            else if (this.parentDeletedItems.subject === "ESR") {
                this.idsToRestore.push({eSRID: item.id, luAgencyTypeId: item.luAgencyTypeId });
                this.childDeletedItemsArray.splice(this.childDeletedItemsArray.findIndex(x => x.id === item.id), 1);
                return;
            }
            else if (this.parentDeletedItems.subject === "Crisis") {
                this.idsToRestore.push(item.fmT_EDBSID);
                this.childDeletedItemsArray.splice(this.childDeletedItemsArray.findIndex(x => x.fmT_EDBSID === item.fmT_EDBSID), 1);
                return;
            }
            else if (this.parentDeletedItems.subject === "RAHS") {
                this.idsToRestore.push({episodeId: item.tsH_EpisodeID, requestId: item.tsH_RequestID});
                this.childDeletedItemsArray.splice(this.childDeletedItemsArray.findIndex(x => x.tsH_RequestID === item.tsH_RequestID), 1);
                return;
            }
            else {
                return;
            }
        },
        checkIfFormIsPastLockDate(dateItem, isRahs) {
            if (isRahs) return false

            let fiscalYearId = this.fyEnumList.find(x => new Date(x.startDate) <= new Date(dateItem) && new Date(x.endDate) >= new Date(dateItem)).id; 
            let lockConfig = this.fyLockConfig.find(x => x.luFiscalYearID === fiscalYearId);

            if (lockConfig && new Date() > new Date(lockConfig.lockDate)) return true
            if (!lockConfig) return true
            return false
        },
    },
    props: {
            parentDeletedItems: Object,
             dialog: {
                type: Boolean,
                required: true,
            },
            fyLockConfig: {
                type: Array,
                required: true,
            },
            fyEnumList: {
                type: Array,
                required: true,
            }

    },
    watch: {
        $props: {
            handler() {
                this.onHandlerEvent();
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        
    },
    created() {
        
        
    }
}
</script>



<template>
    <v-dialog :value="dialog" @click:outside="finish" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="1200" max-height="1200">
        <v-card>
            <v-toolbar color="orange lighten-4" dark class="black--text">{{`Restore Deleted ${this.title} Items`}}</v-toolbar>
            <v-spacer></v-spacer>
            <v-card-title>
                <v-text-field
                    v-model="searchDeletedHistory"
                    append-icon="mdi-magnify"
                    label="Search Deleted Items"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text class="mt-6">
                <v-data-table dense :headers="deletedItemHeaders" :items="childDeletedItemsArray" :search="searchDeletedHistory" item-key="id" class="elevation-1">
                    <template v-slot:item="row">
                        <tr>
                            <td>{{row.item.createDate}}</td>
                            <td>{{row.item.dateOfLastService}}</td>
                            <td>{{row.item.deletedDate}}</td>
                            <td>{{row.item.episodeFormTypeDescription ? row.item.episodeFormTypeDescription : row.item.formType}}</td>
                            <td>{{row.item.agencyName}}</td>
                            <td>{{row.item.agencyCode}}</td>
                            <td v-if="!checkIfFormIsPastLockDate(row.item.createDate, row.item.tshStatus ? true : false)">
                                <v-btn dark x-small color="deep-purple accent-4" @click="addItemToRestoreArray(row.item)">
                                    <v-icon>mdi-recycle</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template> 
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="finish">Finish</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
import loggingService from '@/services/util/loggingService'

var serviceName = 'agencyGroupService'
var path = '/api/agencygroup/'
var isDebug = false

function get(http, id) {
    return http.get(path + id)
}

//This function is called with any number of these three arguments. We need optional parameters.
//Set default values so that we don't have to do a bunch of undefined checks.
async function getAgencyGroups(http, user = 0, id = 0) {
    if (user && user.agencyGroupId) {
        id = user.agencyGroupId;
    }
    if (id) {
        return this.get(http, id)
    } 
    else {
        return await http.get(path)
    } 
}

async function getAgencyGroupsIncludeWSH(http, user = 0, id = 0) {

    if (user.agencyGroupId) id = user.agencyGroupId

    if (id) {
        return this.get(http, id)
    }
    else return await http.get(path + 'get-list-include-wsh')
}

function getAll(http) {
    loggingService.log(isDebug, serviceName, 'get all')
    console.log('get list all')
    return http.get(path + 'getlistall')
}

function getAllIgnoreUser(http) {
    return http.get(path + 'get-all-ignore-user')
}

function save(http, dto) {
    loggingService.log(isDebug, serviceName, 'save agency group')
    return http.post(path, dto)
}

function merge(http, dto) {
    return http.post(path + 'merge', dto)
}

export default {
    get: get,
    getAgencyGroups: getAgencyGroups,
    getAgencyGroupsIncludeWSH: getAgencyGroupsIncludeWSH,
    getAll: getAll,
    getAllIgnoreUser: getAllIgnoreUser,
    save: save,
    merge: merge
}
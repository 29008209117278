<template>
    <v-dialog :value="show"
              max-width="800px">
        <v-card>
            <v-card-title class="primary white--text">
                Session Expire Warning
            </v-card-title>

            <v-card-text>
                Your session is about to expire due to inactivity.
                <br /><br />
                You will be automatically logged out in <span id="timeout-seconds">{{time / 1000}}</span> seconds.
            </v-card-text>

            <v-card-actions>
                <v-btn color="primary" @click="stayLoggedIn()">Stay Logged In</v-btn>
                <v-btn color="primary" @click="logout()">Logout</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import utilService from '@/services/util/utilService'

export default {
    name: 'timeout-modal',
    props: {
        auth: { default: null },
        authenticated: { default: false }
    },
    data: function () {
        return {
            show: false,
            time: 60000,
            checkIdleId: null,
            timeoutId: null,
            timeoutCheck: 60000
        }
    },
    created () {
        this.startTimeoutCheck()
    },
    methods: {
        startTimeoutCheck () {
            this.checkIdleId = setInterval(() => {
                if (!this.$auth.isAuthenticated) {
                    console.log('Auth0 session expired')
                    this.stayLoggedIn()
                    return
                }
                if (!utilService.isAppIdle()) return
                clearInterval(this.checkIdleId)
                this.startTimeout()
            }, 10000)
        },
        startTimeout () {
            this.time = 60000
            this.show = true
            this.timeOutId = setInterval(() => {
                this.time -= 1000
                if (this.time < 1) {
                    if (utilService.isAppIdle()) {
                        this.logout()
                    } else {
                        this.stayLoggedIn()
                    }
                }
            }, 1000)
        },
        logout () {
            clearInterval(this.checkIdleId)
            clearInterval(this.timeOutId)
            this.show = false
            this.$auth.logout({
                returnTo: window.location.origin
            })
        },
        stayLoggedIn () {
            clearInterval(this.checkIdleId)
            clearInterval(this.timeOutId)
            this.startTimeoutCheck()
            this.show = false
            this.$auth.loginWithPopup()
        }
    }
}
</script>

<style>
    #m-t-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        justify-content: center;
        align-items: top;
        transition: opacity .3s ease;
    }

    #m-t-wrapper {
        display: block;
        margin-top: 100px;
        margin-bottom: 60px;
    }

    #m-t-container {
        min-width: 500px;
        max-width: 900px;
        margin: 0px auto;
        padding: 10px 15px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header {
        padding-top: 5px;
    }

    .modal-body {
        margin: 10px;
        padding: 0px;
        max-height: 71vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .modal-footer {
        padding-bottom: 5px;
    }

    .modal-button {
        float: right
    }

    .modal-enter,
    .modal-leave {
        opacity: 0
    }

        .modal-enter .modal-container,
        .modal-leave .modal-container {
            transform: scale(1.1)
        }

    #timeout-seconds {
        font-size: 18px;
        color: red;
    }
</style>

<template>
    <v-snackbar v-model="snackLocal"
                :timeout="3000"
                :color="snackColor">
        {{ snackText }}
        <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs"
                   text
                   @click="snackLocal = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        props: {
            snack: Boolean,
            snackColor: String,
            snackText: String,
        },
        //data: () => ({
        //    snackData: null
        //}),
        //data: function () {
        //    return {
        //        snackData: this.snack
        //    }
        //},
        //created() {
        //    this.snackData = this.snack
        //},
        computed: {
            snackLocal: {
                get: function () {
                    return this.snack
                },
                set: function (value) {
                    this.$emit('snackchange', value)
                }
            }
        }
    }
</script>
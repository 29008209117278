import store from '@/store/store'
import Vue from 'vue'

var path = 'api/report/'

function getList(http) {
    return http.get(path + 'get-list')
}

function getNativeReportOptions(http) {
    return http.get(path + 'get-native-report-options');
}

function getFYDLA20ReportData(http, searchDto) {
    return http.put(path + 'get-fy-dla-data', searchDto);
}

function generateIndividualDLA20PDF(http, clientId, stringifiedElement) {
    let bodyToSend = {
        "clientId": clientId,
        "stringifiedHTML": stringifiedElement
    }
    return http.put(path + 'generate-individual-dla-pdf', bodyToSend, { responseType: 'arraybuffer' });
}

function generateDLA20PDF(http, fiscalYearId, agencyGroupName, fiscalMonthDescription, stringifiedElement) {
    let bodyToSend = {
        "agencyGroupName": agencyGroupName,
        "luFiscalYearId": fiscalYearId,
        "fiscalMonthDescription": fiscalMonthDescription,
        "stringifiedHTML": stringifiedElement
    }
    return http.put(path + 'generate-dla-pdf', bodyToSend, { responseType: 'arraybuffer' });
}

function getFYQIReportData(http, searchDto) {
    return http.put(path + 'get-fy-qi-data', searchDto);
}

function getClientSummaryIndicatorsAndServices(http, clientSummarySearchBody) {
    return http.put(path + "get-client-summary-indicators-and-services", clientSummarySearchBody);
}

function generateFYQIPDF(http, agencyGroupName, luFiscalYearId, stringifiedElement) {
    let bodyToSend = {
        "agencyGroupName": agencyGroupName,
        "luFiscalYearId": luFiscalYearId,
        "notes": "Yellow highlighting indicates that the target was not met.",
        "stringifiedHTML": stringifiedElement
    }
    return http.put(path + 'generate-fy-qi-pdf', bodyToSend, { responseType: 'arraybuffer' });
}

function generateJIIPDF(http, agencyGroupName, luFiscalYearId, stringifiedElements, startDate, endDate) {
    let bodyToSend = {
        "agencyGroupName": agencyGroupName,
        "luFiscalYearId": luFiscalYearId,
        "notes": "",
        "stringifiedHTML": stringifiedElements,
        "startDate": startDate,
        "endDate": endDate
    }
    return http.put(path + 'generate-jii-pdf', bodyToSend, { responseType: 'arraybuffer' });
}

function generateClientSummaryServicesPDF(http, stringifiedElements, startDate, endDate, clientOverview) {
    let bodyToSend = {
        "stringifiedHTML": stringifiedElements,
        "startDate": startDate,
        "endDate": endDate,
        "age": clientOverview.age,
        "gender": clientOverview.gender,
        "employment": clientOverview.employment,
        "income": clientOverview.income
    }
    return http.put(path + 'generate-client-summary-services-pdf', bodyToSend, { responseType: 'arraybuffer' });
}

function getLoginReportData(http, searchDto) {
    return http.put(path + 'get-login-report-data', searchDto);
}

function getJusticeInvolvedInitData(http, searchDto) {
    return http.put(path + 'get-justice-involved-init-report-data', searchDto);
}

function priorityPopulationWithTargets(http, searchDto) {
    return http.post(path + 'priority-population-with-targets', searchDto)
}

function priorityPopulationWithTargetsPdf(http, wrapper) {
    return http.post(path + 'priority-population-with-targets-pdf', wrapper, { responseType: 'arraybuffer' });
}

function invoicedESRs(http, searchDto) {
    return http.post(path + 'invoiced-esrs', searchDto)
}

function crisisServices(http, searchDto) {
    return http.post(path + 'crisis-services', searchDto)
}

function crisisServicesPDF(http, wrapper) {
    return http.post(path + 'crisis-services-pdf', wrapper, { responseType: 'arraybuffer' })
}

function misRaw(http, searchDto) {
    return http.post(path + 'mis-raw', searchDto)
}

function primaryResidentialWomen(http, searchDto) {
    return http.post(path + 'primary-residential-women', searchDto)
}

function primaryResidentialWomenPDF(http, wrapper) {
    return http.post(path + 'primary-residential-women-pdf', wrapper, { responseType: 'arraybuffer' })
}

function dischargeStatus(http, searchDto) {
    return http.post(path + 'discharge-status', searchDto)
}

function dischargeStatusPDF(http, wrapper) {
    return http.post(path + 'discharge-status-pdf', wrapper, { responseType: 'arraybuffer' })
}

function accessToCare(http, searchDto) {
    return http.post(path + 'access-to-care', searchDto)
}

function accessToCarePDF(http, wrapper) {
    return http.post(path + 'access-to-care-pdf', wrapper, { responseType: 'arraybuffer' })
}

function getAccessToCareOutlierData(http, searchDto) {
    return http.put(path + 'get-access-to-care-outliers-data', searchDto);
}

function generateAccessToCareOutliersPDF(http, agencyGroupName, luFiscalYearId, agencyName, agencyType, waitingTime, grouping, stringifiedElements ) {
    let bodyToSend = {
        "agencyGroupName": agencyGroupName,
        "agencyName": agencyName,
        "agencyType": agencyType,
        "waitingTimeInDays": waitingTime,
        "dayGrouping": grouping,
        "luFiscalYearId": luFiscalYearId,
        "stringifiedHTML": stringifiedElements,
    }
    return http.put(path + 'generate-access-to-care-outliers-pdf', bodyToSend, { responseType: 'arraybuffer' })
}

function treatmentComplete(http, searchDto) {
    return http.post(path + 'treatment-complete', searchDto)
}

function treatmentCompletePDF(http, wrapper) {
    return http.post(path + 'treatment-complete-pdf', wrapper, { responseType: 'arraybuffer' })
}

function priorityPopulationServed(http, searchDto) {
    return http.post(path + 'priority-population-served', searchDto)
}

function priorityPopulationServedPDF(http, wrapper) {
    return http.post(path + 'priority-population-served-pdf', wrapper, { responseType: 'arraybuffer' });
}

function monthlyAccuracyContractMonitoring(http, searchDto) {
    return http.post(path + 'monthly-accuracy-contract-monitoring', searchDto)
}

function monthlyAccuracyContractMonitoringPDF(http, wrapper) {
    return http.post(path + 'monthly-accuracy-contract-monitoring-pdf', wrapper, { responseType: 'arraybuffer' });
}

function rahsCapacityAdmin(http, searchDto) {
    return http.post(path + 'rahs-capacity-admin', searchDto)
}

function rahsCapacityAdminPDF(http, wrapper) {
    return http.post(path + 'rahs-capacity-admin-pdf', wrapper, { responseType: 'arraybuffer' });
}

function ssnMonitoring(http, searchDto) {
    return http.post(path + 'ssn-monitoring', searchDto)
}

function ssnMonitoringPDF(http, wrapper) {
    return http.post(path + 'ssn-monitoring-pdf', wrapper, { responseType: 'arraybuffer' });
}

function ssnMonitoringClientLevel(http, searchDto) {
    return http.post(path + 'ssn-monitoring-client-level', searchDto)
}

function ssnMonitoringClientLevelPDF(http, wrapper) {
    return http.post(path + 'ssn-monitoring-client-level-pdf', wrapper, { responseType: 'arraybuffer' });
}

function pwwdcMoe(http, searchDto) {
    return http.post(path + 'pwwdc-moe', searchDto)
}

function pwwdcMoePDF(http, wrapper) {
    return http.post(path + 'pwwdc-moe-pdf', wrapper, { responseType: 'arraybuffer' });
}

function residentialLengthOfStay(http, searchDto) {
    return http.post(path + 'residential-length-of-stay', searchDto)
}

function residentialLengthOfStayPDF(http, wrapper) {
    return http.post(path + 'residential-length-of-stay-pdf', wrapper, { responseType: 'arraybuffer' })
}

function clientsAndHoursServed(http, searchDto) {
    return http.post(path + 'clients-and-hours-served', searchDto)
}

function clientsAndHoursServedPDF(http, wrapper) {
    return http.post(path + 'clients-and-hours-served-pdf', wrapper, { responseType: 'arraybuffer' })
}

function esrRaw(http, searchDto) {
    return http.post(path + 'esr-raw', searchDto)
}

function sorServices(http, searchDto) {
    return http.post(path + 'sor-services', searchDto)
}

function sorServicesPDF(http, wrapper) {
    return http.post(path + 'sor-services-pdf', wrapper, { responseType: 'arraybuffer' })
}

function esrStandard(http, searchDto) {
    return http.post(path + 'esr-standard', searchDto)
}

function esrStandardPDF(http, wrapper) {
    return http.post(path + 'esr-standard-pdf', wrapper, { responseType: 'arraybuffer' })
}

function misStandardClientDemographics(http, searchDto) {
    return http.post(path + 'mis-standard-client-demographics', searchDto)
}

function misStandardClientDemographicsWSH(http, searchDto) {
    return http.post(path + 'mis-standard-client-demographics-wsh', searchDto)
}

function misStandardKeyIndicators(http, searchDto) {
    return http.post(path + 'mis-standard-key-indicators', searchDto)
}

function misStandardKeyIndicatorsWSH(http, searchDto) {
    return http.post(path + 'mis-standard-key-indicators-wsh', searchDto)
}

function misStandardClinical(http, searchDto) {
    return http.post(path + 'mis-standard-clinical', searchDto)
}

function misStandardClinicalWSH(http, searchDto) {
    return http.post(path + 'mis-standard-clinical-wsh', searchDto)
}

function misStandardClientDemographicsPDF(http, wrapper) {
    return http.post(path + 'mis-standard-client-demographics-pdf', wrapper, { responseType: 'arraybuffer' })
}

function misStandardClientDemographicsWSHPDF(http, wrapper) {
    return http.post(path + 'mis-standard-client-demographics-wsh-pdf', wrapper, { responseType: 'arraybuffer' })
}

function misStandardKeyIndicatorsPDF(http, wrapper) {
    return http.post(path + 'mis-standard-key-indicators-pdf', wrapper, { responseType: 'arraybuffer' })
}

function misStandardKeyIndicatorsWSHPDF(http, wrapper) {
    return http.post(path + 'mis-standard-key-indicators-wsh-pdf', wrapper, { responseType: 'arraybuffer' })
}

function misStandardClinicalPDF(http, wrapper) {
    return http.post(path + 'mis-standard-clinical-pdf', wrapper, { responseType: 'arraybuffer' })
}

function misStandardClinicalWSHPDF(http, wrapper) {
    return http.post(path + 'mis-standard-clinical-wsh-pdf', wrapper, { responseType: 'arraybuffer' })
}

function esrServices(http, searchDto) {
    return http.post(path + 'esr-services', searchDto)
}

function esrServicesPDF(http, wrapper) {
    return http.post(path + 'esr-services-pdf', wrapper, { responseType: 'arraybuffer' })
}

function esrServicesMonthlyBreakdown(http, searchDto) {
    return http.post(path + 'esr-services-monthly-breakdown', searchDto)
}

function esrServicesMonthlyBreakdownPDF(http, wrapper) {
    return http.post(path + 'esr-services-monthly-breakdown-pdf', wrapper, { responseType: 'arraybuffer' })
}

function systemDischargeEpisodes(http, searchDto) {
    return http.post(path + 'system-discharge-episodes', searchDto)
}

function systemDischargeEpisodesPDF(http, wrapper) {
    return http.post(path + 'system-discharge-episodes-pdf', wrapper, { responseType: 'arraybuffer' })
}

function rahs_ClientStatus(http, searchDto) {
    return http.post(path + 'rahs-client-status', searchDto)
}

function rahs_ClientStatusPDF(http, wrapper) {
    return http.post(path + 'rahs-client-status-pdf', wrapper, { responseType: 'arraybuffer' })
}

function rahs_Timeliness(http, searchDto) {
    return http.post(path + 'rahs-timeliness', searchDto)
}

function rahs_TimelinessPDF(http, wrapper) {
    return http.post(path + 'rahs-timeliness-pdf', wrapper, { responseType: 'arraybuffer' })
}

function servicesByFundingSource(http, searchDto) {
    return http.post(path + 'services-by-funding-source', searchDto)
}

function servicesByFundingSourcePDF(http, wrapper) {
    return http.post(path + 'services-by-funding-source-pdf', wrapper, { responseType: 'arraybuffer' })
}

function dla20AgencyAggregate(http, searchDto) {
    return http.post(path + 'dla20-agency-aggregate', searchDto)
}

function dla20AgencyAggregatePDF(http, wrapper) {
    return http.post(path + 'dla20-agency-aggregate-pdf', wrapper, { responseType: 'arraybuffer' })
}

function dla20AgencyAggregateByAdmitDate(http, searchDto) {
    return http.post(path + 'dla20-agency-aggregate-by-admit-date', searchDto)
}

function dla20AgencyAggregateByAdmitDatePDF(http, wrapper) {
    return http.post(path + 'dla20-agency-aggregate-by-admit-date-pdf', wrapper, { responseType: 'arraybuffer' })
}

function dla20ParticipantsByAgency(http, searchDto) {
    return http.post(path + 'dla20-participants-by-agency', searchDto)
}

function dla20ParticipantsByAgencyPDF(http, wrapper) {
    return http.post(path + 'dla20-participants-by-agency-pdf', wrapper, { responseType: 'arraybuffer' })
}

function dla20ParticipantsParticipantSummary(http, searchDto) {
    return http.post(path + 'dla20-participant-summary', searchDto)
}

function dla20ParticipantsParticipantSummaryPDF(http, wrapper) {
    return http.post(path + 'dla20-participant-summary-pdf', wrapper, { responseType: 'arraybuffer' })
}

function dla20AgencyAggregateByCategory(http, searchDto) {
    return http.post(path + 'dla20-agency-aggregate-by-category', searchDto)
}

function dla20AgencyAggregateByCategoryPDF(http, wrapper) {
    return http.post(path + 'dla20-agency-aggregate-by-category-pdf', wrapper, { responseType: 'arraybuffer' })
}

function episodeReport(http, searchDto) {
    return http.post(path + 'episode-report', searchDto)
}

function episodeReportPDF(http, wrapper) {
    return http.post(path + 'episode-report-pdf', wrapper, { responseType: 'arraybuffer' })
}

function episodeAggregateReport(http, searchDto) {
    return http.post(path + 'episode-aggregate-report', searchDto)
}

function episodeAggregateReportPDF(http, wrapper) {
    return http.post(path + 'episode-aggregate-report-pdf', wrapper, { responseType: 'arraybuffer' })
}

function rahs_CapacityAdmin(http, searchDto) {
    return http.post(path + 'rahs-capacity-admin-report', searchDto)
}

function rahs_CapacityAdminPDF(http, wrapper) {
    return http.post(path + 'rahs-capacity-admin-report-pdf', wrapper, { responseType: 'arraybuffer' })
}

function womenServedDrawDown(http, searchDto) {
    return http.post(path + 'women-served-draw-down-report', searchDto)
}

function womenServedDrawDownPDF(http, wrapper) {
    return http.post(path + 'women-served-draw-down-report-pdf', wrapper, { responseType: 'arraybuffer' })
}


function runMonthlyProviderReports(http) {
    return http.post(path + 'run-monthly-provider-reports')
}

function test_qi(http) {
    return http.post(path + 'test-qi', {}, { responseType: 'arraybuffer' })
}

function setLoginRecord(user) {
    const http = Vue.prototype.$http;
    let userDto = store.state.auth.wybhdsUserDto;
    let reportInstance = {
        loginReportId: 0,
        userId: userDto.userId,
        username: user.name,
        createUser: user.name
    };
    http.post(path + 'create-login-instance', reportInstance);
    return;
}

function providerPublicSearchPDF(http, data) {
    return http.post(path + 'provider-public-search-pdf', data, { responseType: 'arraybuffer' })
}

function eligibility(http, searchDto) {
    return http.post(path + 'eligibility', searchDto)
}

function eligibilityPDF(http, wrapper) {
    return http.post(path + 'eligibility-pdf', wrapper, { responseType: 'arraybuffer' });
}

function qolContractMonitoring(http, searchDto) {
    return http.post(path + 'qol-contract-monitoring', searchDto)
}

function qolContractMonitoringPDF(http, wrapper) {
    return http.post(path + 'qol-contract-monitoring-pdf', wrapper, { responseType: 'arraybuffer' });
}

export default {
    getList: getList,
    getNativeReportOptions: getNativeReportOptions,
    getFYDLA20ReportData: getFYDLA20ReportData,
    generateIndividualDLA20PDF: generateIndividualDLA20PDF,
    generateDLA20PDF: generateDLA20PDF,
    getFYQIReportData: getFYQIReportData,
    generateFYQIPDF: generateFYQIPDF,
    generateJIIPDF: generateJIIPDF,
    generateClientSummaryServicesPDF: generateClientSummaryServicesPDF,
    getLoginReportData: getLoginReportData,
    getClientSummaryIndicatorsAndServices: getClientSummaryIndicatorsAndServices,
    getJusticeInvolvedInitData: getJusticeInvolvedInitData,
    priorityPopulationWithTargets: priorityPopulationWithTargets,
    priorityPopulationWithTargetsPdf: priorityPopulationWithTargetsPdf,
    invoicedESRs: invoicedESRs,
    crisisServices: crisisServices,
    crisisServicesPDF: crisisServicesPDF,
    misRaw: misRaw,
    primaryResidentialWomen: primaryResidentialWomen,
    primaryResidentialWomenPDF: primaryResidentialWomenPDF,
    dischargeStatus: dischargeStatus,
    dischargeStatusPDF: dischargeStatusPDF,
    accessToCare: accessToCare,
    accessToCarePDF: accessToCarePDF,
    getAccessToCareOutlierData: getAccessToCareOutlierData,
    generateAccessToCareOutliersPDF: generateAccessToCareOutliersPDF,
    treatmentComplete: treatmentComplete,
    treatmentCompletePDF: treatmentCompletePDF,
    priorityPopulationServed: priorityPopulationServed,
    priorityPopulationServedPDF: priorityPopulationServedPDF,
    monthlyAccuracyContractMonitoring: monthlyAccuracyContractMonitoring,
    monthlyAccuracyContractMonitoringPDF: monthlyAccuracyContractMonitoringPDF,
    rahsCapacityAdmin: rahsCapacityAdmin,
    rahsCapacityAdminPDF: rahsCapacityAdminPDF,
    ssnMonitoring: ssnMonitoring,
    ssnMonitoringPDF: ssnMonitoringPDF,
    ssnMonitoringClientLevel: ssnMonitoringClientLevel,
    ssnMonitoringClientLevelPDF: ssnMonitoringClientLevelPDF,
    pwwdcMoe: pwwdcMoe,
    pwwdcMoePDF: pwwdcMoePDF,
    residentialLengthOfStay: residentialLengthOfStay,
    residentialLengthOfStayPDF: residentialLengthOfStayPDF,
    clientsAndHoursServed: clientsAndHoursServed,
    clientsAndHoursServedPDF: clientsAndHoursServedPDF,
    esrRaw: esrRaw,
    sorServices: sorServices,
    sorServicesPDF: sorServicesPDF,
    esrStandard: esrStandard,
    esrStandardPDF: esrStandardPDF,
    misStandardClientDemographics: misStandardClientDemographics,
    misStandardClientDemographicsWSH: misStandardClientDemographicsWSH,
    misStandardKeyIndicators: misStandardKeyIndicators,
    misStandardKeyIndicatorsWSH: misStandardKeyIndicatorsWSH,
    misStandardClinical: misStandardClinical,
    misStandardClinicalWSH: misStandardClinicalWSH,
    misStandardClientDemographicsPDF: misStandardClientDemographicsPDF,
    misStandardClientDemographicsWSHPDF: misStandardClientDemographicsWSHPDF,
    misStandardKeyIndicatorsPDF: misStandardKeyIndicatorsPDF,
    misStandardKeyIndicatorsWSHPDF: misStandardKeyIndicatorsWSHPDF,
    misStandardClinicalPDF: misStandardClinicalPDF,
    misStandardClinicalWSHPDF: misStandardClinicalWSHPDF,
    esrServices: esrServices,
    esrServicesPDF: esrServicesPDF,
    esrServicesMonthlyBreakdown: esrServicesMonthlyBreakdown,
    esrServicesMonthlyBreakdownPDF: esrServicesMonthlyBreakdownPDF,
    systemDischargeEpisodes: systemDischargeEpisodes,
    systemDischargeEpisodesPDF: systemDischargeEpisodesPDF,
    rahs_ClientStatus: rahs_ClientStatus,
    rahs_ClientStatusPDF: rahs_ClientStatusPDF,
    rahs_Timeliness: rahs_Timeliness,
    rahs_TimelinessPDF: rahs_TimelinessPDF,
    servicesByFundingSource: servicesByFundingSource,
    servicesByFundingSourcePDF: servicesByFundingSourcePDF,
    dla20AgencyAggregate : dla20AgencyAggregate,
    dla20AgencyAggregatePDF: dla20AgencyAggregatePDF,
    dla20AgencyAggregateByAdmitDate: dla20AgencyAggregateByAdmitDate,
    dla20AgencyAggregateByAdmitDatePDF: dla20AgencyAggregateByAdmitDatePDF,
    dla20ParticipantsByAgency: dla20ParticipantsByAgency,
    dla20ParticipantsByAgencyPDF: dla20ParticipantsByAgencyPDF,
    dla20ParticipantsParticipantSummary: dla20ParticipantsParticipantSummary,
    dla20ParticipantsParticipantSummaryPDF: dla20ParticipantsParticipantSummaryPDF,
    dla20AgencyAggregateByCategory: dla20AgencyAggregateByCategory,
    dla20AgencyAggregateByCategoryPDF: dla20AgencyAggregateByCategoryPDF,
    episodeReport: episodeReport,
    episodeReportPDF: episodeReportPDF,
    episodeAggregateReport: episodeAggregateReport,
    episodeAggregateReportPDF: episodeAggregateReportPDF,
    rahs_CapacityAdmin: rahs_CapacityAdmin,
    rahs_CapacityAdminPDF: rahs_CapacityAdminPDF,
    womenServedDrawDown: womenServedDrawDown,
    womenServedDrawDownPDF: womenServedDrawDownPDF,
    setLoginRecord: setLoginRecord,
    runMonthlyProviderReports: runMonthlyProviderReports,
    test_qi: test_qi,
    providerPublicSearchPDF: providerPublicSearchPDF,
    eligibility: eligibility,
    eligibilityPDF: eligibilityPDF,
    qolContractMonitoring: qolContractMonitoring,
    qolContractMonitoringPDF: qolContractMonitoringPDF,
}
/**
 * Auth Module
 */
/* eslint-disable */
import Vue from 'vue'
// import loggingService from '@/services/util/loggingService'
import userService from '@/services/userService'
import loginService from '@/services/loginService'
import router from '@/router/router'

var isDebug = false
const state = {
    authenticated: false,
    wybhdsUserDto: null
}

// getters
const getters = {
    //authenticated: state => {
    //    return state.authenticated
    //},
    wybhdsUserDto: state => {
        return state.wybhdsUserDto
    }
}

// actions
const actions = {
    authenticated({ dispatch, commit }) {
        //let result = loginService.updateUserStore(commit).then(() => {
        //    if (loginService.requiresSetup()) {
        //        console.log('pushing to request-access')
        //        router.push('/request-access')
        //    }
        //})
        //return result
        return loginService.updateUserStore(commit)
    },
    logout({ commit }) {
        commit('resetState', 'auth')
    }
}

// mutations
const mutations = {
    //setAuthenticated: set('authenticated'),
    setWybhdsUserDto(state, value) {
        state.wybhdsUserDto = value
    },
    resetState(state) {
        Object.assign(state, {
            wybhdsUserDto: null
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

var path = 'api/cl_edbsclient/'

import utilService from '@/services/util/utilService'

function getClient(http, id) {
    return http.get(path + id)
}

function getByWCISClientId(http, id) {
    return http.get(path + 'get-by-wcis-client-id/' + id)
}

function search(http, searchstring, isClientId) {
    return http.get(`${path}search/?searchstring=${searchstring}&isClientId=${isClientId}`);
}

function saveClient(http, dto) {
    if (dto.dateOfBirth) dto.dateOfBirth = utilService.toJSONParsableDate(dto.dateOfBirth)
    return http.post(path, dto)
}

export default {
    getClient: getClient,
    getByWCISClientId: getByWCISClientId,
    search: search,
    saveClient: saveClient
}
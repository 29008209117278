var path = 'api/fmt_esr/'

function saveMH(http, dto) {
    return http.post(path + 'savemh', dto)
}

function saveSA(http, dto) {
    return http.post(path + 'savesa', dto)
}

function saveMHRes(http, dto) {
    return http.post(path + 'save-mh-res', dto)
}

function saveSARes(http, dto) {
    return http.post(path + 'save-sa-res', dto)
}

function getESRById(http, id, agencyTypeId) {
    if (agencyTypeId === 1) {
        return http.get(path + `get-by-id-sa/${id}`);
    }
    else if (agencyTypeId === 2) {
        return http.get(path + `get-by-id-mh/${id}`);
    }
    else {
        return;
    }
}

function deleteByObject(http, dto) {
    //Agency type id checkpoint, 1 = sa, 2 = mh
    if (dto.luAgencyTypeId === 1) {
        return http.post(path + `delete-by-dto-sa`, {trackingId: dto.formID, additionalIdentifyingInformation: null});
    }
    else if (dto.luAgencyTypeId === 2) {
        return http.post(path + `delete-by-dto-mh`, {trackingId: dto.formID, additionalIdentifyingInformation: null});
    }
    else {
        return;
    }
}

function getDeletedFormsByClientId(http, id) {
    return http.get(path + `get-deleted-by-client-id/${id}`);
}


function restoreByObject(http, dto) {
    return new Promise((resolve, reject) => {
        try {
            let saESRs = new Array();
            let mhESRs = new Array();
            dto.forEach(x => {
                if (x.luAgencyTypeId === 1) {
                    saESRs.push({trackingId: x.eSRID, additionalIdentifyingInformation: null});
                }
                else if (x.luAgencyTypeId === 2) {
                    mhESRs.push({trackingId: x.eSRID, additionalIdentifyingInformation: null});
                }
            });
            http.post(path + `restore-from-delete-by-dto-sa`, saESRs).then(() => {
                http.post(path + `restore-from-delete-by-dto-mh`, mhESRs).then(() => {
                    resolve();
                });
            });
        }
        catch (error) {
            console.error(error);
            reject(error);
        }
    });
}

function getLatestMH(http) {
    return http.get(path + 'get-latest-mh')
}

function getLatestSA(http) {
    return http.get(path + 'get-latest-sa')
}

function runESRCreation(http) {
    return http.get(path + 'run-esr-creation')
}


export default {
    saveMH: saveMH,
    saveSA: saveSA,
    saveMHRes: saveMHRes,
    saveSARes: saveSARes,
    getESRById: getESRById,
    deleteByObject: deleteByObject,
    getDeletedFormsByClientId: getDeletedFormsByClientId,
    restoreByObject: restoreByObject,
    getLatestMH: getLatestMH,
    getLatestSA: getLatestSA,
    runESRCreation: runESRCreation,
}
<template>
  <v-navigation-drawer
    v-model="drawer"
    id="app_drawer"
    app
    dark
    persistent
    clipped
    mobile-breakpoint="450"
    :src="require('@/assets/drawerBg.png')"
  >
    
    <div class="bar-div">
      <template v-for="(link, i) in filteredLinks">
          <template v-if="link.items != null">
            <v-list-group :key="link.title" v-model="link.active" class="main-nav">
                <v-list-item slot="activator" active-class="navlinkActive--text" class="nav-group-title">
                        <v-list-item-title>{{ link.text }}</v-list-item-title>
                </v-list-item>

                <v-list-item v-for="subItem in link.items" :key="subItem.title" :to="subItem.to" active-class="navlinkActive--text" class="nav-group-item-title">
                    <v-list-item-title v-text="subItem.text" />
                    <v-chip v-if="subItem.alert" :color="getAlertColor(subItem)" class="ml-1" dark>{{ subItem.alert }}</v-chip>
               </v-list-item>
            </v-list-group>
          </template>
          <template v-else>
            <v-list-item :key="'links' + i" :to="link.to" :class="[activeLinks[link.title] ? ['navlinkActive--text', 'v-list-item--active'] : '']">
              <v-list-item-title v-text="link.text" />
            </v-list-item>
          </template>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
// Utilities
//import { mapMutations, mapState } from 'vuex'
import { mapState } from "vuex";
import userService from "@/services/userService";
import accessService from "@/services/accessService";
import store from '@/store/store'

export default {
    data: () => ({
        drawer: {},
        logosimple: "@/assets/wdh_logo_only.png",
        logo: 'require("@/assets/wdh_logo_only.png")',
        //bgImage: '/img/sidebar_bg_teton_1.jpg',
        highlightRules: {
            'provider-search': ['/provider-search', '/provider-profile'],
            'improv-task-list': ['/improv-task-list', '/provider-application', '/provider-renewal', '/provider-update'],
        },
        links: [
            {
                title: "home",
                to: "/",
                text: "Home",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: true,
                iswsh: true,
                isprovider: true,
                issor: true
            },
            {
                title: "client-list",
                to: "/client-list",
                text: "Client List",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: true,
                iswsh: true,
                isprovider: false,
                issor: true
            },
            {
                title: "data-submission",
                to: "/",
                active: false,
                text: "Data Submission",
                isadmin: true,
                isstateuser: false,
                isagencygroup: true,
                israhs: false,
                iswsh: true,
                isprovider: false,
                issor: false,
                items: [
                    {
                        title: "form-entry",
                        to: "/form-entry",
                        text: "Online Entry",
                        iswsh: true,
                    },
                    {
                        title: "upload",
                        to: "/upload",
                        text: "Upload",
                        iswsh: false,
                    },
                ],
            },
            {
                title: "rahs",
                to: "/rahs",
                text: "RAHS",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: false,
                israhs: true,
                iswsh: false,
                isprovider: false,
                issor: false
            },
            {
                title: "qol",
                to: "/qol",
                text: "QOL",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false
            },
            {
                title: "improv-task-list",
                to: "/improv-task-list",
                text: "Provider Tasks",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: true,
                issor: false
            },
            {
                title: 'provider-search',
                to: '/provider-search',
                text: 'Provider',
                isadmin: true,
                isstateuser: true,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: true,
                issor: false
            },
            {
                title: "reporting-forms",
                to: "/reporting-forms",
                text: "Reporting Forms",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false
            },
            {
                title: "reports",
                to: "/reports",
                text: "Reports",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: true,
                iswsh: true,
                isprovider: false,
                issor: true
            },
            {
                title: "powerbi",
                to: "/power-bi-reports",
                text: "Power BI Reports",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: false,
                israhs: false,
                iswsh: false, 
                isprovider: false,
                issor: false
            },
            //{
            //    title: 'Provider',
            //    to: '/provider-profile',
            //    text: 'Provider',
            //    isadmin: false,
            //    isstateuser: false,
            //    isagencygroup: false,
            //    israhs: false,
            //    iswsh: false,
            //    isprovider: true
            //},
            {
                title: "administration",
                to: "/",
                active: false,
                text: "Admin",
                isadmin: true,
                isstateuser: false,
                isagencygroup: false,
                israhs: false,
                iswsh: false,
                isprovider: false,
                issor: false,
                items: [
                    {
                        title: "user-admin",
                        to: "/user-admin",
                        text: "User Admin",
                    },
                    {
                        title: "request-access-review",
                        to: "/request-access-review",
                        text: "Access Requests",
                    },
                    {
                        title: "agencygroup-admin",
                        to: "/agencygroup-admin",
                        text: "Agency Admin",
                    },
                    {
                        title: "invoice-contracts",
                        to: "/invoice-contracts",
                        text: "Invoice Contracts",
                    },
                    {
                        title: "invoice",
                        to: "/invoice",
                        text: "Invoice",
                    },
                    {
                        title: "email-configuration",
                        to: "/email-configuration",
                        text: "Email Configuration",
                    },
                    {
                        title: "letter-templates",
                        to: "/letter-templates",
                        text: "Letter Templates"
                    },
                    {
                        title: "email-templates",
                        to: "/email-templates",
                        text: "Email Templates"
                    },
                    {
                        title: "report-management",
                        to: "/report-management",
                        text: "Report Management",
                    },
                    {
                        title: "report-access",
                        to: "/report-access",
                        text: "Report Access",
                    },
                ],
            },
            {
                title: "contact-us",
                to: "/contact-us",
                text: "Contact Us",
                items: null,
                isadmin: true,
                isstateuser: true,
                isagencygroup: true,
                israhs: true,
                iswsh: true,
                isprovider: true,
                issor: true
            }
        ],
        responsive: false,
        accessRequests: null
    }),
    computed: {
        //...mapState('app', ['image', 'color']),
        //...mapState('auth', ['authenticated', 'wybhdsUserDto', 'profile']),
        //inputValue: {
        //    get () {
        //        return this.$store.state.app.drawer
        //    },
        //    set (val) {
        //        this.setDrawer(val)
        //    }
        //},
        //sidebarOverlayGradiant () {
        //    return `${this.$store.state.app.sidebarBackgroundColor}, ${this.$store.state.app.sidebarBackgroundColor}`
        //},
        //userName: function () {
        //    return (this.profile && this.profile.name) ? this.profile.name : 'Unknown User'
        //},
        //userProfilePicUrl: function () {
        //    return (this.profile && this.profile.picture) ? this.profile.picture : ''
        //},
        //userEmail: function () {
        //    return (this.profile && this.profile.email) ? this.profile.email : ''
        //},
        activeLinks() {
            const routePath = this.$route.path;
            let activeLinks = {};
            this.links.forEach(link => {
                // Direct match
                let isActive = routePath === link.to;

                // Check highlight rules for additional routes that should activate the link
                if (this.highlightRules[link.title] && this.highlightRules[link.title].includes(routePath)) {
                    isActive = true;
                }

                // Set the link title and its active state in the object
                activeLinks[link.title] = isActive;
            });

            return activeLinks;
        },
        items() {
            return this.$t("Layout.View.items");
        },
        ...mapState("auth", ["wybhdsUserDto"]),
        isWSHUser: function () { return (this.wybhdsUserDto && userService.isWSHUser()) },
        isRahsUser: function () { return (this.wybhdsUserDto && userService.isRahsUser()) },
        isAgencyGroupUser: function () { return (this.wybhdsUserDto && userService.isAgencyGroupUser()) },
        isAdminUser: function () { return (this.wybhdsUserDto && userService.isAdministrator()) },
        isStateUser: function () { return (this.wybhdsUserDto && userService.isStateUser()) },
        isProvider: function () { return (this.wybhdsUserDto && userService.isProvider()) },
        isSorUser: function () { return (this.wybhdsUserDto && userService.isSorUser()) },
        hasRoles: function () { return this.wybhdsUserDto && userService.hasRoleList(); },
        filteredLinks() {
            var l = [...this.links];
            const today = new Date();
            const cutoffDate = new Date('2024-07-16');

            if (this.isAdminUser) return l.filter(x => x.isadmin);
            if (this.isStateUser) return l.filter(x => x.isstateuser);
            if (this.isWSHUser) return l.filter(x => x.iswsh);

            l = l.filter(link => {
                if (this.isAgencyGroupUser)
                {
                    if (link.title === 'rahs' && today > cutoffDate && this.wybhdsUserDto.agencyGroupId !== 25) {
                        return false;
                    }
                } 
                return true;
            });

            let links = [];

            if (this.isProvider) {
                const providerLinks = l.filter(x => x.isprovider);
                links.push(...providerLinks);
            }

            if (this.isAgencyGroupUser) {
                const agencyGroupLinks = l.filter(x => x.isagencygroup);

                links.push(...agencyGroupLinks);
            }

            if (this.isRahsUser) {
                const rahsLinks = l.filter(x => x.israhs);
                links.push(...rahsLinks);
            }

            if (this.isSorUser) {
                const sorLinks = l.filter(x => x.issor);
                links.push(...sorLinks);
            }

            // Filter out duplicates
            return links.filter((x, i, ar) => ar.findIndex(y => y === x) === i);
        }
    },
    watch: {
        isAdminUser(val) {
            if (val) this.setAlerts()
        }
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    methods: {
        //...mapMutations('app', ['setDrawer', 'toggleDrawer']),
        onResponsiveInverted() {
            if (window.innerWidth < 991) this.responsive = true
            else this.responsive = false
        },
        setAlerts() {
            this.getAccessRequests()
        },
        getAccessRequests() {
            this.accessRequests = null
            return accessService.getList(this.$http, false, false)
                .then(response => {
                    this.accessRequests = response.data
                    if (this.accessRequests?.length > 0) {
                        var adminLink = this.links.find(x => x.text === 'Admin')
                        var requestLink = adminLink.items.find(x => x.text === 'Access Requests')
                        requestLink.alert = this.accessRequests.length

                        //console.log(link)
                        //link.alert = response.data.length
                        //console.log(link)
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        getAlertColor(item) {
            switch (item.text) {
                case 'Access Requests':
                    return this.getAccessRequestAlertColor()
            }
            return 'success'
        },
        getAccessRequestAlertColor() {
            if (this.accessRequests?.length > 0) {
                let x = [...this.accessRequests]
                x.sort(this.accessRequest_sort)

                var date = new Date(x[0].modifiedDate).getTime()
                const today = new Date().getTime()
                const days = Math.ceil((Math.abs(today - date)) / (1000 * 60 * 60 * 24));

                if (days <= 5) return 'success'
                else if (days <= 15) return 'warning'
                else return 'error'
            }
            return 'success'
        },
        accessRequest_sort(a, b) {
            return new Date(a.modifiedDate).getTime() - new Date(b.modifiedDate).getTime();
        }
    },
};
</script>

<style lang="scss">
@import "../../styles/material-dashboard/mixins";
@import "../../styles/material-dashboard/variables";
</style>

<template>
    <!--src="https://picsum.photos/1920/1080?random"-->
    <v-app-bar app prominent persistent clipped-left :color="getBackgroundColor()" id="app-bar">

        <v-icon v-if="isPublicPage" color="white" class="align-self-center" @click="$emit('toggleDrawer')">{{ appBarIcon }}</v-icon>
        <div class="bar-logo align-self-center" background-color="primary" height="80" width="220">
            <v-img :src="require('@/assets/wdh_logo_only.png')" height="80" width="220" contain></v-img>
        </div>
        
        <v-app-bar-title class="white--text align-self-center pb-0 ms-5">{{ $appNameFull }}<br />{{ additionalTitleText }}</v-app-bar-title>
        <v-spacer></v-spacer>

        <h6 class="white--text align-self-center"><span v-if="isPublicPage">Hello, {{ username }}</span><br /> {{ timestamp[0] }} <br /> {{ timestamp[1] }} <br />{{ userinfo }}</h6>
        <v-btn v-if="isPublicPage" class="mx-2 align-self-center" fab dark color="white" @click="logout"><v-icon color="black">mdi-logout</v-icon></v-btn>
    </v-app-bar>
</template>

<script>
    import { mapState } from 'vuex'
    import utilService from '@/services/util/utilService'
    import agencyGroupService from '@/services/agencyGroupService'
    import userService from '@/services/userService'

    export default {
        props: {
            appBarIcon: String,
        },
        data: () => ({
            timestamp: "",
            agencyGroup: null
        }),
        computed: {
            ...mapState('auth', ['wybhdsUserDto']),
            isAgencyGroupUser: function () { return (this.wybhdsUserDto && userService.isAgencyGroupUser()) },
            username() { return (this.$auth.user && this.$auth.user.name) ? this.$auth.user.name : 'Unknown User' },
            userinfo() {
                if (this.isAgencyGroupUser && this.wybhdsUserDto.agencyGroupId) {
                    this.getAgencyGroup()
                    return this.agencyGroup
                }
                else if (this.wybhdsUserDto && this.wybhdsUserDto.roleList) {
                    var role = ''
                    this.wybhdsUserDto.roleList.forEach(x => {
                        role += x + '  '
                    })
                    return role
                }
                else { return '' }
            },
            additionalTitleText() {
                if (this.wybhdsUserDto && this.wybhdsUserDto.environment == 'Development') return ' (Development)'
                if (this.wybhdsUserDto && this.wybhdsUserDto.environment == 'Test') return ' (Test)'
                return ''
            },
            isPublicPage() {
                return this.$route.name !== 'provider-public-search';
            }
        },
        mounted() {
            setInterval(this.getNow, 1000);
        },
        methods: {
            getNow: function () {
                this.timestamp = utilService.getAppBarTimestamp()
            },
            logout() {
                //TODO:  I think we need to kill the WCIS cookie in the old site (if one exists) here
                this.$auth.logout({
                    returnTo: window.location.origin
                });
            },
            getAgencyGroup() {
                return agencyGroupService.get(this.$http, this.wybhdsUserDto.agencyGroupId).then(response => {
                    this.agencyGroup = response.data.agencyGroupName
                })
                .catch(err => {
                    console.warn(err)
                })
            },
            getBackgroundColor() {
                if (this.wybhdsUserDto && (this.wybhdsUserDto.environment == 'Development' || this.wybhdsUserDto.environment == 'Test')) return 'testBackground'
                return 'background'
            }
        }
    }

</script>

<style>
    #app-bar {
        height: 100px !important;
    }
    #app-bar .v-toolbar__content {
        height: 100px !important;
    }
</style>
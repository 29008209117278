<script>
import utilService from '@/services/util/utilService';
import enumService from '@/services/util/enumService';

export default{
    data: () => ({
        isFormValid: false,
        rahsObjectChildCopy: {},
        requiredFieldRules: [
            value => !!value || 'This field is REQUIRED.',
        ],
        tshStatusList: [],        
    }),
    methods: {
        close() {
            this.$emit("close-admin-rahs-dialog");
        },
        save() {
            if (!this.isRevert) {
                this.$emit("save-admin-rahs-dialog", {subject: "editDate", modifiedItem: this.rahsObjectChildCopy});
                return;
            }
            else if (this.isRevert) {
                this.$emit("save-admin-rahs-dialog", {subject: "revertStatus", modifiedItem: this.rahsObjectChildCopy});
                return;
            }
            else {
                return;
            }
        },
        onHandlerEvent() {
            this.rahsObjectChildCopy = this.parentRahsObject ? JSON.parse(JSON.stringify(this.parentRahsObject)) : {};
            if (this.rahsObjectChildCopy.tsH_RequestID && this.rahsObjectChildCopy.tsH_EpisodeID && !this.isRevert) {
                this.rahsObjectChildCopy.acceptedDate = this.rahsObjectChildCopy.acceptedDate ? utilService.formatDateToJavascriptDate(this.rahsObjectChildCopy.acceptedDate) : null;
                this.rahsObjectChildCopy.completedDate = this.rahsObjectChildCopy.completedDate ? utilService.formatDateToJavascriptDate(this.rahsObjectChildCopy.completedDate) : null;
                this.rahsObjectChildCopy.removalDate = this.rahsObjectChildCopy.removalDate ? utilService.formatDateToJavascriptDate(this.rahsObjectChildCopy.removalDate) : null;
                this.rahsObjectChildCopy.waitlistDate = this.rahsObjectChildCopy.waitlistDate ? utilService.formatDateToJavascriptDate(this.rahsObjectChildCopy.waitlistDate) : null;
                this.isFormValid = true;
            }
            else if (this.rahsObjectChildCopy.tsH_RequestID && this.rahsObjectChildCopy.tsH_EpisodeID && this.isRevert) {
                this.isFormValid = true;
            }
            else {
                this.isFormValid = false;
            }
        },
    },
    props: {
            parentRahsObject: Object,
             dialog: {
                type: Boolean,
                required: true,
            },
            isRevert: {
                type: Boolean,
                required: true,
            }
    },
    watch: {
        $props: {
            handler() {
                this.onHandlerEvent();
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        
    },
    created() {
        this.tshStatusList = enumService.getTSHStatusList();
    }
}
</script>



<template>
    <v-dialog :value="dialog" @click:outside="close" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="600">
        <v-card>
            <v-toolbar color="orange lighten-4" dark class="black--text">{{isRevert ? "Revert Status" : "Edit Dates"}}</v-toolbar>
            <v-spacer></v-spacer>
            <v-card-text class="mt-6" v-if="!isRevert">
                <v-form v-model="isFormValid">
                    <v-text-field v-model="rahsObjectChildCopy.acceptedDate" label="Accepted Date" type="date" v-if="rahsObjectChildCopy.acceptedDate !== null" clearable ></v-text-field>
                    <v-text-field v-model="rahsObjectChildCopy.completedDate" label="Completion Date" type="date" v-if="rahsObjectChildCopy.completedDate !== null" clearable ></v-text-field>
                    <v-text-field v-model="rahsObjectChildCopy.removalDate" label="Removal Date" type="date" v-if="rahsObjectChildCopy.removalDate !== null" clearable></v-text-field>
                    <v-text-field v-model="rahsObjectChildCopy.waitlistDate" label="Waitlisted Date" type="date" v-if="rahsObjectChildCopy.waitlistDate !== null" clearable></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-text class="mt-6" v-if="isRevert">
            <v-form v-model="isFormValid">
                <p class="black--text">{{"Current status is: " + rahsObjectChildCopy.tshStatus}}</p>
                <v-select v-model="rahsObjectChildCopy.luTSHStatusID" :items="tshStatusList" item-value="luTSHStatusID" item-text="description" :menu-props="{ maxHeight: '400' }" label="Desired Status To Move Into" class="font-weight-regular" :rules="requiredFieldRules"></v-select>
            </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="save" :disabled="!isFormValid">Save</v-btn>
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="close">Cancel</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
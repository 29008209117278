<script>

export default{
    data: () => ({
        childAgencyArray: [],
        isValid: false,
        requiredFieldRules: [
            value => !!value || 'This field is REQUIRED.',
        ],
    }),
    methods: {
        save(isOutsideClick) {
            if (isOutsideClick) {
                this.$emit("close-agency-description-dialog");
                return;
            }
            this.$emit("close-agency-description-dialog", this.childAgencyArray.map(a => {return {agencyId: a.agencyID, agencyDescription: a.agencyDescription}}));
        },
        onHandlerEvent() {
            this.childAgencyArray = this.parentAgencyArray ? JSON.parse(JSON.stringify(this.parentAgencyArray)) : [];
        },
    },
    props: {
        parentAgencyArray: Array,
        dialog: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        $props: {
            handler() {
                this.onHandlerEvent();
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        
    },
    created() {
        
        
    }
}
</script>

<template>
    <v-dialog :value="dialog" @click:outside="save(true)" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="1200" max-height="1200">
        <v-card>
            <v-toolbar color="orange lighten-4" dark class="black--text">Edit Agency Descriptions</v-toolbar>
            <v-spacer></v-spacer>
            <v-card-title>
            </v-card-title>
            <v-card-text class="mt-6">
                <div class="col-sm">
                    <v-form v-model="isValid">
                        <div v-for="item in childAgencyArray" :key="item.agencyID">
                            <v-text-field v-model="item.agencyDescription" :label=item.agencyName :rules="requiredFieldRules"></v-text-field>
                        </div>
                    </v-form>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="save(false)" :disabled="!isValid">Save and Finish</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
var path = 'api/improv/providerupdate/'

function getProviderUpdateListByUser(http) {
    return http.get(path + 'get-provider-update-list-by-user')
}

function getProviderUpdateHistoryByProviderId(http, providerId) {
    return http.get(path + 'get-provider-update-history-by-provider/' + providerId)
}

function getProviderUpdateProcessInstanceByProcessInstanceId(http, processInstanceId) {
    return http.get(path + 'get-provider-update-process-instance-by-process-instance-id/' + processInstanceId)
}

function getProviderUpdateProcessInstanceListByUser(http) {
    return http.get(path + 'get-provider-update-process-instance-list-by-user')
}

function getProviderUpdateProcessInstanceListInProgressByUser(http) {
    return http.get(path + 'get-provider-update-process-instance-list-in-progress-by-user')
}

function save(http, dto) {
    return http.post(path + 'save', dto)
}

function initialize(http, processInstanceId) {
    return http.post(path + 'initialize/' + processInstanceId)
}

function submit(http, dto) {
    return http.post(path + 'submit', dto)
}

function approve(http, dto) {
    return http.post(path + 'approve', dto)
}

function reject(http, dto) {
    return http.post(path + 'reject', dto)
}

function cancel(http, dto) {
    return http.post(path + 'cancel', dto)
}

function revertProcess(http, processInstanceId) {
    return http.post(path + 'revert-process/' + processInstanceId)
}

export default {
    getProviderUpdateListByUser: getProviderUpdateListByUser,
    getProviderUpdateHistoryByProviderId: getProviderUpdateHistoryByProviderId,
    getProviderUpdateProcessInstanceByProcessInstanceId: getProviderUpdateProcessInstanceByProcessInstanceId,
    getProviderUpdateProcessInstanceListByUser: getProviderUpdateProcessInstanceListByUser,
    getProviderUpdateProcessInstanceListInProgressByUser: getProviderUpdateProcessInstanceListInProgressByUser,
    save: save,
    initialize: initialize,
    submit: submit,
    approve: approve,
    reject: reject,
    cancel: cancel,
    revertProcess: revertProcess
}
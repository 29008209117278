var path = 'api/carf/'

function getLatest(http) {
    return http.get(path + `get-latest/`);
}

function getLatestSentinelEventsTake(http, take) {
    return http.get(`${path}get-latest-sentinel-events-take/${take}`)
}

function getUnacknowledgedSentinelEventList(http) {
    return http.get(path + 'get-unacknowledged-sentinel-event-list')
}

function getUnacknowledgedLeadershipChangeList(http) {
    return http.get(path + 'get-unacknowledged-leadership-change-list')
}

function getRequiresUserAttentionSentinelEventList(http) {
    return http.get(path + 'get-requires-user-attention-sentinel-event-list')
}

function getRequiresUserAttentionLeadershipChangeList(http) {
    return http.get(path + 'get-requires-user-attention-leadership-change-list')
}

function getLatestLeadershipChangesTake(http, take) {
    return http.get(`${path}get-latest-leadership-changes-take/${take}`)
}

function getSentinelEventsByClientId(http, clientId) {
    return http.get(path + `get-sentinel-events-by-client-id/${clientId}`)
}

function getSentinelEventList(http) {
    return http.get(path + 'get-sentinel-event-list')
}

function getLeadershipChangeList(http) {
    return http.get(`${path}get-leadership-change-list`)
}

function getAdministrativeCommunicationListLatestActivity(http) {
    return http.get(path + 'get-administrative-communication-list-latest-activity')
}

function getAdministrativeCommunicationListLatestActivityAdmin(http) {
    return http.get(path + 'get-administrative-communication-list-latest-activity-admin')
}

function getAdministrativeCommunicationListTrailingDayActivityAdmin(http, trailingdays) {
    return http.get(path + 'get-administrative-communication-list-trailing-day-activity-admin/' + trailingdays)
}

function getAdministrativeCommunicationListLatestUpload(http) {
    return http.get(path + 'get-administrative-communication-list-latest-upload')
}

function upload(http, dto, luCARFFormTypeId) {
    return http.post(`${path}upload/${luCARFFormTypeId}`, dto)
}

function downloadFile(http, id) {
    return http.get(`${path}downloadfile/${id}`)
}

function getSentinelEvent(http, id) {
    return http.get(`${path}get-sentinel-event/${id}`)
}

function getLeadershipChange(http, id) {
    return http.get(`${path}get-leadership-change/${id}`)
}

function saveSentinelEvent(http, dto) {
    return http.post(path + 'save-sentinel-event', dto)
}

function acknowledgeSentinelEvent(http, dto) {
    return http.post(path + 'acknowledge-sentinel-event', dto)
}

function saveLeadershipChange(http, dto) {
    return http.post(path + 'save-leadership-change', dto)
}

function acknowledgeLeadershipChange(http, dto) {
    return http.post(path + 'acknowledge-leadership-change', dto)
}

function sentinelEventFlagAsRequiresUserAttention(http, dto) {
    return http.post(path + 'sentinel-event-flag-as-requires-user-attention', dto)
}

function leadershipChangeFlagAsRequiresUserAttention(http, dto) {
    return http.post(path + 'leadership-change-flag-as-requires-user-attention', dto)
}

function sentinelEventPDF(http, objid) {
    return http.post(path + 'sentinel-event-pdf', objid, { responseType: 'arraybuffer' })
}

function leadershipChangePDF(http, objid) {
    return http.post(path + 'leadership-change-pdf', objid, { responseType: 'arraybuffer' })
}

export default {
    getLatest: getLatest,
    getLatestSentinelEventsTake: getLatestSentinelEventsTake,
    getUnacknowledgedSentinelEventList: getUnacknowledgedSentinelEventList,
    getUnacknowledgedLeadershipChangeList: getUnacknowledgedLeadershipChangeList,
    getRequiresUserAttentionSentinelEventList: getRequiresUserAttentionSentinelEventList,
    getRequiresUserAttentionLeadershipChangeList: getRequiresUserAttentionLeadershipChangeList,
    getLatestLeadershipChangesTake: getLatestLeadershipChangesTake,
    getSentinelEventList: getSentinelEventList,
    getSentinelEventsByClientId: getSentinelEventsByClientId,
    getLeadershipChangeList: getLeadershipChangeList,
    getAdministrativeCommunicationListLatestActivity: getAdministrativeCommunicationListLatestActivity,
    getAdministrativeCommunicationListLatestActivityAdmin: getAdministrativeCommunicationListLatestActivityAdmin,
    getAdministrativeCommunicationListTrailingDayActivityAdmin: getAdministrativeCommunicationListTrailingDayActivityAdmin,
    getAdministrativeCommunicationListLatestUpload: getAdministrativeCommunicationListLatestUpload,
    upload: upload,
    downloadFile: downloadFile,
    getSentinelEvent: getSentinelEvent,
    getLeadershipChange: getLeadershipChange,
    saveSentinelEvent: saveSentinelEvent,
    saveLeadershipChange: saveLeadershipChange,
    acknowledgeSentinelEvent: acknowledgeSentinelEvent,
    acknowledgeLeadershipChange: acknowledgeLeadershipChange,
    sentinelEventFlagAsRequiresUserAttention: sentinelEventFlagAsRequiresUserAttention,
    leadershipChangeFlagAsRequiresUserAttention: leadershipChangeFlagAsRequiresUserAttention,
    sentinelEventPDF: sentinelEventPDF,
    leadershipChangePDF: leadershipChangePDF
}
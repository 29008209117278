<script>
import reportService from '@/services/reportService';
import utilService from '@/services/util/utilService';

export default{
    data: () => ({
        startDate: null,
        endDate: null,
        tabs: null,
        isReportLoading: false,
        preliminaryRangeCompleted: false,
        searchClientOverview: "",
        searchMHOPEpisodes: "",
        searchMHResEpisodes: "",
        searchSUDOPEpisodes: "",
        searchSUDResEpisodes: "",
        searchRahsEpisodes: "",
        searchMHServices: "",
        searchSUDServices: "",
        filteredByDateEpisodes: [],
        filteredByDateRahsEpisodes: [],
        dynamicEpisodeTableData: [],
        filteredByDateServices: [],
        dynamicServicesTableData: [],
        clientOverviewHeaders: [ {text: "Gender", value: "gender" , class: "parent-table-container"}, {text: "Age", value: "age", class: "parent-table-container"}, {text: "Latest Employment Status", value: "employment", class: "parent-table-container"}, {text: "Latest Annual Income", value: "annualIncome", class: "parent-table-container"}],
        sharedEpisodeHeaders: [{text: "Agency", value: "agencyName", class: "parent-table-container"}, {text: "Agency Code", value: "agencyCode", class: "parent-table-container"}, {text: "Admit", value: "admitDate", class: "parent-table-container"}, {text: "Discharge", value: "dischargeDate", class: "parent-table-container"}, {text: "Last Service", value: "lastServiceDate", class: "parent-table-container"}, {text: "Discharge Status", value: "dischargeStatus", class: "parent-table-container"}, {text: "Diagnoses", value: "diagnoses", class: "parent-table-container"}, {text: "Presenting Problems", value: "presentingProblems", class: "parent-table-container"} ],
        rahsEpisodeHeaders: [{text: "Agency", value: "agencyName", class: "parent-table-container"}, {text: "Agency Code", value: "agencyCode", class: "parent-table-container"}, {text: "Accepted", value: "acceptedDate", class: "parent-table-container"}, {text: "Removed", value: "removalDate", class: "parent-table-container"}, {text: "Completed", value: "completedDate", class: "parent-table-container"}, {text: "Status", value: "tshStatus", class: "parent-table-container"}, {text: "Diagnoses", value: "diagnoses", class: "parent-table-container"}, {text: "Presenting Problems", value: "presentingProblems", class: "parent-table-container"} ],
        sharedServicesHeadersOP: [{text: "Service Type", value: "episodeFormTypeDescription", class: "parent-table-container"}, {text: "Service Hours", value: "fixedPlaceHours", class: "parent-table-container"}],
        sharedServicesHeadersRes: [{text: "Service Type", value: "episodeFormTypeDescription", class: "parent-table-container"}, {text: "Service Days", value: "fixedPlaceDays", class: "parent-table-container"}],


    }),
    methods: {
        close() {
            this.$emit("close-dialog");
        },
        beginReportLoad() {
            this.preliminaryRangeCompleted = true;
            this.isReportLoading = true;
            //Confirmed with Drew, we want to filter by admit or create date in the case of RaHS. Could be subject to change but this is the place to make such changes.
            this.filteredByDateEpisodes = this.episodeList.filter(e => new Date(e.admitDate) >= new Date(this.startDate) && new Date(e.admitDate) <= new Date(this.endDate));
            this.filteredByDateRahsEpisodes = this.rahsEpisodeList.filter(e => new Date(e.waitlistDate) >= new Date(this.startDate) && new Date(e.waitlistDate) <= new Date(this.endDate));
            //Lotta ternary operators here, but for good reason (null/undefined safety because there is a lot of bad data). The overall idea is that we collect ALL MIS ids into a single 
            //array, same with TSH Episode ids. Then we query the DB one time for all clinical indicators throughout a clients episode history. The rest is handled by the server.
            let relevantSummaryInformation = {
                "startDate": utilService.toJSONParsableDate(this.startDate),
                "endDate": utilService.toJSONParsableDate(this.endDate),
                "clientId": this.clientForReport.cL_WCISClientID,
                "misIds": this.filteredByDateEpisodes.length > 1 ? this.filteredByDateEpisodes.flatMap(e => {
                    return new Array(e.fmT_MISID_Admit, e.lastInterimFormId, e.fmT_MISID_Discharge);
                }).filter(Number) : [this.filteredByDateEpisodes[0] ? this.filteredByDateEpisodes[0].fmT_MISID_Admit : null, this.filteredByDateEpisodes[0] ? this.filteredByDateEpisodes[0].lastInterimFormId : null, this.filteredByDateEpisodes[0] ? this.filteredByDateEpisodes[0].fmT_MISID_Discharge : null].filter(Number),
                "rahsIds": this.filteredByDateRahsEpisodes.length > 1 ? this.filteredByDateRahsEpisodes.flatMap(e => 
                    e.tsH_EpisodeID
                ).filter(Number) : [this.filteredByDateRahsEpisodes[0] ? this.filteredByDateRahsEpisodes[0].tsH_EpisodeID : null].filter(Number),
            };

            reportService.getClientSummaryIndicatorsAndServices(this.$http, relevantSummaryInformation).then(reportResponse => {
                this.filteredByDateServices = reportResponse.data.servicesProvidedAsEsrs;
                this.filteredByDateEpisodes.forEach(x => {
                    
                    //FWIW I tried to start at the root of this tree (the episodes) for the least intensive looping strategy, and the following
                    //lines are intensely satisfying to me. All that data, neatly ordered and ready to go :')
                    let admitDiagnoses = x.fmT_MISID_Admit ? reportResponse.data.misDiagnosisDtos.filter(e => e.fmT_MISID === x.fmT_MISID_Admit) : [];
                    let interimDiagnoses = x.lastInterimFormId ? reportResponse.data.misDiagnosisDtos.filter(e => e.fmT_MISID === x.lastInterimFormId) : [];
                    let dischargeDiagnoses = x.fmT_MISID_Discharge ? reportResponse.data.misDiagnosisDtos.filter(e => e.fmT_MISID === x.fmT_MISID_Discharge) : [];

                    let admitProblems = x.fmT_MISID_Admit ? reportResponse.data.misPresentingProblemDtos.filter(e => e.fmT_MISID === x.fmT_MISID_Admit) : [] ;
                    let interimProblems = x.lastInterimFormId ? reportResponse.data.misPresentingProblemDtos.filter(e => e.fmT_MISID === x.lastInterimFormId) : [];
                    let dischargeProblems = x.fmT_MISID_Discharge ? reportResponse.data.misPresentingProblemDtos.filter(e => e.fmT_MISID === x.fmT_MISID_Discharge): [];
                    
                    x.diagnoses = "";
                    x.presentingProblems = "";

                    admitDiagnoses.forEach(e => {
                        x.diagnoses += e.luDiagnosisID + " " + (e.description ? e.description : "") + " (A), ";
                    });
                    interimDiagnoses.forEach(e => {
                        x.diagnoses += e.luDiagnosisID + " " + (e.description ? e.description : "") + ", ";
                    });
                    dischargeDiagnoses.forEach(e => {
                        x.diagnoses += e.luDiagnosisID + " " + (e.description ? e.description : "") + " (D), ";
                    });

                    //WCIS-557 edits, Polly requests to not include "Not Applicable" fields.
                    admitProblems.forEach(e => {
                        if (e.description.includes("Not Applicable")) {
                            admitProblems.splice(admitProblems.indexOf(e), 1)
                        }
                        else {
                            x.presentingProblems += e.description + " (A), ";
                        }
                    });
                    interimProblems.forEach(e => {
                        if (e.description.includes("Not Applicable")) {
                            interimProblems.splice(interimProblems.indexOf(e), 1)
                        }
                        else {
                            x.presentingProblems += e.description + ", ";
                        }
                    });
                    dischargeProblems.forEach(e => {
                        if (e.description.includes("Not Applicable")) {
                            dischargeProblems.splice(dischargeProblems.indexOf(e), 1)
                        }
                        else {
                            x.presentingProblems += e.description + " (D), ";
                        }
                    });
                });

                this.filteredByDateRahsEpisodes.forEach(x => {

                    let diagList = reportResponse.data.rahsDiagnosisDtos.filter(e => e.tsH_EpisodeID === x.tsH_EpisodeID);
                    let problemList = reportResponse.data.rahsPresentingProblemDtos.filter(e => e.tsH_EpisodeID === x.tsH_EpisodeID);

                    x.diagnoses = "";
                    x.presentingProblems = "";

                    diagList.forEach(e => {
                        x.diagnoses += e.luDiagnosisID + " " + (e.description ? e.description : "") + ", ";
                    });

                    problemList.forEach(e => {
                        if (e.description.includes("Not Applicable")) {
                            problemList.splice(problemList.indexOf(e), 1)
                        }
                        else {
                            x.presentingProblems += e.description + ", ";
                        }
                    });
                });             
                this.isReportLoading = false;
            });
        },
        async mutateData(tabIndex) {
            return new Promise((resolve, reject) => {
                try {
                    switch(tabIndex) {
                        case 1:
                            this.filterRelevantEpisodeTabData(x => x.agencyType === "MH" && x.latestTreatmentCategory === "Outpatient", tabIndex).then(() => {

                            });
                            break;
                        case 2:
                            this.filterRelevantEpisodeTabData(x => x.agencyType === "MH" && x.latestTreatmentCategory === "Residential", tabIndex).then(() => {
                                
                            });
                            break;
                        case 3:
                            this.filterRelevantEpisodeTabData(x => x.agencyType === "SUD" && x.latestTreatmentCategory === "Outpatient", tabIndex).then(() => {
                                
                            });
                            break;
                        case 4:
                            this.filterRelevantEpisodeTabData(x => x.agencyType === "SUD" && x.latestTreatmentCategory === "Residential", tabIndex).then(() => {
                                
                            });
                            break;
                        case 6:
                            this.filterRelevantEpisodeTabData(x => x.luMentalHealthServiceID > 0 && x.mentalHealthService && x.luTreatmentCategoryId === 1, tabIndex).then(() => {

                            });
                            break;
                        case 7:
                            this.filterRelevantEpisodeTabData(x => x.luMentalHealthServiceID > 0 && x.mentalHealthService && x.luTreatmentCategoryId === 2, tabIndex).then(() => {

                            });
                            break;
                        case 8:
                            this.filterRelevantEpisodeTabData(x => x.luSubstanceAbuseServiceID > 0 && x.substanceAbuseService && x.luTreatmentCategoryId === 1, tabIndex).then(() => {

                            });
                            break;
                        case 9:
                            this.filterRelevantEpisodeTabData(x => x.luSubstanceAbuseServiceID > 0 && x.substanceAbuseService && x.luTreatmentCategoryId === 2, tabIndex).then(() => {

                            });
                            break;
                        default:
                            break;
                    }
                    resolve();
                }
                catch(error) {
                    reject(error);
                    console.error(error);
                }                    
            });
        },
        filterRelevantEpisodeTabData(lambdaPredicate, tabIndex) {
            return new Promise((resolve, reject) => {
                try {
                    if (tabIndex === 6 || tabIndex === 8) {
                        this.dynamicServicesTableData = this.filteredByDateServices.filter(lambdaPredicate);
                        this.dynamicServicesTableData.forEach(e => {
                            e.fixedPlaceHours = e.hours.toFixed(2);
                        });
                        this.dynamicServicesTableData.push({"episodeFormTypeDescription": "Total", "fixedPlaceHours" : this.dynamicServicesTableData.reduce((accumulator, obj) => {return accumulator + obj.hours}, 0).toFixed(2)});
                        resolve();
                    }
                    else if(tabIndex === 7 || tabIndex === 9) {
                        this.dynamicServicesTableData = this.filteredByDateServices.filter(lambdaPredicate);
                        this.dynamicServicesTableData.push({"episodeFormTypeDescription": "Total", "fixedPlaceDays" : this.dynamicServicesTableData.reduce((accumulator, obj) => {return accumulator + obj.days}, 0)});
                        resolve();
                    }
                    else {
                        this.dynamicEpisodeTableData = this.filteredByDateEpisodes.filter(lambdaPredicate);
                        resolve();
                    }
                }
                catch(error) {
                    reject(error);
                    console.error(error);
                }                    
            });
        },
        async exportPdf() {
            let exportableContent = "";
            for (let i = 1; i <= 9; i++) {
                await this.mutateData(i);
                if (i === 1) {
                    exportableContent += "<h4>MH OP Episodes</h4>"
                    exportableContent += this.$refs.exportablePDFArea1.outerHTML;
                }
                else if (i === 2) {
                    exportableContent += "<h4>MH Residential Episodes</h4>"
                    exportableContent += this.$refs.exportablePDFArea2.outerHTML;
                }
                else if (i === 3) {
                    exportableContent += "<h4>SUD OP Episodes</h4>"
                    exportableContent += this.$refs.exportablePDFArea3.outerHTML;
                }
                else if (i === 4) {
                    exportableContent += "<h4>SUD Residential Episodes</h4>"
                    exportableContent += this.$refs.exportablePDFArea4.outerHTML;
                }
                else if (i === 5) {
                    exportableContent += "<h4>RaHS Episodes</h4>"
                    exportableContent += this.$refs.exportablePDFArea5.outerHTML;
                }
                else if (i === 6) {
                    exportableContent += "<h4>MH OP Services Received</h4>"
                    exportableContent += this.$refs.exportablePDFArea6.outerHTML;
                }
                else if (i === 7) {
                    exportableContent += "<h4>MH Residential Services Received</h4>"
                    exportableContent += this.$refs.exportablePDFArea7.outerHTML;
                }
                else if (i === 8) {
                    exportableContent += "<h4>SUD OP Services Received</h4>"
                    exportableContent += this.$refs.exportablePDFArea8.outerHTML;
                }
                else if (i === 9) {
                    exportableContent += "<h4>SUD Residential Services Received</h4>"
                    exportableContent += this.$refs.exportablePDFArea9.outerHTML;
                }
            }
            this.tabs = 0;
            let documentName = `Client_Summary_Services_For_${this.clientForReport.clientID}_Ranging_From_${new Date(this.startDate).toLocaleDateString()}_To_${new Date(this.endDate).toLocaleDateString()}`
            let clientOverviewObj = {"gender": this.clientForReport.cL_WCISClient_Informations[0].gender, "age": this.clientForReport.cL_WCISClient_Informations[0].age, "employment": this.clientForReport.cL_WCISClient_Informations[0].employment, "income": this.clientForReport.cL_WCISClient_Informations[0].annualIncome};
            reportService.generateClientSummaryServicesPDF(this.$http, exportableContent, this.startDate, this.endDate, clientOverviewObj).then(pdfResponse => {
                const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = documentName + '.pdf';
                link.click();
                URL.revokeObjectURL(link.href);
            });
            return;
        }
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        isCrisisClient: {
            type: Boolean,
            required: true
        },
        clientForReport: {
            type: Object,
            required: true
        },
        episodeList: {
            type: Array,
            required: true,
        },
        rahsEpisodeList: {
            type: Array,
            required: true,
        }
    },
    computed: {
        
    },
    created() {
    }
}
</script>
<template>
    <v-dialog :value="dialog" @click:outside="close" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="1300">
        <v-card>
            <v-toolbar color="orange lighten-4" dark class="black--text">Client Summary Services Reporting</v-toolbar>
            <v-spacer></v-spacer>
            <v-card-text class="mt-6">
                <h5 v-if="!(startDate && endDate)">Please Select Your Desired Date Range</h5>
                <v-row>
                    <v-col>
                        <v-text-field v-model="startDate"
                                    label="Start Date"
                                    type="date" clearable></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="endDate"
                                    label="End Date"
                                    type="date" clearable></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col style="text-align: center;">
                        <v-btn text color="deep-purple accent-4" class="mx-4" @click="beginReportLoad" :disabled="!(startDate && endDate)">Load Report</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-text v-if="(isReportLoading && preliminaryRangeCompleted)">
                <v-progress-circular :size="60" :width="20" color="deep-purple accent-4" indeterminate :style="{left: '50%', transform:'translateX(-50%)'}"></v-progress-circular>
            </v-card-text>
            <v-card-text v-else-if="(!isReportLoading && preliminaryRangeCompleted)">
                <v-toolbar dark flat prominent color="orange lighten-4" class="black--text mt-8" :height="105">
                    <v-tabs centered dark slider-color="deep-purple accent-4" v-model="tabs" class="pa-6" :height="85" @change="mutateData($event)" active-class="activeTabClass" :show-arrows="true">
                        <v-tab style="color: black" >
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            Client Overview
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            MH OP Episodes
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            MH Residential Episodes
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            SUD OP Episodes
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            SUD Residential Episodes
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            RaHS Episodes
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            MH OP Services Received
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            MH Residential Services Received
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            SUD OP Services Received
                        </v-tab>
                        <v-tab style="color: black">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            SUD Residential Services Received
                        </v-tab>
                    </v-tabs>
                </v-toolbar>
                <v-tabs-items v-model="tabs">
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">{{"Client Overview For "+ clientForReport.clientID}}</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchClientOverview"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea0" id="exportablePDFArea0">
                                <v-data-table dense :search="searchClientOverview" :headers="clientOverviewHeaders" :items="clientForReport.cL_WCISClient_Informations" class="elevation-1">
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">MH OP Episodes</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchMHOPEpisodes"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea1" id="exportablePDFArea1">
                                <v-data-table dense :search="searchMHOPEpisodes" :headers="sharedEpisodeHeaders" :items="dynamicEpisodeTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.agencyName }}</td>
                                            <td class="parent-table-container">{{ row.item.agencyCode }}</td>
                                            <td class="parent-table-container ">{{ row.item.admitDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeDate }}</td>
                                            <td class="parent-table-container">{{ row.item.lastServiceDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeStatus }}</td>
                                            <td class="parent-table-container">{{ row.item.diagnoses }}</td>
                                            <td class="parent-table-container">{{ row.item.presentingProblems }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">MH Residential Episodes</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchMHResEpisodes"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea2" id="exportablePDFArea2">
                                <v-data-table dense :search="searchMHResEpisodes" :headers="sharedEpisodeHeaders" :items="dynamicEpisodeTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.agencyName }}</td>
                                            <td class="parent-table-container">{{ row.item.agencyCode }}</td>
                                            <td class="parent-table-container ">{{ row.item.admitDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeDate }}</td>
                                            <td class="parent-table-container">{{ row.item.lastServiceDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeStatus }}</td>
                                            <td class="parent-table-container">{{ row.item.diagnoses }}</td>
                                            <td class="parent-table-container">{{ row.item.presentingProblems }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">SUD OP Episodes</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchSUDOPEpisodes"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea3" id="exportablePDFArea3"> 
                                <v-data-table dense :search="searchSUDOPEpisodes" :headers="sharedEpisodeHeaders" :items="dynamicEpisodeTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.agencyName }}</td>
                                            <td class="parent-table-container">{{ row.item.agencyCode }}</td>
                                            <td class="parent-table-container ">{{ row.item.admitDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeDate }}</td>
                                            <td class="parent-table-container">{{ row.item.lastServiceDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeStatus }}</td>
                                            <td class="parent-table-container">{{ row.item.diagnoses }}</td>
                                            <td class="parent-table-container">{{ row.item.presentingProblems }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">SUD Residential Episodes</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchSUDResEpisodes"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea4" id="exportablePDFArea4">
                                <v-data-table dense :search="searchSUDResEpisodes"  :headers="sharedEpisodeHeaders" :items="dynamicEpisodeTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.agencyName }}</td>
                                            <td class="parent-table-container">{{ row.item.agencyCode }}</td>
                                            <td class="parent-table-container ">{{ row.item.admitDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeDate }}</td>
                                            <td class="parent-table-container">{{ row.item.lastServiceDate }}</td>
                                            <td class="parent-table-container">{{ row.item.dischargeStatus }}</td>
                                            <td class="parent-table-container">{{ row.item.diagnoses }}</td>
                                            <td class="parent-table-container">{{ row.item.presentingProblems }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">RaHS Episodes</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchRahsEpisodes"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea5" id="exportablePDFArea5">
                                <v-data-table dense :search="searchRahsEpisodes" :headers="rahsEpisodeHeaders" :items="filteredByDateRahsEpisodes" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.agencyName }}</td>
                                            <td class="parent-table-container">{{ row.item.agencyCode }}</td>
                                            <td class="parent-table-container ">{{ row.item.acceptedDate }}</td>
                                            <td class="parent-table-container">{{ row.item.removalDate }}</td>
                                            <td class="parent-table-container">{{ row.item.completedDate }}</td>
                                            <td class="parent-table-container">{{ row.item.tshStatus }}</td>
                                            <td class="parent-table-container">{{ row.item.diagnoses }}</td>
                                            <td class="parent-table-container">{{ row.item.presentingProblems }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">MH OP Services Received</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchMHServices"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea6" id="exportablePDFArea6">
                                <v-data-table dense :search="searchMHServices" :headers="sharedServicesHeadersOP" :items="dynamicServicesTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.episodeFormTypeDescription }}</td>
                                            <td class="parent-table-container">{{ row.item.fixedPlaceHours }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">MH Residential Services Received</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchMHServices"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea7" id="exportablePDFArea7">
                                <v-data-table dense :search="searchMHServices" :headers="sharedServicesHeadersRes" :items="dynamicServicesTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.episodeFormTypeDescription }}</td>
                                            <td class="parent-table-container">{{ row.item.fixedPlaceDays }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">SUD OP Services Received</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchSUDServices"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea8" id="exportablePDFArea8">
                                <v-data-table dense :search="searchSUDServices" :headers="sharedServicesHeadersOP" :items="dynamicServicesTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.episodeFormTypeDescription }}</td>
                                            <td class="parent-table-container">{{ row.item.fixedPlaceHours }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat>
                            <v-card-title class="black--text">SUD Residential Services Received</v-card-title>
                            <v-card-title>
                                <v-text-field
                                    v-model="searchSUDServices"
                                    append-icon="mdi-magnify"
                                    label="Search For Any Keyword In Any Row"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-card-text ref="exportablePDFArea9" id="exportablePDFArea9">
                                <v-data-table dense :search="searchSUDServices" :headers="sharedServicesHeadersRes" :items="dynamicServicesTableData" class="elevation-1">
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="parent-table-container">{{ row.item.episodeFormTypeDescription }}</td>
                                            <td class="parent-table-container">{{ row.item.fixedPlaceDays }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <v-btn class="white--text my-2" color="deep-purple accent-4" depressed @click="exportPdf">
                    Export Client Services Data
                    <v-icon right>
                        mdi-file-pdf-box
                    </v-icon>
                </v-btn>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="close">Finish</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>

<style scoped>
    .activeTabClass{
        color: blue !important;
    }
</style>
import { getInstance } from './index'
import store from '@/store/store'
import loginService from '@/services/loginService'
import reportService from '@/services/reportService'

function hasUserDto() {
    return !!store.state.auth.wybhdsUserDto
}

function isUserActive() {
    let userDto = store.state.auth.wybhdsUserDto || {}
    return userDto.isActive
}

function userHasRoleList() {
    let userDto = store.state.auth.wybhdsUserDto || {}
    //check if user has roleList or not
    return !!(userDto.roleList && userDto.roleList.length > 0)
}

function hasNames() {
    let userDto = store.state.auth.wybhdsUserDto || {}
    return userDto.firstName && userDto.lastName
}

// function hasAdminAccess () {
//     return userService.isAdministrator()
// }

export const authGuard = (to, from, next) => {
    const authService = getInstance()

    const mainCheck = () => {

        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            //loginService.testWcisApi()
            //loginService.wcisLogin()

            //check if wybhdsUserDto is loaded
            if (!store.state.auth.wybhdsUserDto) {
                store.dispatch('auth/authenticated').then(() => {
                    performLoginRecord();
                    return checkRoles()
                })
            } else {
                performLoginRecord();
                return checkRoles()
            }
        } else {
            // Otherwise, log in
            localStorage.setItem("loginOccured", 'true');
            authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
        }
    }

    const performLoginRecord = () => {
        let loginOccured = localStorage.getItem("loginOccured");
        if (loginOccured !== null && loginOccured === 'true') {
            reportService.setLoginRecord(authService.user);
            localStorage.removeItem("loginOccured");
        }
        return;
    }

    const checkRoles = () => {
        //if (!hasUserDto()) {
        //    return next('/login-error?error=server_error&error_description=cannot+obtain+user+information')
        //}
        //if (this.$route.query.error) {
        //    return next(`/login-error?error=${this.$route.query.error}&error_description=${this.$route.query.error_description}`)
        //}
        //if (!hasNames()) {
        //    return next('/request-access')
        //}


        if (!isUserActive() || !userHasRoleList()) {
            return next('/request-access')
        }
        if (to.meta && to.meta.requireRoles) {
            let requiredRoles = to.meta.requireRoles
            let userDto = store.state.auth.wybhdsUserDto || {}
            if (!(userDto.roleList && userDto.roleList.some(r => requiredRoles.includes(r)))) {
                alert('User does not have access.')
                return next('/')
            }
        }
        return next()
    }

    // If loading has already finished, check our auth state using `mainCheck()`
    if (!authService.loading) {
        return mainCheck()
    }

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch('loading', loading => {
        if (loading === false) {
            return mainCheck()
        }
    })
}

<template>
    <v-app id="app" :style="cssProps">
        <core-bar @toggleDrawer="toggleDrawer" :appBarIcon="appBarIcon" />
        <core-drawer v-if="drawer" />

        <v-main v-if="!$auth.loading" color="primary" :style="mainStyle">
            <v-alert :value="errorList.length > 0" text prominent dismissible elevation="2" type="error" icon="mdi-cloud-alert">
                <h5>Errors have occurred</h5>
                <v-divider></v-divider>
                <div v-for="error in errorList" :key="error.id">
                    {{ error.time }}<br />
                    {{ error.status }}{{ error.message }}
                </div>
            </v-alert>

            <div id="div-content">
                <router-view @toggleDrawer="toggleDrawer"/>
                <TimeoutModal v-if="this.$auth.isAuthenticated" />
            </div>
        </v-main>
    </v-app>
</template>

<script>
import coreBar from "@/components/core/Bar";
    import coreDrawer from "@/components/core/Drawer";
    import TimeoutModal from '@/components/TimeoutModal'
    import { mapState } from "vuex";

    import utilService from '@/services/util/utilService'

export default {
  //name: 'App',
    components: {
        coreBar,
        coreDrawer,
        TimeoutModal
    },
    data: () => ({
        drawer: true,
        drawerWidth: '220px'
    }),
    computed: {
        ...mapState("app", {
            httpRunning: (store) => store.httpRunning,
            errorList: "httpErrorList",
        }),
        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
        appBarIcon() {
            return this.drawer ? "mdi-close" : "mdi-menu"
        },
        mainStyle() {
            if (this.drawer) {
                return { paddingLeft: this.drawerWidth };
            } else {
                return { paddingLeft: '0px' };
            }
        },
        cssProps() {
            var themeColors = {};
            Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
                themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
            });
            return themeColors;
        },
        isIdle() {
            return this.$store.state.idleVue.isIdle;
        }
    },
    async created() {
        localStorage.setItem('AppActive', new Date())
        setInterval(() => {
            if (!this.isAppIdle) {
                localStorage.setItem('AppActive', new Date())
            }
        }, 1000)
    },
    mounted() {
    },
    methods: {
        errorMessageClear() {
            this.$store.commit("app/clearErrors");
        },
        toggleDrawer() {
            if (this.$route.name === 'provider-public-search') {
                this.drawer = false;
            } else {
                this.drawer = !this.drawer;
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}

.v-tabs .v-window__container {
  height: unset !important;
}

.v-tab {
    font-weight: 700 !important;
}

#div-content {
  background: var(--v-contentBackground);
  padding: 40px 30px;
}

.v-main__wrap {
  border: 4px solid #01275A;
}

#app {
  background: var(--v-contentBackground);

}

.selected {
  color: var(--v-primary);
  background-color: rgba(1, 29, 90, 0.12);
}

#app_drawer {
  // background-color: var(--v-background);
  margin-top: 10px;
  height: 100% !important;
  top: 90px !important;
  max-height: calc(100% - 90px) !important;
  width: 220px !important;

}

.bar-div {
  background-color: rgba(0, 0, 0, 0.5);
  height: max-content;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.v-main {
    padding-top: 100px !important;
}

.main-nav {
    margin: 0;
}

.main-nav .nav-group-title {
  padding: 0;
}

.main-nav .nav-group-item-title {
  padding-left: 30px;
}

.grid-selectable tr {
  cursor: pointer;
}

.v-card__title {
    color: var(--v-lightText);
}

#ifrm {
  border: none;
}

</style>

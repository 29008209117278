import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
 
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        //iconfont: 'md',  // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
        iconfont: 'mdi'
    },
    theme: {
        themes: {
            light: {
                primary: '#01275A',
                secondary: '#49713D',
                tertiary: '#495057',
                accent: '#82B1FF',
                error: '#FF4E4E',
                info: '#CA6800',
                success: '#7EBF65',
                warning: '#FF9100',
                background: "#01275A",
                testBackground: "#B71C1C",
                lightText: "#f2f2f2",
                navlinkActive: "#f2f2f2",
                text: '#525252',
                contentBackground: "#f2f2f2",
                mhHeader: '#388965',
                sudHeader: '#5a5aa2',
                mhResHeader: '#21523C',
                sudResHeader: '#363661',
                improvProviderHeader: '#015B52',
                improvAdminHeader: '#16015B'
            },
        },
    },
    
})

var requestpath = 'api/tsh_request/'
var episodepath = 'api/tsh_episode/'
var capacitypath = 'api/rahs_capacitychanges/'
var participantspath = 'api/tsh_agencyprogramparticipants/'

function getRequest(http, id) {
    return http.get(requestpath + id)
}

function getEpisodeAndRequestByIds(http, episodeId, requestId) {
    return http.get(`${requestpath}get-by-episode-and-request-id/${episodeId}/${requestId}`);
}

function getRequestsByClient(http, cl_wcisclientid) {
    return http.get(`${requestpath}getbyclient/${cl_wcisclientid}`)
}

function searchRequests(http, searchDto) {
    return http.post(`${requestpath}search`, searchDto)
}

function addToWaitlist(http, request) {
    return http.post(`${requestpath}addtowaitlist`, request)
}

function accept(http, request) {
    return http.post(`${requestpath}accept`, request)
}

function updateStatus(http, request) {
    return http.post(`${requestpath}updatestatus`, request)
}

function revertStatus(http, itemToModify) {
    return http.post(`${requestpath}revert-status`, itemToModify);
}

function editActionDates(http, itemToModify) {
    return http.post(`${requestpath}edit-action-dates`, itemToModify);
}

function getCurrentCapacity(http, searchDto) {
    return http.post(`${capacitypath}getcurrentcapacity`, searchDto)
}

function getLatestRequest(http) {
    return http.get(requestpath + 'get-latest')
}

function deleteByObject(http, dto) {
    console.log(dto);
    return http.post(requestpath + `delete-by-dto`, {trackingId: dto.tsH_EpisodeID, additionalIdentifyingInformation: dto.tsH_RequestID.toString()});
}

function getDeletedFormsByClientId(http, id) {
    return http.get(requestpath + `get-deleted-by-client-id/${id}`);
}

function restoreByObject(http, dto) {
    let dtosToRestore = new Array();
    console.log(dto);
    dto.forEach(x => {
        dtosToRestore.push({trackingId: x.episodeId, additionalIdentifyingInformation: x.requestId.toString()});

    });
    return http.post(requestpath + `restore-from-delete-by-dto`, dtosToRestore);
}

function getParticipantActivity(http, agencyId) {
    return http.get(`${participantspath}get-list-by-agency/${agencyId}`)
}

function saveParticipantActivity(http, dto) {
    return http.post(participantspath + 'save-participant-activity', dto)
}

export default {
    getRequest: getRequest,
    getRequestsByClient: getRequestsByClient,
    searchRequests: searchRequests,
    addToWaitlist: addToWaitlist,
    accept: accept,
    updateStatus: updateStatus,
    revertStatus: revertStatus,
    editActionDates: editActionDates,
    getCurrentCapacity: getCurrentCapacity,
    getLatestRequest: getLatestRequest,
    getEpisodeAndRequestByIds: getEpisodeAndRequestByIds,
    deleteByObject: deleteByObject,
    getDeletedFormsByClientId: getDeletedFormsByClientId,
    restoreByObject: restoreByObject,
    getParticipantActivity: getParticipantActivity,
    saveParticipantActivity: saveParticipantActivity
}
/* eslint-disable no-useless-escape */

import moment from 'moment'
import lodash from 'lodash'

var standardDateFormat = 'YYYY-MM-DD'
const timeOutCheck = 900000 // 15 minutes to count before idle prompt user. this counter doesn't start until user has been idle for 5 minutes (main.js)

function isAppIdle() {
    var lastActiveTime = new Date(localStorage.getItem('AppActive')) || new Date()
    //console.log('date difference', new Date() - lastActiveTime)
    return (new Date() - lastActiveTime >= timeOutCheck)
}

function isValidDate(value) {
    var m = moment(value)
    var isvalid = m.isValid()
    console.error(m.invalidAt())
    return isvalid
}

function convertToDate(value) {
    if (!value) {
        return null
    }

    if (value === '') {
        return null
    }

    var result = moment(value)
    return result
}

function formatDate(value) {
    var x = moment(value).format("MM/DD/YYYY")
    return x === 'Invalid date' ? '-' : x
}

function formatDateToJavascriptDate(value) {
    return moment(value).format("YYYY-MM-DD")
}

function formatDateWithDashes(value) {
    return moment(value).format("M-DD-YY")
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function getRemainingDaysInCurrentMonth() {
    let dateToday = new Date();
    let lastDayOfMonth = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0).getDate();
    return lastDayOfMonth - dateToday.getDate();
}

function formatCurrency(value, places) {
    return new Intl.NumberFormat('us-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: places,
        minimumFractionDigits: places,
    }).format(value)
}

function formatNumber(value, places) {
    return new Intl.NumberFormat('us-US', {
        maximumFractionDigits: places,
        minimumFractionDigits: places,
    }).format(value)
}

function formatDecimalToPercent(value, places) {
    return `${(value * 100).toFixed(places)}%`
}

function parseCurrencyToNumber(value) {
    return Number(value.replace(/[^0-9\.]+/g, ""))
}

function toJSONParsableDate(value) {
    var momentResult = convertToDate(value)
    return momentResult ? momentResult.format(standardDateFormat) : ''
}

function dateBetween(start, end, x, isStrict = true) {
    if (!start && !end) return true

    if (!end) {
        if (isStrict) return Date.parse(this.formatDate(x)) >= Date.parse(this.formatDate(start))
        else return Date.parse(this.formatDate(x)) > Date.parse(this.formatDate(start))
    }
    else {
        if (isStrict) return Date.parse(this.formatDate(x)) >= Date.parse(this.formatDate(start)) && Date.parse(this.formatDate(x)) <= Date.parse(this.formatDate(end))
        else return Date.parse(this.formatDate(x)) > Date.parse(this.formatDate(start)) && Date.parse(this.formatDate(x)) < Date.parse(this.formatDate(end))
    }
}

function getDateOfBirthFromClientId(value) {
    if (!value || !value.length)
        return ''

    if (value.length === 14)
        value = value.substring(3,10)

    var parsedyear = value.substring(4)
    var year = parseInt(parsedyear)
    var currentyear = parseInt(new Date().getFullYear().toString().substring(1))

    var dob_year = ''
    if (parsedyear == '000') dob_year = '2' + parsedyear
    else if (year > currentyear) dob_year = '1' + parsedyear
    else dob_year = '2' + parsedyear

    return value.substring(0,2) + '/' + value.substring(2,4) + '/' + dob_year
}

//function formatSSN(value) {
//    return 
//}

function getAppBarTimestamp() {
    return [moment().format("dddd, MMM Do YYYY"), moment().format("h:mm:ss a")]
}

function toStringNow() {
    return moment()
}

function flattenChildren(array) {
    const getChildren = (child) => {
        if (!child.children || !child.children.length) {
            return child
        }
        return [child, lodash.flatMapDeep(child.children, getChildren)]
    }

    return lodash.flatMapDeep(array, getChildren)
}

function flattenChildrenJS(array) {
    const getChildren = (child) => {
        let children = []
        const flatten = child.map(m => {
            if (m.children && m.children.length) {
                children = [...children, ...m.children]
            }
            return m
        })

        return flatten.concat(children.length ? getChildren(children) : children)
    }

    return getChildren(array)
}

function sortJSON(objArray, prop, direction) {
    if (arguments.length < 2) throw new Error("ARRAY, AND OBJECT PROPERTY MINIMUM ARGUMENTS, OPTIONAL DIRECTION");
    if (!Array.isArray(objArray)) throw new Error("FIRST ARGUMENT NOT AN ARRAY");
    const clone = objArray.slice(0);
    const direct = arguments.length > 2 ? arguments[2] : 1; //Default to ascending
    const propPath = (prop.constructor === Array) ? prop : prop.split(".");
    clone.sort(function (a, b) {
        for (let p in propPath) {
            if (a[propPath[p]] && b[propPath[p]]) {
                a = a[propPath[p]];
                b = b[propPath[p]];
            }
        }
        // convert numeric strings to integers
        //a = a.match(/^\d+$/) ? +a : a;
        //b = b.match(/^\d+$/) ? +b : b;
        return ((a < b) ? -1 * direct : ((a > b) ? 1 * direct : 0));
    });
    return clone;
}

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

function isObject (a) {
    return (!!a) && (a.constructor === Object);
}

//function isNumber(evt) {
//    evt = (evt) ? evt : window.event
//    var charCode = (evt.which) ? evt.which : evt.keyCode
//    if ((charCode < 48 && charCode != 45) || charCode > 57) evt.preventDefault()
//    return true
//}

function isNumber(evt, allowHyphen = true, allowPeriod = true) {
    evt = (evt) ? evt : window.event
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if ((charCode < 48 && ((charCode != 45 && allowHyphen) || (charCode != 46 && allowPeriod))) || charCode > 57) evt.preventDefault()
    //if ((charCode < 48 && (charCode != 45 || charCode != 46)) || charCode > 57) evt.preventDefault()
    return true
}

function isNumberStrict(evt) {
    evt = (evt) ? evt : window.event
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode < 48 || charCode > 57) evt.preventDefault()
    return true
}

function isAlpha(evt) {
    evt = (evt) ? evt : window.event
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode < 65) evt.preventDefault()
    if (charCode > 90 && charCode < 97) evt.preventDefault()
    if (charCode > 122) evt.preventDefault()
    return true
}

function isValidGender(evt) {
    evt = (evt) ? evt : window.event
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode == 70 || charCode == 77 || charCode == 85 || charCode == 88 || charCode == 102 || charCode == 109 || charCode == 117 || charCode == 120) return true
    evt.preventDefault()
}

function calculateAgeFromDOB(dob) {
    let today = new Date();
    let birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function calculateAge(start, end) {
    let startDate = new Date(start);
    let endDate = new Date(end);
    let age = endDate.getFullYear() - startDate.getFullYear();
    let m = endDate.getMonth() - startDate.getMonth();
    if (m < 0 || (m === 0 && endDate.getDate() < startDate.getDate())) {
        age--;
    }
    return age;
}

function vDataTableCustomSort(items, i, desc, isDate) {
    items.sort((a, b) => {
        if (a[i] != null && b[i] != null) {
            if (isDate) {
                if (!desc[0]) {
                    if (new Date(a[i]) == 'Invalid Date') return new Date(b[i]) - new Date()
                    if (new Date(b[i]) == 'Invalid Date') return new Date() - new Date(a[i])
                    return new Date(b[i]) - new Date(a[i])
                }
                else {
                    if (new Date(a[i]) == 'Invalid Date') return new Date() - new Date(b[i])
                    if (new Date(b[i]) == 'Invalid Date') return new Date(a[i]) - new Date()
                    return new Date(a[i]) - new Date(b[i])
                }
            }
            else {
                if (!desc[0]) return a[i].toString().toLowerCase().localeCompare(b[i].toString().toLowerCase())
                else return b[i].toString().toLowerCase().localeCompare(a[i].toString().toLowerCase())
            }
        }
    });
    return items
}

function isNullOrWhiteSpace(str) {
    return str === undefined || str === null || str.match(/^ *$/) !== null;
}

function validatePhoneNumber(str) {
    if (str?.match('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')) return true
    return false
}

function validateEmail(str) {
    var lastAtPos = str?.lastIndexOf('@')
    var lastDotPos = str?.lastIndexOf('.')
    return (lastAtPos < lastDotPos && lastAtPos > 0 && str?.indexOf('@@') == -1 && lastDotPos > 2 && (str?.length - lastDotPos) > 2)
}

const pluck = (arr, key) => arr.map(i => i[key])

export default {
    isAppIdle: isAppIdle,
    isValidDate: isValidDate,
    convertToDate: convertToDate,
    formatDate: formatDate,
    formatDateToJavascriptDate: formatDateToJavascriptDate,
    formatDateWithDashes: formatDateWithDashes,
    addDays: addDays,
    getRemainingDaysInCurrentMonth: getRemainingDaysInCurrentMonth,
    formatCurrency: formatCurrency,
    formatNumber: formatNumber,
    formatDecimalToPercent: formatDecimalToPercent,
    parseCurrencyToNumber: parseCurrencyToNumber,
    toJSONParsableDate: toJSONParsableDate,
    dateBetween: dateBetween,
    getDateOfBirthFromClientId: getDateOfBirthFromClientId,
    getAppBarTimestamp: getAppBarTimestamp,
    toStringNow: toStringNow,
    flattenChildren: flattenChildren,
    sortJSON: sortJSON,
    delay: delay,
    isObject: isObject,
    isNumber: isNumber,
    isNumberStrict: isNumberStrict,
    isAlpha: isAlpha,
    isValidGender: isValidGender,
    calculateAgeFromDOB: calculateAgeFromDOB,
    calculateAge: calculateAge,
    vDataTableCustomSort: vDataTableCustomSort,
    isNullOrWhiteSpace: isNullOrWhiteSpace,
    validatePhoneNumber: validatePhoneNumber,
    validateEmail: validateEmail,
    pluck: pluck
}
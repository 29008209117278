function log(isDebug, source, message) {
    if (isDebug) {
        console.log(source + ' - ' + message)
    }
}

function logRaw(isDebug, object) {
    if (isDebug) {
        console.log(object)
    }
}

function error(source, message) {
    log(true, source, message)
}

export default {
    log: log,
    logRaw: logRaw,
    error: error
}
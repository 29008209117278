/**
 * App Module
 */
/* eslint-disable */
import { set, toggle, addToList, clearList } from '@/utils/vuex'

const state = {
    drawer: null,
    color: 'success',
    image: '/img/sidebar_bg_teton_1.jpg',
    sidebarBackgroundColor: 'rgba(27, 27, 27, 0.74)',
    httpRunning: false,
    httpErrorList: []
}

const getters = {}
const actions = {}

const mutations = {
    setDrawer: set('drawer'),
    setImage: set('image'),
    setColor: set('color'),
    toggleDrawer: toggle('drawer'),
    setHttpRunning: set('httpRunning'),
    setHttpErrorList: set('httpErrorList'),
    addError: addToList('httpErrorList'),
    clearErrors: clearList('httpErrorList')
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
var path = 'api/fmt_crisis/'

function save(http, dto) {
    return http.post(path, dto)
}

function getExistingFormById(http, id) {
    return http.get(path+`get-by-id/${id}`);    
}

function deleteByObject(http, dto) {
    return http.post(path + `delete-by-dto`, {trackingId: dto.fmT_EDBSID, additionalIdentifyingInformation: null});
}

function getDeletedFormsByClientId(http, id) {
    return http.get(path + `get-deleted-by-client-id/${id}`);
}

function restoreByObject(http, dto) {
    let dtoArray = new Array();
    dto.forEach(x => {
        dtoArray.push({trackingId: x, additionalIdentifyingInformation: null});

    });
    return http.post(path + `restore-from-delete-by-dto`, dtoArray);
}

function getRelatedForms(http, cl_wcisclientid) {
    return http.get(`${path}get-related-forms/${cl_wcisclientid}`)
}

function getRelatedFormsByEdbsId(http, edbsClientId) {
    return http.get(`${path}get-related-forms-by-edbs-id/${edbsClientId}`);
}

export default {
    save: save,
    getExistingFormById: getExistingFormById,
    deleteByObject: deleteByObject,
    getDeletedFormsByClientId: getDeletedFormsByClientId,
    restoreByObject: restoreByObject,
    getRelatedForms: getRelatedForms,
    getRelatedFormsByEdbsId: getRelatedFormsByEdbsId,
}
<script>
import utilService from '@/services/util/utilService';
import { uuid } from 'vue-uuid';

export default{
    data: () => ({
        childAuditCopy: [],
        auditHeaders: [{text: "Audit Action", value: "auditAction"}, {text: "Date Of Change", value: "dateOfChange"}, {text: "Change User", value: "changeUser"}, {text: "Annual Income", value: "annualIncome"}, {text: "Co-Occurring Client", value: "coOccurringClient"}, {text: "County of Origin", value: "countyOfOrigin"},
                {text: "County Of Residence", value: "countyOfResidence"}, {text: "Criminal Justice", value: "criminalJustice"}, {text: "Education", value: "education"}, {text: "Employment", value: "employment"}, {text: "Hispanic Origin", value: "hispanicOrigin"},
                {text: "Juvenile Court", value: "juvenileCourt"}, {text: "Marital Status", value: "maritalStatus"}, {text: "Medicaid Number", value: "medicaidNumber"}, {text: "MH Population", value: "mhPopulation"}, {text: "Parole", value: "parole"},
                {text: "Primary Income Source", value: "primaryIncomeSource"}, {text: "Probation", value: "probation"}, {text: "Race", value: "race"}, {text: "Residence", value: "residence"}, {text: "SA Age At First Use", value: "saAgeAtFirstUse"},
            { text: "SA Female", value: "saFemale" }, { text: "SUD Persons Who Inject Drugs (PWID)", value: "saivDrugUser"}, {text: "Veteran Status", value: "veteranStatus"}, {text: "Zip Code", value: "zipCode"}],
                searchAuditHistory: "",
    }),
    methods: {
        close() {
            this.$emit("close-dialog");
        },
    },
    props: {
            parentAudit: Array,
            dialog: Boolean,
        },
    computed: {
        
    },
    created() {
        //This creates a deep copy of the original parent object. Probably not neccessary since we are only viewing information and not modifying.
        this.childAuditCopy = JSON.parse(JSON.stringify(this.parentAudit));
        this.childAuditCopy.forEach(x => {
            x = {...x, ...{auditId: uuid.v4}};
            x = {...x, ...{dateofChange: utilService.convertToDate(x.dateofChange)}};
        });
    }
}
</script>



<template>
    <v-dialog :value="dialog" @click:outside="close" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="1200" max-height="1200">
        <v-card>
            <v-toolbar color="orange lighten-4" dark class="black--text">Client Audit History</v-toolbar>
            <v-spacer></v-spacer>
            <v-card-title>
                <v-text-field
                    v-model="searchAuditHistory"
                    append-icon="mdi-magnify"
                    label="Search Audit History"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text class="mt-6">
                <v-data-table dense :headers="auditHeaders" :items="childAuditCopy" :search="searchAuditHistory" item-key="auditId" class="elevation-1">
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="close">Close</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
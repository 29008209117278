import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import ClientSummary from '@/views/ClientSummary.vue';
import { authGuard } from '@/auth/authGuard'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            beforeEnter: authGuard
        },
        {
            path: '/power-bi-reports',
            name: 'powerbi',
            component: () => import('@/views/PowerBiReportsEmbed.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/login-error',
            name: 'login-error',
            component: () => import('@/views/LoginError.vue'),
        },
        {
            path: '/request-access',
            name: 'request-access',
            component: () => import('@/views/RequestAccess.vue'),
            //beforeEnter: authGuard
        },
        {
            path: '/callback',
            name: 'callback',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '@/views/Callback.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/about',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
        },
        {
            path: '/contact-us',
            name: 'contact-us',
            component: () => import(/* webpackChunkName: "about" */ '@/views/ContactUs.vue')
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/Profile.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/task-list',
            name: 'task-list',
            component: () => import('@/views/TaskList.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/document-library',
            name: 'document-library',
            component: () => import('@/views/DocumentLibrary.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/client-list',
            name: 'client-list',
            component: () => import('@/views/ClientList.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/client',
            name: 'client',
            component: () => import('@/views/Client.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/client-summary',
            name: 'client-summary',
            component: ClientSummary,
            beforeEnter: authGuard
        },
        {
            path: '/client-merge',
            name: 'client-merge',
            component: () => import('@/views/admin/ClientMerge.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/rahs',
            name: 'rahs',
            component: () => import('@/views/Rahs.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/rahs-acceptance',
            name: 'rahs-acceptance',
            component: () => import('@/views/RahsAcceptance.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin', 'RaHS User', 'State User']
            }
        },
        {
            path: '/mis',
            name: 'mis',
            component: () => import('@/views/MIS.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/esr',
            name: 'esr',
            component: () => import('@/views/ESR.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/crisis',
            name: 'crisis',
            component: () => import('@/views/Crisis.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/carf',
            name: 'carf',
            component: () => import('@/views/Carf.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/sentinel-event',
            name: 'sentinel-event',
            component: () => import('@/views/SentinelEvent.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/leadership-change',
            name: 'leadership-change',
            component: () => import('@/views/LeadershipChange.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/upload',
            name: 'upload',
            component: () => import('@/views/Upload.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/invoice',
            name: 'invoice',
            component: () => import('@/views/Invoice.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/qol',
            name: 'qol',
            component: () => import('@/views/QOL.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import('@/views/Reports.vue'),
            beforeEnter: authGuard
            //component: () => import('@/views/Reports.vue'),
            //beforeEnter: authGuard
            // beforeEnter(to, from, next) {
            //     window.location.href = "https://localhost:44324/Reports/Default.aspx"
            // }
        },
        {
            path: '/request-access-review',
            name: 'request-access-review',
            component: () => import('@/views/admin/RequestAccessReview.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/user-admin',
            name: 'user-admin',
            component: () => import('@/views/admin/Users.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/agencygroup-admin',
            name: 'agencygroup-admin',
            component: () => import('@/views/admin/AgencyGroup.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/agency-admin',
            name: 'agency-admin',
            component: () => import('@/views/admin/Agency.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/rahs-configuration',
            name: 'rahs-configuration',
            component: () => import('@/views/admin/RahsConfiguration.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/agencygroup-merge',
            name: 'agencygroup-merge',
            component: () => import('@/views/admin/AgencyGroupMerge.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/invoice-generate',
            name: 'invoice-generate',
            component: () => import('@/views/admin/InvoiceGenerate.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/invoice-view',
            name: 'invoice-view',
            component: () => import('@/views/admin/InvoiceView.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/invoice-contracts',
            name: 'invoice-contracts',
            component: () => import('@/views/admin/InvoiceContracts.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/invoice-edit',
            name: 'invoice-edit',
            component: () => import('@/views/admin/InvoiceEdit.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/teds',
            name: 'teds',
            component: () => import('@/views/admin/TEDS.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/mhdig',
            name: 'mhdig',
            component: () => import('@/views/admin/MHDIG.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/email-configuration',
            name: 'email-configuration',
            component: () => import('@/views/admin/EmailConfiguration.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/report-management',
            name: 'report-management',
            component: () => import('@/views/admin/ReportManagement.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/report-access',
            name: 'report-access',
            component: () => import('@/views/admin/ReportAccess.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/accuracy',
            name: 'accuracy',
            component: () => import('@/views/Accuracy.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/form-entry',
            name: 'form-entry',
            component: () => import('@/views/FormEntry.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/training-forms',
            name: 'training-forms',
            component: () => import('@/views/TrainingForms.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/provider-bulletin',
            name: 'provider-announcements',
            component: () => import('@/views/ProviderAnnouncements.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/provider-search',
            name: 'provider-search',
            component: () => import('@/views/improv/ProviderSearch.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/provider-public-search',
            name: 'provider-public-search',
            component: () => import('@/views/improv/ProviderPublicSearch.vue'),
        },
        {
            path: '/provider-profile',
            name: 'provider-profile',
            component: () => import('@/views/improv/ImprovMasterSelections.vue'),
            beforeEnter: authGuard,
        },
        {
            path: '/provider-application',
            name: 'provider-application',
            component: () => import('@/views/improv/ProviderApplication.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin', 'Provider', 'State User']
            }
        },
        {
            path: '/provider-renewal',
            name: 'provider-renewal',
            component: () => import('@/views/improv/ProviderRenewal.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin', 'Provider', 'State User']
            }
        },
        {
            path: '/provider-update',
            name: 'provider-update',
            component: () => import('@/views/improv/ProviderUpdate.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin', 'Provider', 'State User']
            }
        },
        {
            path: '/improv-task-list',
            name: 'improv-task-list',
            component: () => import('@/views/improv/TaskList.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin', 'Provider', 'State User']
            }
        },
        {
            path: '/letter-templates',
            name: 'letter-templates',
            component: () => import('@/views/admin/LetterTemplates.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/email-templates',
            name: 'email-templates',
            component: () => import('@/views/admin/EmailTemplates.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin']
            }
        },
        {
            path: '/reporting-forms',
            name: 'reporting-forms',
            component: () => import('@/views/ReportingForms.vue'),
            beforeEnter: authGuard,
            meta: {
                requireRoles: ['State Admin', 'State User', 'Agency Group User']
            }
        }
    ]
})

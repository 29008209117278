<script>
import enumService from '@/services/util/enumService';
import { clientSummaryService } from '@/services/clientSummaryService';
import utilService from '../services/util/utilService';
import userService from '@/services/userService'

export default{
    data: () => ({
        isFormValid: false,
        childClientCopy: {},
        requiredFieldRules: [
            value => !!value || 'This field is REQUIRED.',
        ],
        cities: [],
        states: [],
        countries: [],
        genders: [],
        isSSNFieldDisabled: true,
        areAlternateFieldsFilled: false,
    }),
    methods: {
        close() {
            this.$emit("close-dialog");
        },
        save() {
            this.isSSNFieldDisabled = true;
            this.$emit("save-dialog", {modifiedClient: this.childClientCopy});
        },
        editSSN() {
            clientSummaryService.getFieldForEditing(this.$http, this.childClientCopy.cL_WCISClientID).then(response => {
                this.childClientCopy.ssn = response.data;
                this.isSSNFieldDisabled = false;
            });
        },
        ssnFieldRules(value) {
            if (!this.isSSNFieldDisabled && !this.areAlternateFieldsFilled) {
                const digitPattern = /^\d+$/;
                if (value.toString().length !== 9 && value.toString().length !== 0) {
                    return 'This field must contain exactly nine numbers.';
                }
                if (!digitPattern.test(value) && value.toString().length !== 0) {
                    return 'This field must contain only numbers.';
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        },
        //Server side validation should take care of this, but if more is needed, this will work with a few tweaks.
        /*checkIfAlternateFieldsRequired(value) {
            let lastFour = this.childClientCopy.clientID.slice(this.childClientCopy.clientID.length-4, this.childClientCopy.clientID.length);
            if ((!this.isSSNFieldDisabled && this.childClientCopy.ssn !== null && this.childClientCopy.ssn.length !== 9) || lastFour === "0000") {
                if (this.childClientCopy.mothersFirstName && this.childClientCopy.mothersFirstName.length > 0 && this.childClientCopy.luBirthCityID && this.childClientCopy.luBirthStateID && this.childClientCopy.luBirthCountryID) {
                    this.areAlternateFieldsFilled = true;
                    this.ssnFieldRules(null)
                    return true;
                }
                else {
                    this.areAlternateFieldsFilled = false;
                    return "This field is required when a SSN is not present.";
                }
            }
            else {
                return true;
            }
        },*/
    },
    props: {
            parentClient: Object,
            dialog: Boolean,
        },
    computed: {
        isAdmin: function () {
            return (this.wybhdsUserDto && userService.isAdministrator())
        },
    },
    created() {
        //This creates a deep copy of the original parent object so that it can be modified, validated, and sent back to the parent view, and server for saving.
        //If you need to create a new client, just pass in an empty object i.e. '{}'
        this.childClientCopy = JSON.parse(JSON.stringify(this.parentClient));
        this.cities = enumService.getCities();
        this.states = enumService.getStates();
        this.countries = enumService.getCountries();
        this.genders = enumService.getGenderList();
        this.childClientCopy.dateOfBirth = utilService.formatDateToJavascriptDate(this.childClientCopy.dateOfBirth);
    }
}
</script>



<template>
    <v-dialog :value="dialog" @click:outside="close" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="600">
        <v-card>
            <v-toolbar color="orange lighten-4" dark class="black--text">{{Object.keys(childClientCopy).length > 0 ? "Edit Client" : "Create Client"}}</v-toolbar>
            <v-spacer></v-spacer>
            <v-card-text class="mt-6">
                <v-form v-model="isFormValid">
                    <v-text-field v-model="childClientCopy.clientID" :label="'Client ID'" :rules="requiredFieldRules"></v-text-field>
                    <v-text-field v-model="childClientCopy.dateOfBirth" :label="'Date of Birth'" :rules="requiredFieldRules" type="date"></v-text-field>
                    <v-select v-model="childClientCopy.luGenderID" :items="genders" item-value="luGenderID" item-text="description" label="Sex" :rules="requiredFieldRules"></v-select>
                    <div class="d-flex">
                        <v-text-field v-model="childClientCopy.ssn" :label="'SSN'" :disabled="isSSNFieldDisabled" :rules="[ssnFieldRules]"></v-text-field>
                        <v-btn text color="deep-purple accent-4" class="mt-4" @click="editSSN">Edit SSN</v-btn>
                    </div>
                    <v-text-field v-model="childClientCopy.medicaidID" label="Medicaid ID"></v-text-field>
                    <v-text-field v-model="childClientCopy.mothersFirstName" :label="`Mother's First Name`"></v-text-field>
                    <v-select v-model="childClientCopy.luBirthCityID" :items="cities" item-value="luBirthCityID" item-text="description" label="Birth City" :clearable=true ></v-select>
                    <v-select v-model="childClientCopy.luBirthStateID" :items="states" item-value="luBirthStateID" item-text="description" label="Birth State" :clearable=true ></v-select>
                    <v-select v-model="childClientCopy.luBirthCountryID" :items="countries" item-value="luBirthCountryID" item-text="description" label="Birth Country" :clearable=true ></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="save" :disabled="!isFormValid">Save</v-btn>
                <v-btn text color="deep-purple accent-4" class="mx-4" @click="close">Cancel</v-btn>
            </v-card-actions>
        </v-card>        
    </v-dialog>
</template>
/* eslint-disable */
import Vue from 'vue'

// Lib imports
//import AppConfig from '../../public/static/appconfig'
import axios from 'axios'
import store from '@/store/store'
import utilService from '@/services/util/utilService'

var instance = axios.create({ baseURL: AppConfig.bhmsapiurl })

instance.interceptors.request.use(async config => {
    store.commit('app/setHttpRunning', true)
    if (Vue.prototype.$auth.isAuthenticated) {
        const token = await Vue.prototype.$auth.getTokenSilently()
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
    store.commit('app/setHttpRunning', false)
    store.commit('app/clearErrors')
    return response
}, function (error) {
    store.commit('app/setHttpRunning', false)
    var errorObject = {
        //id: _.uniqueId('error_'),
        time: utilService.toStringNow(),
        //url: error.config.url.replace(error.config.baseURL, ''),
        status: error.response ? error.response.status + ' - ' : '',
        message: error.message,
        stack: error.stack
    }
    store.commit('app/addError', errorObject)
    return Promise.reject(error)
})

var aspnet_instance = axios.create({ baseURL: AppConfig.wcisapiurl })

aspnet_instance.interceptors.request.use(async config => {
    store.commit('app/setHttpRunning', true)
    if (Vue.prototype.$auth.isAuthenticated) {
        const token = await Vue.prototype.$auth.getTokenSilently()
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

aspnet_instance.interceptors.response.use(function (response) {
    store.commit('app/setHttpRunning', false)
    store.commit('app/clearErrors')
    return response
}, function (error) {
    store.commit('app/setHttpRunning', false)
    var errorObject = {
        id: _.uniqueId('error_'),
        time: utilService.toStringNow(),
        url: error.config.url.replace(error.config.baseURL, ''),
        status: error.response ? error.response.status + ' - ' : '',
        message: error.message,
        stack: error.stack
    }
    store.commit('app/addError', errorObject)
    return Promise.reject(error)
})

var blob_instance = axios.create({
    baseURL: AppConfig.bhmsapiurl,
    responseType: 'blob'
})

blob_instance.interceptors.request.use(async config => {
    store.commit('app/setHttpRunning', true)
    if (Vue.prototype.$auth.isAuthenticated) {
        const token = await Vue.prototype.$auth.getTokenSilently()
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

blob_instance.interceptors.response.use(function (response) {
    store.commit('app/setHttpRunning', false)
    store.commit('app/clearErrors')
    return response
}, function (error) {
    store.commit('app/setHttpRunning', false)
    var errorObject = {
        //id: _.uniqueId('error_'),
        time: utilService.toStringNow(),
        //url: error.config.url.replace(error.config.baseURL, ''),
        status: error.response ? error.response.status + ' - ' : '',
        message: error.message,
        stack: error.stack
    }
    store.commit('app/addError', errorObject)
    return Promise.reject(error)
})


Vue.prototype.$http = instance
Vue.prototype.$httpRunning = false

Vue.prototype.$http_aspnet = aspnet_instance
Vue.prototype.$http_aspnet_Running = false

Vue.prototype.$http_blob = blob_instance
Vue.prototype.$http_blob_Running = false

import utilService from '@/services/util/utilService';

const clientSummaryPath = 'api/clientsummary/';

class ClientSummaryService {
    constructor() {}

    getClientForClientSummary(http, id) {
        return http.get(`${clientSummaryPath}get-summary-client/${id}`);
    }

    getCrisisClientForClientSummary(http, id) {
        return http.get(`${clientSummaryPath}get-crisis-summary-client/${id}`);
    }

    getClientUniqueFormsHistoryByClientId(http, id) {
        return http.get(`${clientSummaryPath}client-unique-forms-history/${id}`);
    }

    getClientCrisisInformation(http, id) {
        return http.get(`${clientSummaryPath}client-crisis-forms-history/${id}`);
    }

    getClientEpisodeInformation(http, id) {
        return http.get(`${clientSummaryPath}episode-information-by-client-id/${id}`); 
    }

    getClientRAHSEpisodeInformation(http, id) {
        return http.get(`${clientSummaryPath}rahs-information-by-client-id/${id}`);
    }

    getClientAuditHistory(http, id) {
        return http.get(`${clientSummaryPath}client-audit-by-client-id/${id}`);
    }

    getFieldForEditing(http, id) {
        return http.get(`${clientSummaryPath}get-field-for-edit/${id}`);
    }

    getFYEnum(http) {
        return http.get(`${clientSummaryPath}get-fy-enum`);
    }

    getFormLockConfig(http) {
        return http.get(`${clientSummaryPath}get-form-lock-config`);
    }

    getFullDLA20HistoryByMISIds(http, misIds) {
        return http.get(`${clientSummaryPath}get-full-DLA-history-by-mis-ids/${misIds}`);
    }

    updateClientFromClientSummary(http, dto, isCrisis) {
        dto.dateOfBirth = utilService.toJSONParsableDate(dto.dateOfBirth);
        if (isCrisis) {
            return http.post(`${clientSummaryPath}update-crisis-client`, dto);
        }
        else if (!isCrisis) {
            return http.post(`${clientSummaryPath}update-client`, dto);
        }
    }

    addProviderClientFromClientSummary(http, dto, isCrisis) {
        if (isCrisis) {
            return http.post(`${clientSummaryPath}add-crisis-provider`, dto);
        }
        else if (!isCrisis) {
            return http.post(`${clientSummaryPath}add-provider`, dto);
        }
    }

    editProviderClientFromClientSummary(http, dto, isCrisis) {
        if (isCrisis) {
            return http.post(`${clientSummaryPath}edit-crisis-provider`, dto);
        }
        else if (!isCrisis) {
            return http.post(`${clientSummaryPath}edit-provider`, dto);
        }
    }

    deleteByProviderObject(http, dto, isCrisis) {
        if (isCrisis) {
            return http.post(`${clientSummaryPath}delete-crisis-provider-by-dto`, {trackingId: dto.cL_ProviderEDBSClientID, additionalIdentifyingInformation: null});
        }
        else if (!isCrisis) {
            return http.post(`${clientSummaryPath}delete-provider-by-dto`, {trackingId: dto.cL_ProviderClientID, additionalIdentifyingInformation: null});
        }
    }
}

export const clientSummaryService = new ClientSummaryService();
<script lang="js">
  import userService from '@/services/userService';
  import reportService from '@/services/reportService'
  import store from '@/store/store';
  import { mapState } from 'vuex';

  export default  {
    name: 'individual-dla20-report',
    created() {
      
    },
    mounted() {

    },
    components:{
      
    },
    props: {
        rawDLA20Data: {
            type: Array,
            required: true
        },
        episodeData: {
            type: Array,
            required: true,
        },
        episodeFormsData: {
            type: Array,
            required: true,
        },
        clientId: {
            type: String,
            required: true,
        },
        dialog: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        $props: {
            handler() {
                this.onHandlerEvent();
            },
            deep: true,
            immediate: true
        },
    },
    data() {
      return {
        currentTabDlaEntryCount: 0,
        childDLA20Data: [],
        sharedDlaTabData: [],
        childEpisodeInfoToDisplay: [],
        dynamicCollapseAndExpandHeaderSet: [],
        sharedDlaHeaders: [{ text: "DLA 20 Questions", value: "metric", class: "parent-table-container" }],
        //Json key references from the API are mapped to what the metric labels should be in the data table. Do not ask me how long I tried to make this dyanmic.
        metricLabelSet: [{healthPracticesResponse: "Health Practices"}, {housingResponse: "Housing Stability"}, {communicationResponse: "Communication"}, {safetyResponse: "Safety"}, {timeResponse: "Managing Time"},
                                 {moneyResponse: "Managing Money"}, {nutritionResponse: "Nutrition"}, {problemSolvingResponse: "Problem Solving"}, {familyResponse: "Family Relationships"}, {alcoholDrugResponse: "Alcohol/Drug Use"},
                                 {leisureResponse: "Leisure"}, {communityResponse: "Community Resources"}, {socialResponse: "Social Network"}, {sexualityResponse: "Sexuality"}, {productivityResponse: "Productivity"}, {copingResponse: "Coping Skill"},
                                 {behavioralResponse: "Behavioral Norms"}, {hygieneResponse: "Personal Hygiene"}, {groomingResponse: "Grooming"}, {dressResponse: "Dress"}, {totalScore: "Total Score"}, {score: "Average Score" }],
        tabs: null
        
      }
    },
    methods: {
        onHandlerEvent() {
            this.childDLA20Data = JSON.parse(JSON.stringify(this.rawDLA20Data));
            if (this.episodeData && this.episodeData.length > 0) {
                this.childEpisodeInfoToDisplay = JSON.parse(JSON.stringify(this.episodeData));                
            }
            if (this.episodeFormsData && this.episodeFormsData.length > 0) {
                this.childDLA20Data.forEach(x => {
                    x.createDate = this.episodeFormsData.find(e => e.formID === x.fmT_MISID).dateOfForm;
                });
            }
            if (this.dialog) {
                this.mutateData(0);
            }
        },
        finish() {
            this.$emit("close-dla-dialog");
        },
        async mutateData(tabIndex) {
            return new Promise((resolve, reject) => {
                try {
                    this.sharedDlaTabData = new Array();
                    //First, cross reference form ids with existing forms object in relation to what episode has been selected
                    let relevantFormIds = this.episodeFormsData.filter(x => x.episodeId === this.childEpisodeInfoToDisplay[tabIndex].episodeID).flatMap(y => y.formID);
                    //Then, find the MIS ids that match the recovered form ids in our previous step
                    let relevantDLAObjects = this.childDLA20Data.filter(x => relevantFormIds.includes(x.fmT_MISID));
                    //Acquire all DLA response keys, use them to calculate average of all responses
                    let responseKeys = Object.keys(relevantDLAObjects[0]).filter(e => e.includes("Response"));

                    for (const [idx, value] of relevantDLAObjects.entries()) {
                        let accumulator = 0;
                        responseKeys.forEach(x => {
                            accumulator += value[x];
                        })
                        value.totalScore = accumulator;
                    }
                    responseKeys.push("totalScore", "score");

                    //This is used to set the default rows per page upon first table load
                    this.currentTabDlaEntryCount = relevantDLAObjects.length;
                    //Now sort by date, ASC
                    relevantDLAObjects.sort((a,b) => {return new Date(b.formDate) - new Date(a.formDate)});
                    //Reset the headers, and resize depending on how many DLA entries we have.
                    this.sharedDlaHeaders.splice(1);
                    for (let i = 0; i < relevantDLAObjects.length; i++) {
                        this.sharedDlaHeaders.push({text: relevantDLAObjects[i].transactionType + " Score " + relevantDLAObjects[i].createDate, value: "score"+i.toString(), class: "parent-table-container"});
                    }
                    this.sharedDlaHeaders.push({text: "Overall Average", value: "rowAverage", class: "parent-table-container"});

                    //Get deep clone of newly created header array for collapse and expand operations
                    this.dynamicCollapseAndExpandHeaderSet = JSON.parse(JSON.stringify(this.sharedDlaHeaders));

                    for (const [idx, value] of responseKeys.entries()) {
                        let metricPiece = {"metric": this.metricLabelSet[idx][value]};
                        let responsePiece = {};
                        let responseRowAccumulator = 0;
                        for (const [dlaIdx, dlaValue] of relevantDLAObjects.entries()) {
                            let scoreObj = {"rawScore": "", "cellColorCode": "#FFFFFF"};
                            scoreObj["rawScore"] = dlaValue[value];
                            responseRowAccumulator += dlaValue[value];
                            //For the IMPERATIVE cell color shading -__-
                            //Just perform a -1 lookback on each previous value of the row and see if it has improved or declined.
                            if (responsePiece["score"+(dlaIdx-1).toString()] != undefined) {
                                if (scoreObj["rawScore"] > responsePiece["score"+(dlaIdx-1).toString()].rawScore) {
                                    scoreObj["cellColorCode"] = "#00FF00";
                                }
                                else if (scoreObj["rawScore"] < responsePiece["score"+(dlaIdx-1).toString()].rawScore) {
                                    scoreObj["cellColorCode"] = "#FFFF00";
                                }
                            }
                            responsePiece["score"+dlaIdx.toString()] = scoreObj;
                        }
                        responsePiece.rowAverage = (responseRowAccumulator/relevantDLAObjects.length).toFixed(2);
                        //Essentially concats both objects as table data that can be displayed in the fashion that BMHS requires.
                        this.sharedDlaTabData.push({...metricPiece, ...responsePiece});
                    }
                    resolve();
                }
                catch(error) {
                    reject(error);
                    console.error(error);
                }
            }); 
        },
        collapseHeaderColumns() {
            if (this.dynamicCollapseAndExpandHeaderSet.length === 0) {
                return; 
            }

            this.dynamicCollapseAndExpandHeaderSet.pop();
        },
        expandHeaderColumns() {
            if (this.dynamicCollapseAndExpandHeaderSet.length === this.sharedDlaHeaders.length) {
                return;
            }

            this.dynamicCollapseAndExpandHeaderSet.push(this.sharedDlaHeaders[this.dynamicCollapseAndExpandHeaderSet.length]);
        },
        generateNameBasedOnActiveClient() {
            return "Individual_Client_DLA20_Report_For_" + this.clientId + "_"+new Date().toLocaleDateString();
        },
        async exportPdf() {
            let exportableContent = "";
            for (let i = 0; i < this.childEpisodeInfoToDisplay.length; i++) {
                await this.mutateData(i);
                exportableContent += `<h4>${this.childEpisodeInfoToDisplay[i].agencyCode} - ${this.childEpisodeInfoToDisplay[i].agencyName}: Episode ${this.childEpisodeInfoToDisplay[i].episodeNumber}</h4>`
                exportableContent += this.$refs[`exportablePDFArea${this.childEpisodeInfoToDisplay[i].episodeID}`][0].outerHTML;
            }
            await this.mutateData(0);
            this.tabs = 0;
            let documentName = this.generateNameBasedOnActiveClient();
            reportService.generateIndividualDLA20PDF(this.$http, this.clientId, exportableContent).then(pdfResponse => {
                const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = documentName + '.pdf';
                link.click();
                URL.revokeObjectURL(link.href);
            });
            return;
        }
    },
    computed: {
      ...mapState('auth', ['wybhdsUserDto']),
      isAdmin: function () {
        return (this.wybhdsUserDto && userService.isAdministrator())
      },
    }
}
</script>

<template lang="html">
    <v-dialog :value="dialog" @click:outside="finish" @input="$emit('input', $event)" transition="dialog-top-transition" max-width="2200" max-height="2200">
        <v-container :fluid=true>
            <v-card>
                <v-card-title class="black--text">{{"Client ID " + this.clientId }}</v-card-title>
                <v-toolbar dark flat prominent color="orange lighten-4" class="black--text mt-8" :height="105">
                    <v-tabs centered dark slider-color="deep-purple accent-4" v-model="tabs" class="pa-6" :height="85" @change="mutateData($event)" active-class="activeTabClass">
                        <v-tab style="color: black" v-for="episode in childEpisodeInfoToDisplay" :key="episode.episodeID">
                            <v-icon left>mdi-chart-box-outline</v-icon>
                            {{episode.agencyCode + " - " + episode.agencyName + ": " + "Episode " + episode.episodeNumber}}
                        </v-tab>
                    </v-tabs>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="collapseHeaderColumns()">
                        <v-icon>mdi-arrow-expand-left</v-icon>
                    </v-btn>
                    <v-btn icon @click="expandHeaderColumns()" :disabled="dynamicCollapseAndExpandHeaderSet.length === sharedDlaHeaders.length">
                        <v-icon>mdi-arrow-expand-right</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-tabs-items v-model="tabs">
                    <v-tab-item v-for="episodeTab in childEpisodeInfoToDisplay" :key="episodeTab.episodeID" eager>
                        <v-card flat>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on ,attrs }">
                                    <v-card-title v-bind="attrs" v-on="on" class="black--text">{{episodeTab.agencyCode + " - " + episodeTab.agencyName + ": " + "Episode " + episodeTab.episodeNumber}}</v-card-title>
                                </template>
                                <span>Green highlighting signifies an improvement from the previous DLA score, while yellow highlighting signifies a decrease from the previous DLA score</span><br> 
                            </v-tooltip>
                            <v-card-text :ref="'exportablePDFArea' + episodeTab.episodeID.toString()" :id="'exportablePDFArea' + episodeTab.episodeID.toString()">
                                <v-data-table dense :headers="dynamicCollapseAndExpandHeaderSet" :items="sharedDlaTabData" :items-per-page="metricLabelSet.length" class="elevation-1">
                                    <template v-slot:[`item.metric`]="{ item }">
                                        <td class="parent-table-container">{{ item[`metric`] }}</td>     
                                    </template>
                                    <template v-slot:[`item.score`+(index-1).toString()]="{ item }" v-for="index in currentTabDlaEntryCount">
                                        <!-- Not to worry, I have a permit-->
                                        <!-- eslint-disable vue/require-v-for-key-->
                                        <td class="parent-table-container" :style="{'background-color': item[`score`+(index-1).toString()].cellColorCode}">{{ item[`score`+(index-1).toString()].rawScore}}</td>
                                        <!-- eslint-enable vue/require-v-for-key-->
                                    </template>
                                    <template v-slot:[`item.rowAverage`]="{ item }">
                                        <td class="parent-table-container">{{ item[`rowAverage`] }}</td>     
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn text color="deep-purple accent-4" class="mx-4" @click="exportPdf">Export Client Level DLA-20 Data</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-container>
    </v-dialog>
</template>

<style scoped lang="scss">
    .activeTabClass{
        color: blue !important;
    }
</style>

import Vue from 'vue'
import Vuex from 'vuex'

// modules
import auth from './auth'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    modules: {
        auth,
        app
    }
    //plugins: [createPersistedState({
    //    reducer: function (state, paths) {
    //        return { auth: state.auth }
    //    }
    //})]
})

/* eslint-disable */
import userService from '@/services/userService'
import accessService from '@/services/accessService'
import Vue from 'vue'
import store from '@/store/store'
import axios from 'axios'

var path = 'api/login/'

async function wcisLogin() {
    if (Vue.prototype.$auth.isAuthenticated) {
        const token = await Vue.prototype.$auth.getTokenSilently()
        var t = { token: token }

        var http = axios.create({
            //baseURL: 'https://wcistest.health.wyo.gov/'
            //baseURL: 'https://localhost:44324/'
            baseURL: AppConfig.wcisapiurl
        })

        return http.post(path + 'posttoken', t)
    }
}

function testWcisApi() {
    var http = axios.create({
        //baseURL: 'https://wcistest.health.wyo.gov/'
        //baseURL: 'https://localhost:44324/',
        baseURL: AppConfig.wcisapiurl
    })

    return http.get(path + 'testapi')
}

//function validatetoken() {
//    return Vue.prototype.$http.get('api/authentication/')
//}

function updateUserStore(commit) {
    return new Promise((resolve, reject) => {
        var wybhdsUserPromise = userService.getCurrentWybhdsUser(Vue.prototype.$http).then(response => {
            commit('setWybhdsUserDto', response.data)
        }, err => {
            reject(err)
        })

        Promise.all([wybhdsUserPromise]).then(results => {
            //loggingService.log(isDebug, 'auth', 'authenticated - post promises')
            resolve(results)
            //loggingService.log(isDebug, 'auth', 'auth.authenticated - post promises - ' + state.authenticated)
        }, err => {
            console.log(err)
            reject(err)
        })
    })
}

function requiresSetup() {
    let userDto = store.state.auth.wybhdsUserDto || {}
    if (userDto) {
        if (userService.isAgencyGroupUser()) {
            return !userDto.agencyGroupId
        }
        else {
            return !userService.isUser()
        }
    }
    return false
}

export default {
    updateUserStore: updateUserStore,
    requiresSetup: requiresSetup,
    wcisLogin: wcisLogin,
    //validatetoken: validatetoken,

    testWcisApi: testWcisApi
}
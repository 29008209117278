import store from '@/store/store'
import Vue from 'vue'

//var serviceName = 'userService'
var path = 'api/user/'
//var isDebug = false

const adminRoles = [
    'State Admin'
]

const stateuserRoles = [
    'State User'
]

const agencygroupuserRoles = [
    'Agency Group User'
]

const rahsuserRoles = [
    'RaHS User'
]

const providerRoles = [
    'Provider'
]

const soruserRoles = [
    'SOR User'
]

const userRoles = [
    'State Admin',
    'Agency Group User',
    'State User',
    'RaHS User',
    'Provider',
    'SOR User'
]

// const Roles = [
//     'Administrator',
//     'Public',
//     'Verification'
// ]

// check if user's roleList matches any of the checkRoleList
function hasRoleCheck(checkRoleList) {
    let wybhdsUserDto = store.state.auth.wybhdsUserDto || {}
    let userRoleList = wybhdsUserDto.roleList
    if (userRoleList) {
        var checkRoleListUpper = checkRoleList.map(role => role.toUpperCase())
        return userRoleList.some(role => {
            return checkRoleListUpper.includes(role.toUpperCase())
        })
    } else {
        return false
    }
}

// function hasRole (role) {
//     return hasRoleCheck([role])
// }

function isAdministrator() {
    return hasRoleCheck(adminRoles)
}

function isAdminOrStateUser() {
    return hasRoleCheck(adminRoles) || hasRoleCheck(stateuserRoles)
}

function isStateUser() {
    return hasRoleCheck(stateuserRoles)
}

function isAgencyGroupUser() {
    return hasRoleCheck(agencygroupuserRoles)
}

function isRahsUser() {
    return hasRoleCheck(rahsuserRoles)
}

function isProvider() {
    return hasRoleCheck(providerRoles)
}

function isSorUser() {
    return hasRoleCheck(soruserRoles)
}

function isUser() {
    return hasRoleCheck(userRoles)
}

function isWSHUser() {
    let wybhdsUserDto = store.state.auth.wybhdsUserDto || {}
    return this.isAgencyGroupUser && wybhdsUserDto.agencyGroupId && wybhdsUserDto.agencyGroupId === 24
}

function hasRoleList() {
    let wybhdsUserDto = store.state.auth.wybhdsUserDto || {}
    //console.log(wybhdsUserDto.roleList.length > 0)
    return !!wybhdsUserDto.roleList && wybhdsUserDto.roleList.length > 0
}

function getUsers(http) {
    //loggingService.log(isDebug, serviceName, 'getCurrentUser')
    return http.get(path)
}

function getUser(http, id) {
    console.log('getUser')
    //loggingService.log(isDebug, serviceName, 'getCurrentUser')
    return http.get(path + id)
}

function getAccessRequestUserByAccessRequestId(http, accessRequestId) {
    console.log('getUserByAccessRequestId');
    //loggingService.log(isDebug, serviceName, 'getCurrentUser')
    return http.get(path + 'get-access-request-user-by-access-request-id/' + accessRequestId);
}

function getCurrentWybhdsUser(http) {
    //loggingService.log(isDebug, serviceName, 'getCurrentUser')
    return http.get(path + 'getCurrentWybhdsUser')
}

function listUserResultDtos(http) {
    //loggingService.log(isDebug, serviceName, 'listUserResultDtos')
    return http.get(path + 'ListUserResultDtos')
}

function listActiveUserResultDtos(http) {
    //loggingService.log(isDebug, serviceName, 'listActiveUserResultDtos')
    return http.get(path + 'ListActiveUserResultDtos')
}

function searchNewUsers(http, showInactive) {
    //loggingService.log(isDebug, serviceName, 'searchNewUsers')
    return http.get(path + 'SearchNewUsers?showInactive=' + showInactive)
}

function saveUser(http, userDto) {
    //loggingService.log(isDebug, serviceName, 'put')
    return http.post(path + 'saveuser', userDto)
}

function setUserInactive(http, portalUserId) {
    //loggingService.log(isDebug, serviceName, 'put')
    return http.put(path + 'SetUserInactive?portalUserId=' + portalUserId)
}

function setUserActive(http, portalUserId) {
    //loggingService.log(isDebug, serviceName, 'put')
    return http.put(path + 'SetUserActive?portalUserId=' + portalUserId)
}

function searchUsers(http, searchDto) {
    return http.post(path + 'SearchUsers', searchDto)
}

export default {
    getCurrentWybhdsUser: getCurrentWybhdsUser,
    isAdministrator: isAdministrator,
    isAdminOrStateUser: isAdminOrStateUser,
    isStateUser: isStateUser,
    isAgencyGroupUser: isAgencyGroupUser,
    isRahsUser: isRahsUser,
    isWSHUser: isWSHUser,
    isProvider: isProvider,
    isSorUser: isSorUser,
    isUser: isUser,

    hasRoleList: hasRoleList,

    getUsers: getUsers,
    getUser: getUser,
    getAccessRequestUserByAccessRequestId: getAccessRequestUserByAccessRequestId,
    
    listUserResultDtos: listUserResultDtos,
    listActiveUserResultDtos: listActiveUserResultDtos,
    searchNewUsers: searchNewUsers,
    saveUser: saveUser,
    setUserInactive: setUserInactive,
    setUserActive: setUserActive,
    searchUsers: searchUsers
}
